import React, { useState } from "react";
import BlogDetail from "./BlogDetail";
import BlogsApp from "./BlogsApp";
import useBlogs from "../../hooks/useBlogs";

const PersonalWebsiteBlogApp = ({ setIsShowNavigation }) => {
  const {
    blogs,
    blogsTotalCount,
    filters,
    setFilters,
    deleteBlog,
    deleteSelectedBlogs,
    fetchBlogs,
    tableConfig,
    fetchBlogFilterData,
    loading,
    onPageChange,
    resetPage,
    tags,
    blog,
    fetchBlogById,
    publishBlog,
    createBlog,
    updateBlog,
  } = useBlogs();

  // used to toggle view details page and store the blog id when the blog details page is open
  const [blogDetailsPageId, setBlogDetailsPageId] = useState(null);

  const _handleViewBlogDetailsPage = (id) => {
    setBlogDetailsPageId(id || null);
    setIsShowNavigation(id ? false : true);
  };

  return (
    <>
      {blogDetailsPageId ? (
        <BlogDetail
          handleViewBlogDetailsPage={_handleViewBlogDetailsPage}
          loading={loading}
          blog={blog}
          blogId={blogDetailsPageId}
          fetchBlogById={fetchBlogById}
        />
      ) : (
        <BlogsApp
          handleViewBlogDetailsPage={_handleViewBlogDetailsPage}
          blogs={blogs}
          blogsTotalCount={blogsTotalCount}
          tags={tags}
          loading={loading}
          filters={filters}
          tableConfig={tableConfig}
          setFilters={setFilters}
          fetchBlogs={fetchBlogs}
          deleteBlog={deleteBlog}
          publishBlog={publishBlog}
          deleteSelectedBlogs={deleteSelectedBlogs}
          fetchBlogFilterData={fetchBlogFilterData}
          resetPage={resetPage}
          onPageChange={onPageChange}
          createBlog={createBlog}
          updateBlog={updateBlog}
        />
      )}
    </>
  );
};

export default PersonalWebsiteBlogApp;
