import { Route, Redirect } from "react-router-dom";
import React from "react";
import { store } from "../../../redux/store";

const PublicRoute = ({ component: Component, ...rest }) => {
  const state = store.getState();
  // console.log(state);

  if (state.userData && state.userData.type) {
    var userType = state.userData.type.toLowerCase();
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (
          state &&
          state.userData &&
          state.userData.token &&
          userType &&
          !rest.path.includes("new-closing")
        ) {
          return (
            <Redirect
              to={{
                pathname: `/${userType}`,
                extras: { ...rest.location },
              }}
            />
          );
        } else {
          return <Component {...props} />;
        }
      }}
    />
  );
};

export default PublicRoute;
