import { BASE_URL, cloudinaryConfig } from "../config";
import {
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  uploadFile,
  makeDeleteRequest,
} from "./http-service";
import { store as REDUX_STORE } from "../redux/store";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/login", false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signupAgentViaEmail = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/agentsignup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signupAgentViaInvitation = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/agent/signup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signupSigningCompanyViaEmail = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/signingcompanysignup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signupSigningCompanyViaInvitation = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/signingcompany/signup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signupClientViaEmail = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/clientsignup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signupClientViaInvitation = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/client/signup", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const forgetPassword = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/forgotpassword", false, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const cloudinaryImageUpload = (
  imageData,
  type = "image",
  isLargeFile = false
) => {
  // console.log("typeof(imageData) :", typeof imageData, type);
  return new Promise((resolve, reject) => {
    const url =
      "https://api.cloudinary.com/v1_1/" +
      cloudinaryConfig.APIKey +
      "/" +
      type +
      "/upload?upload_preset=" +
      cloudinaryConfig.uploadPreset;
    uploadFile(url, false, imageData, isLargeFile)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// export const getAllClients = (data) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + "/signingcompany/clients", true, data)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

export const getAllClients = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/clients`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createClient = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/signingcompany/client", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const resendClientInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + "/signingcompany/request/client/again",
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClient = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/signingcompany/client/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signingCompanyDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + "/signingCompany", true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateSigningCompanyProfile = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/signingCompany/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const updateSigningCompanySetting = (data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/companySetting", true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// export const getAllAgentClosing = (payload) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + "/agent/closings", true, payload)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

export const getAllAgentClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/closings?${payload}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createAgentClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/agent/closing", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createAgentInvite = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/signingcompany/invite/agent", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// export const getAllSigningCompanyAgents = (payload) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + "/signingcompany/agents", true, payload)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

export const getAllSigningCompanyAgents = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/agents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentDetailById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/agent/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createNewSigningCompanyClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + "/signingcompany/closing", true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// export const getAllSigningCompanyClosing = (payload) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + "/signingcompany/closings", true, payload)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

export const getAllSigningCompanyClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/closings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Get all List of Agents for Signing Company
 */

export const getAllListOfAgentsForClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/list/agents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFiltersData = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/all`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const getAllWorkingAgentsForSigningCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/beta/list/all/agents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllWorkingAgentsForAgentPayBreakdown = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/beta/signingcompany/pay-period/list-agent`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentProfileDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentSocialLink = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentProfileDetail = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/${id}`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentDetailVisibility = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/agent-details/visibilty/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const updatePaymentVisibility = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL +
        `/signingcompany/set/send-invoice-link-status/for-client/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addAgentProfileDetail = (id, data) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/add/license/${id}`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editAgentProfileDetail = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/edit/license/${id}`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Add Agent's Document for a closings
 */
export const addAgentDocuments = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/closingdocuments/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Get Agent's Expenses for a closings
 */
export const getAllAgentExpensesForClosing = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/closingexpenses/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Add Agent's Expenses Item for a closings
 */
export const addAgentExpenses = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/closingexpenses/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentProcessingFee = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Edit AgentAbout's Expenses Item for closing
 */

export const editAgentExpense = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agent/edit/closingexpenses/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Delete Agent's Expenses Item for a closings
 */
export const deleteAgentClosingExpense = (id, expensesId) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/closingexpenses/${id}`, true, {
      expensesId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteAgentExpenseItem = (id, expensesId) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/agent/closingexpenses/${id}`, true, {
      expensesId,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Add SigningCompany's Document for a closings
 */
export const addSigningCompanyDocuments = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/closingdocuments/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Signing Company Create Bidding for a Closing
 */
export const signingCompanyCreateClosingBidding = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/bidding`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Signing Company updates to a Bidding
 */
export const signingCompanyUpdateClosingBidding = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/bidding/update`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent accept/decline to a Bidding
 */
export const agentAcceptOrRejectBidding = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/bidding`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//
export const agentAcceptOrRejectBiddingNew = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/agent-website/bidding`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent counter to a Bidding
 */
export const agentCounterBidding = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/bidding/counter`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const agentCounterBiddingNew = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/agent-website/bidding/counter`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * SigningCompany Update Agent's status
 *
 * @param {string} id - string
 * @returns
 */
export const signingCompanyUpdateAgentStatus = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/agent/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Fetch all Agent's the Leaves
 *
 * @param {object} payload - Object
 * @returns
 */
export const getAllAgentLeaves = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/leaves`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create a new Leave for Agent
 *
 * @param {object} payload - Object
 * @returns
 */
export const createAgentLeaves = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/leave`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Delete Agent's Leave by _id (leave id)
 *
 * @param {string} id - string
 * @returns
 */
export const deleteAgentLeaves = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/leave/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create a new user for signing company
 *
 * @param {object} payload - object
 * @returns
 */
export const createSigningcompanyUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/user`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create a new user for signing company
 *
 * @param {object} payload - object
 * @returns
 */
// export const getAllSigningCompanyUsers = (payload) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + `/signingcompany/users`, true, payload)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

export const getAllSigningCompanyUsers = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/users`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Get Signing Company User by _id
 *
 * @param {string} id - string
 * @returns
 */
export const getUserById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/user/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Update a user of signing company
 *
 * @param {string} id - string
 * @returns
 */
export const updateSigningcompanyUserStatus = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/user/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Update Signing Company user
 *
 * @param {string} id
 * @param {object} payload
 * @returns
 */
export const updateSigningcompanyUser = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/user/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent Fetch all the Invoices
 *
 * @param {object} payload
 * @returns
 */
export const getAllAgentInvoices = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/invoices`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Upload Signing Company closing PDF file
export const uploadClosingPdf = (file) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/ai/multipart-parse`, true, file)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Find a Agent Invoice by _id
 *
 * @param {string} id - string
 * @returns
 */
export const getAgentInvoiceById = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/invoice/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent Edit Own Invoice by _id
 */
export const agentUpdateInvoiceById = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/invoice/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent Create a new Invoice (only closing with status="Closed" will pass)
 *
 * @param {array} closingId - array
 * @returns
 */
export const createAgentInvoice = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/invoice`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent Create a new Invoice from closed/DNC Closing job
export const createAgentInvoiceFromClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/closing/create/invoice`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent Get all List of closing for Invoice
 *
 * @param {object} payload - object
 * @returns
 */
export const getAllAgentClosingForInvoice = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/list/agent/closings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent's Closing Master Schedule
 *
 * @param {object} payload - object
 * @returns
 */
export const getAgentClosingMasterSchedule = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/closing-master-schedule`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchGoogleCalenderEventHtmlLink = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/fetch/google-calender-event/html-link`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get closing master schedule
export const companyClosingMasterSchedule = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/signingcompany/closing-master-schedule`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get signing company subscription detail
export const getSigningCompanySubscription = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// buy signing company subscription
export const buySigningCompanySubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/buy-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get setting detail
export const getSettingDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/setting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signing company upgrade details
export const upgradeDetailSigningCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/upgrade-subscription/detail`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signing company upgrade subscription
export const upgradeSigningCompanySubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/upgrade-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signing company downgrade subscription
export const downgradeSigningCompanySubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/downgrade-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signing company Billings
export const billingSigningCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/billings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// cancel subscription for signing company
export const cancelSigningCompanySubscription = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/cancel-subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent get a Subscription detail
 */
export const agentGetSubscription = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// renew subscription for signing company
export const renewSigningCompanySubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/renew-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 *  Agent buy Subscription
 */
export const agentBuySubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/buy-subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 *  Agent Renew Subscription
 */
export const agentRenewSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/new/renew-subscription`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent upgrade Subscription
 */
export const agentUpgradeSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/clone/upgrade-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Agent downgrade Subscription
 */
export const agentDowngradeSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/clone/downgrade-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Fetch all the agent Billings
 */
export const getAllAgentBillings = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/billings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * gets cards
 */
export const fetchAllCards = () => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/cards`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getUserKycLink = () => {
  const userType =
    REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase() === "client"
      ? "client"
      : "user";

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/kyc`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const reDoUserKycLink = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/user/re/kyc`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Sets Default Card
 */
export const makeCardDefault = (cardId) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/${userType}/card/default/${cardId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Adds and Sets Default Card
 */
export const addCardAndMakeDefault = (token) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/card/default`, true, {
      token,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Agent delete a Card
 */
export const deleteCard = (cardId) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/${userType}/card/delete/` + cardId, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * SigningCompany Fetch all the Invoices
 */
export const getAllSigningCompanyInvoices = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/get/agent/invoices`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// update signing company invoice
export const updateSigningcompanyAgentInvoice = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/edit/agent/invoice/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Signing Company Gets all Bidding for a Closing
export const getAllSigningCompanyBidding = (id, payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/bidding/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Agent Get details of Closing for a bid
 */
export const getAgentClosingBidDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/bidding/details`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Fetch all the Expenses
 */
export const getAllAgentExpense = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/expenses`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create a new Expense for account
 */
export const createAgentExpense = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/expense`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Edit a Expense by _id
 */
export const updateAgentExpense = (id, payload) => {
  console.log("%%%%%%%%%%%%%%%%%%%%%%%%", payload);
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/expense/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/* create new rating of Closing for agent
 */
export const createNewRating = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/rating`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editRating = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/rating`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company Get Rating details of Agent regarding Closing
export const getRating = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/rating/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company Get Expenses for a closings
export const companyExpenseForClosing = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/closingexpenses/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

// Agent Get all List of client of Closing for Invoice
export const agentGetAllUnregisteredClient = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/agent/clients`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Fetch all the Incomes of Agent
 */
export const agentFetchAllIncome = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/incomes`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create a new Income for Agent
 */
export const agentCreateIncome = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/income`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update Income as Paid by income _id
export const agentUpdateIncomeMarkPaidById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/income/paid/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

/**
 * Edit a Agent's Income by _id
 */
export const agentUpdateIncome = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/income/edit/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Get Agent's Profit & Loss
 */
export const agentGetProfitAndLoss = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/profitloss`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Fetch all the Expense Summaries of Agent
 */
export const agentGetAllExpenseSummaries = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/expense-summaries`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Fetch all the reciepts of Agent
 */
export const agentGetAllReciepts = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/reciepts`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Fetch all the Mileages
 */
export const agentGetAllMileages = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/mileages`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create a new Mileage for Agent
 */
export const agentCreateMileage = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/mileage`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Get Agents's summaries
 */
export const agentGetReportingSummaries = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/summaries`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/*
 * Get a list of all Invoices signing Company Closings
 */
export const getAllCompanyClosingForInvoice = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/list/signingcompany/closings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Create Invoice signing Company
 */
export const createInvoiceCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/invoice`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createInvoiceCompanyForPending = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/send/invoice/client`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createInvoiceAgentForPending = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/closing/send/invoice/client`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// update signing company invoice status
export const updateSigningcompanyInvoiceStatus = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/edit/company/invoice/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Income List Of Signing Company
export const signingCompanyFetchAllIncome = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/incomes`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Create Income Of Signing Company
export const companyCreateIncome = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/income`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateSigningcompanyIncome = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/income/edit/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Edit a Mileage by _id for Agent's closing
 */
export const agentUpdateClosingMileage = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/closing/mileage/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Remove agent from closing by signing company
 */
export const removeAgentFromClosingBySigningCompany = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/signingcompany/closing/remove-agent/${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Fetch All Expenses Of Signing Company
export const fetchExpensesOfSigningCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/expenses`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Create Expense Of Signing Company
export const createCompanyExpense = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/expense`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Edit a expense signing company
 */
export const updateExpenseSigningCompany = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/expense/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * User sync Google Calendar for closingMasterSchedule
 */
export const userGoogleCalendarSync = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/sync-google-calendar`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const removeGoogleCalendarSync = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/remove-sync-google-calendar`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Delete Document for Closing
export const deleteDocumentForClosing = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/delete/closingdocuments/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get list of agent for pay period Breakdown
export const payPeriodSigningcompanyAgentList = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/pay-period/list-agent`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// fetch all signingcompany for pay period Breakdown
export const fetchAllSigningcompanyPayPeriodBreakdown = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/pay-period`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update signing company pay-period-breakdown
export const updateSigningCompanyPayPeriodBreakdown = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/update/pay-period`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Assign new Device Id
export const assignNewDeviceId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/user/add/device`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Remove Device Id
export const removeDeviceId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/user/remove/device`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get ip data
export const getIpData = () => {
  return new Promise((resolve, reject) => {
    fetch("https://ipapi.co/json/", {
      method: "GET",
    })
      .then((res) => {
        resolve(res.json());
      })
      .catch((e) => {
        console.log("getIpData call error: ", e);
        reject(e);
      });
  });
};

// Closing Activity Log
export const activityLogClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/activity-log?${payload}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// list of LoanType
export const getLoanType = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/loan-type`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get Mileage Detail from address
export const getMileageDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/get/mileage-detail`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// SigningCompany mark Fovourite Agent by _id
export const signingCompanyMarkFavouriteAgentById = (id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/agent/mark-favourite/${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// SigningCompany Fetch All Status Report
export const signingCompanyFetchAllStatusReport = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/status-report`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentFetchAllStatusReport = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/status-report`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const signingCompanyProfitAndLoss = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/profitloss`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signingcompnay Fetch Reporting Agent-Schedule
export const signingCompanyAgentSchedule = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/agent-schedule`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Get Signing Company's summaries
export const signingCompanySummary = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/summaries`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company Update Income Mark as Paid by income _id
export const signingCompanyMarkIncomeAsPaid = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/income/paid/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Signing Company Fetch all the Invoices
export const signingCompanyFetchAllInvoices = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/invoices`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update Agent Income For Record Payment
export const updateAgentIncomeForRecordPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/income/record-payment`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update Company Income For Record Payment
export const updateCompanyIncomeForRecordPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/income/record-payment`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signingcompany Email Invoice
export const signingcompanyEmailInvoice = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/mail/invoices`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Expense - 11.0 Terminate Expense Recurring
 */
export const terminateExpenseRecurring = (expenseId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/terminate-recurring/${expenseId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update Agent Invoice For Record Payment
export const updateAgentInvoiceForRecordPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/invoice/record-payment`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update Company Invoice For Record Payment
export const updateCompanyInvoiceForRecordPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/invoice/record-payment`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Update user profile
export const updateUserProfileDetail = ({ userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/${userType}/user/update/profile`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//get user Detail by Id
export const getUserProfileDetail = ({ userType, id }) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/user/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Add Bank
export const addUserBank = (payload) => {
  const userType =
    REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase() === "client"
      ? "client"
      : "user";

  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/bank`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// user add bank account

export const addBankAccount = (payload) => {
  const userType =
    REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase() === "client"
      ? "client"
      : "user";
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/add/bank`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get User verify bank account

export const getUserVerifyBankAccount = (payload) => {
  const userType =
    REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase() === "client"
      ? "client"
      : "user";

  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/verify-bank`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company Pay Agent Test API

export const companyPayAgent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/pay/agent`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// company rate any Agent

export const createNewAgentRating = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/agent/rate/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// send email on status change

export const sendEmailOnStatusChange = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/closing/status/send/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Create Agent Client

export const createAgentClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAgentClients = (payload) => {
  // console.log("234 ", payload);
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/clients`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// export const getAllAgentClients = (payload) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + `/agent/clients`, true, payload)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

export const updateAgentClient = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + "/agent/client/" + id, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentClientById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentClient = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/agent/clients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get All Agent with ZipCode

export const getAgentWithZipcode = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/list/suggested/zipcode/agents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAgentsWorkWith = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/working/agents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Send Confirmation email to Agent (Reminder)

export const sendConfirmationEmailToAgent = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/closing/confirmation/send/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getPlaidLinkToken = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/create_link_token`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const exchangePublicToken = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/exchange_public_token`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createNewDraft = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/draft`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editDraft = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/draft/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAssignedAgentScheduleforClosing = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/agent/schedule/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentDownloadedDocumentClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/docs/download`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addNotaryPromo = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/promo-subscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const startAutomationForClosing = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/automation/start/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const stopAutomationForClosing = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/automation/stop/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const resumeAutomationForClosing = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/automation/resume/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client's Apis ========= start ========= //

export const createClientClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/closing`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllClientClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/closings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get All companies list for Client
export const getAllSigningCompanyForClient = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/list/companies`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/clientSetting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClientSettings = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/clientSetting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createLenderClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/lenderClient`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateLenderClient = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/lenderClient/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllLenderClients = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/fetchAllLenderClients`, true)
      // makeGetRequest(BASE_URL + `/client/lenderClients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllOrderClients = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/lenderClients`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getOrderClientById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/lenderClient/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateOrderClientStatus = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/update/status/lenderClient/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client Closing Activity Log
export const activityLogClientClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/activity-log?${payload}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Fetch all companies for connect
export const getAllCompanyConnectByClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/fetch/connect/company`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Connect request for company
export const connectCompanyByClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/connect/company`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Connect request for client
export const connectClientByCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/connect/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client get company detail page by companyId
export const getCompanyConnectByClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/get/company-detail-page`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// company get detail page by clientId
export const getClientConnectByCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/get/client-detail-page`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientTeamMembers = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/company/client/users`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addClientTeamMember = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/client/user`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addClientMembers = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/client/users`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editClientTeamMember = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/company/client/user/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteClientTeamMember = (id, payload) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + `/company/delete/client/user/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editClientTeamMemberStatus = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/company/client/user/status/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client request company for connect
export const requestCompanyConnectByClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/request/company`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company Respose to connect request for client
export const companyResponseToConnectByClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/response/connect/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client Respose to connect request for Company
export const clientResponseToConnectByCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/response/connect/company`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client enquiry company details by email
export const enquiryCompanyDetailsByEmail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/get/company-details`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// company enquiry Client details by email
export const enquiryClientDetailsByEmail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/get/client-details`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company enquiry agent details by email
export const enquiryAgentDetailsByEmail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/get/agent-details`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentCancelNotaryPromo = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/cancel-notaryPromoSubscription`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client re-request for Connect with company
export const reRequestCompanyConnectByClient = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/connect/re-request/company/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// company re-request for Connect with client
export const reRequestClientConnectByCompany = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/connect/re-request/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client's Apis ========= end ========= //

// Chat_Log ========== start ========== //

export const getAllChatLog = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chatLogs`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createChatLog = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chatLog`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const forwardChatLog = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/chatLog/forward/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateChatLog = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/chatLog/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteChatLog = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/chatLog/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Chat_Log ========== end ========== //

// Documents ========== start ========== //

export const getAllDocuments = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/get/closingdocuments/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addDocument = ({ id, userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/${userType}/closingdocuments/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Documents ========== end ========== //

// Connect_Company_Notes ========== start ========== //

export const clientGetAllCompanyNotes = ({ userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/get-all/notes`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const clientCreateCompanyNote = ({ userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/upload/note`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const clientUpdateCompanyNote = ({ id, userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/${userType}/update/note/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const clientDeleteCompanyNote = ({ userType, id }) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/${userType}/delete/note/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Connect_Company_Notes ========== end ========== //

// Connect_Company_Files ========== start ========== //

export const clientGetAllCompanyDocuments = ({ userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/get-all/files`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const clientAddCompanyDocument = ({ userType, payload }) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/${userType}/upload/files`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const clientDeleteCompanyDocument = ({ userType, id }) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/${userType}/delete/file/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Connect_Company_Files ========== end ========== //

// Client_profile ========== start ========== //

export const getClientProfileDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClientProfileDetail = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client_profile ========== end ========== //

// Client_User ========== start ========== //

export const getAllClientUsers = (payload) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/users`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientUserById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/user/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createClientUser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/user`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClientUser = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/user/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClientStatusUser = ({ id, payload }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/user/status/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client_User ========== end ========== //

// Client_Pay_Period_Breakdown ========== start ========== //

// Fetch all the Client Pay-Period-Breakdown
export const getAllClientPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/pay-period`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client Pay SigingCompany Test API
export const updateClientPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/update/pay-period`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// update Client Pay-Period-Breakdown
export const clientPayCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/pay/company`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client get all list of Signing Company for Pay Period BreakDown
export const getAllCompanyForPayment = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/client/pay-period/list-signingCompany`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client_Pay_Period_Breakdown ========== end ========== //
// ScanBackDocument ========== start ========== //
export const deleteScanBackDocumentId = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/companySetting/scanBackDocument/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// ScanBackDocument ========== end ========== //

// companySetting ========== start ========== //

export const createCompanySetting = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/companySetting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllCompanySettings = (payload) => {
  console.log("payload", payload);
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/companySetting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// companySetting ========== end ========== //

// AgentDetailSigningCompany ========== start ========== //

export const getAgentDetailForAssignmentModal = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/get-details/agent/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentDetailForAboutAgentPage = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/notary-promo/detail/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// export const getAgentDetailForAssignmentModal = (id) => {
//   return new Promise((resolve, reject) => {
//     makeGetRequest(BASE_URL + `/signingcompany/agent/details/${id}`, true)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };
// AgentDetailSigningCompany ========== end ========== //

// Closing common api ========== start ========== //

export const getClosingDetailsById = (id) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/closing/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getTrackingInfoById = (payload) => {
  console.log("Payload", payload);
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/tracking/information`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateClosingDetailsById = ({ id, payload }) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/${userType}/closing/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Closing common api ========== end ========== //

// notification common api ========== start ========== //

// Fetch all Notifications
export const getAllNotifications = () => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/notifications`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// mark Notifications read
export const markAllNotificationRead = () => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/notifications/read`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// notification common api ========== end ========== //

// deleate connection request ========== start ========== //

//for signing company
export const deleteRequestConnectWithClient = (id) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/delete/connect/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Client
export const deleteRequestConnectWithCompany = (id) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/delete/connect/company/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// deleate connection request ========== end ========== //

//    Company request Client for connect ========== start ========== //
export const companyRequestClientForConnect = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/request/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//   Delete a Signing Company Expense by _id ========== start ========== //

export const deleteCompanyExpense = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/signingcompany/expense/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteAgentExpense = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/agent/expense/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteCompanySettingStandardFeeRates = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/companySetting/standardFeeRates/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateCompanySettingStandardFeeRates = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/companySetting/standardFeeRates/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateCompanyByClient = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/client/update/company-detail/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateCompanyDetails = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/closing/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Create Notarial Log
export const createNotarialLog = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/notarial-log`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// create Notarial Log for closing
export const createNotarialLogForClosing = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/closing-notariallog/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Update Notarial Log
export const updateNotarialLog = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/notarial-log/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentUpdateNotarialLog = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/update/notarial-log/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getNotarialLogByClosingId = (id) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/notarial-log/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Fetch all Notarial Log
export const fetchAllNotarialLog = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/notarial-logs`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const uploadAgentCSV = (data) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/signingcompany/bulk-upload/agents`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const uploadClientCSV = (data) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/signingcompany/bulk-upload/clients`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientListForInvoice = () => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/${userType}/list/invoice/clients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getUnregisteredClientListForInvoice = () => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();

  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/${userType}/list/invoice/unregister-clients`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientListForCompanyInvoice = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/company/list/invoice/clients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const editMileageAgentClosingDetails = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agent/closing/mileage/update/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// Signing Company Create Custom-Email
export const createCustomEmailCompany = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/custom-email/create`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// Signing Company Update Custom-Email
export const updateCustomEmailCompany = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/custom-email/update/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// Signing Company find all Custom-Email Templates
export const getAllCustomEmailCompany = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/custom-email/find`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// socialLogin
export const socialLogin = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/social-login`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Fetch all the FaqTopics
export const getAllFaqTopics = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-topics`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFaqMiscellaneous = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-miscellaneous`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllFaqCategories = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/faq-categories`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findFaqCategoryById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/faq-category/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findFaqTopicById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/faq-topic/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const companyGetClientWiseAutomation = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/companySetting/signingcompany/automation/get-list`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const companyUpdateClientWiseAutomation = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/companySetting/signingcompany/automation/update/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Agent find all Custom-Email Templates
export const agentFindCustomEmailTemplates = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/custom-email/find`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Client find all Custom-Email Templates
export const clientFindCustomEmailTemplates = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/custom-email/find`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentGetClientWiseAutomation = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agentSetting/agent/automation/get-list`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentUpdateClientWiseAutomation = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agentSetting/agent/automation/update/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//  Update a Agent Setting

export const updateAgentSettings = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agentSetting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Agent find all Custom-Email Templates
export const getAgentSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agentSetting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent Create Custom-Email
export const createCustomEmailAgent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/custom-email/create`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent Update Custom-Email
export const updateCustomEmailAgent = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/custom-email/update/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client Create Custom-Email
export const createCustomEmailClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/custom-email/create`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client Update Custom-Email
export const updateCustomEmailClient = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/client/custom-email/update/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Company Get All client list for download invoice
export const companyListDownloadInvoiceClient = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/company/list/download/invoice/clients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// email  verification link api - for all
export const sendEmailVerificationLink = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/send/email-verification/link`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent upgrade/downgrade yearly and monthly Pro-Subscription
export const agentSubscriptionProDowngrade = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/clone/downgrade-subscription`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent downgrade detail
export const agentSubscriptionDowngradeDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/clone/downgrade-subscription/detail`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent upgrade detail
export const agentSubscriptionUpgradeDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/clone/upgrade-subscription/detail`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// guest agent bidding details
export const guestAgentBiddingDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/guest-agent/bidding/details`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * first time agent login then videoshowapi call
 */
export const showVideo = (role, id, payload) => {
  console.log("qwe", role, id, payload);
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/${role}/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Guest Agent accept/decline to a Bidding
 */
export const guestAgentAcceptOrRejectBidding = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/guest-agent/bidding`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// bulk upload client agent side
export const bulkUploadClient = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/bulkupload/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Guest Agent counter to a Bidding
 */
export const guestAgentCounterBidding = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/guest-agent/bidding/counter`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signingCompany bulk Upload Agents
export const signingCompanybulkUploadAgents = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/signingcompany/bulk-upload/agents`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

/**
 * Guest Agent resetpassword for-guest-agent
 */
export const guestAgentResetPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/resetpassword/for-guest-agent`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// signingCompany bulk Upload Clients
export const signingCompanybulkUploadClients = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/signingcompany/bulk-upload/clients`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent bulk Upload pendingincome
export const agentbulkUploadPendingIncome = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/bulkupload/pendingincome`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent bulk Upload pendingincome
export const agentbulkUploadPaidIncome = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/bulkupload/paidincome`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent bulk Upload milage log
export const agentbulkUploadMilageLog = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/bulkupload/milagelog`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent bulk Upload notarial Log
export const agentbulkUploadNotarialLog = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/bulkupload/notariallog`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// agent bulk Upload expenses
export const agentbulkUploadexpenses = (payload) => {
  return new Promise((resolve, reject) => {
    uploadFile(BASE_URL + `/bulkupload/expenses`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get Agent Draft Closings
export const getAgentDraftClosings = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/draft/closings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// get Company Draft Closings
export const getCompanyDraftClosings = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/draft/closings`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const reportingCommentForAgentClosing = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/user/report/closing`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteImportDraft = (type, id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/${type}/draft/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteAgentNotarialLog = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/agent/remove/notarial-log/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentBlacklist = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/block-unblock/agent/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentCreateBulkEmailMessageTemplate = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/bulk-email-message-template`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentUpdateBulkEmailMessageTemplate = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agent/bulk-email-message-template/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAgentTemplates = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/bulk-email-message-template/lists`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateStatusAgentBulkEmailMessageTemmplate = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agent/bulk-email-message-template/status/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteAgentBulkEmailMessageTemmplate = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + `/agent/bulk-email-message-template/${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const checkAgentGuestUser = (agentId) => {
  console.log("we", agentId);
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/check/agent/guest/user/${agentId}`, false)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentClientTagsList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/client/tags/list`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const linkdinLogin = (url) => {
  return new Promise((resolve, reject) => {
    makePostRequest(url, false)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentGenerateLinkdinToken = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/generate/linkedin-token`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentFetchBulkEmailMessageTemplatesList = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/bulk-email-message-template/list`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
export const fetchAllSocialMediaPosts = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/fetch/social-posts`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentSendEmailOrMessageToClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/send/emailormessageto/client`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createSocialMediaPost = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/create/social-post`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const generateFacebookAccessToken = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/generate/facebook/access-token`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const customWebsiteDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/custom/website/detail`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getFacebookPagesList = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/get/facebook/pages-list`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getInstagramBussinessId = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/get/instagram/business-id`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const saveFacebookPageId = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/save/facebook/page-id`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Agent Get All register clients all list for invoice

export const agentGetAllRegisterClients = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/list/invoice/all/clients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAgentWebsiteDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/get/agent-website`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const forwardingAgentsSearchByEmail = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/agent-website/search/forwarding-agents`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addForwardingAgentToAgentWebsite = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/agent/agent-website/add/forwarding-agent/${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAllAgentsAddedAsForwardedAgents = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/agent/agent-website/get/forwarding-agents`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getClientListForWebsitePage = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/agent-website/get/client/list`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addUpdateClientsForWebsitePage = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/agent-website/update/manage-clients`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createAgentWebsiteDetail = () => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/create/agent-website`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentWebsiteDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/update/agent-website`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const publishAgentWebsiteDetail = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agent/publish/agent-website-draft`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteForwardingAgent = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + `/agent/agent-website/delete/forwarding-agent/${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get Agent's Expenses for a closings
export const getAgentExpenses = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/closingexpenses/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get All list of LoanType
export const getAllLoanTypes = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/loan-type`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Add Standard fee Product Category for Agent

export const ronFeesHandler = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createStandardFeeProductCategory = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/add/standardfee/product-category`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//update  description in product category
export const updateProductCategoryDescription = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/agent/update/standardfee/product-category`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Add Product Type in category for Agent
export const createCategoryProductType = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/add/standardfee/product-type`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Delete Category  for agent
export const deleteProductCategory = (categoryName) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + `/agent/remove/standardfee/category`,
      true,
      categoryName
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Delete Product type in category

export const deleteProductType = (id, payload) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(
      BASE_URL + `/agent/remove/standardfee/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//update productType in category
export const updateCategoryProductType = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/update/standardfee`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateAgentClosingById = (id, data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/closing/${id}`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get list of RON platform types
export const getRonPlatformTypes = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/ronplatform-type`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getAvailableDomainsList = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/get/agent-website/domain-list`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Get All connected client list for company

export const getAllConnectedListforCompany = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/company/list/connected/clients`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const saveDomainDetailForAgentWebsite = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/save/agent-website/domain-detail`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//  Agent purchase domain for agent-Website Agent purchase domain for agent-Website
export const agentPurchaseDomainForAgentWebsite = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/purchase/agent-website/domain`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const verifyAgentWebsiteDomain = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/verify/agent-website/domain`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get company standardFess

export const getCompanyStandardFee = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/company/standardfees/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Company Edit a Quickbook Setting
export const companyEditQuickbookSetting = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/quickbook-setting`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Company Get QuickbooksStting details
// * @api {get} /signingcompany/quickbooks-setting

export const getQuickbooksSettingDetails = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/quickbooks-setting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Company create/Edit a Custom Order Form
//  * @api {post} /signingcompany/custom-order-form 02.0 Company Create/Edit a Custom Order Form

export const createCustomOrderForm = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/custom-order-form/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Client Get SigningCompany Custom Order Form by Id
//  * @api {get} /client/fetch/signingcompany/custom-order-form/:id 03.0 Client Get SigningCompany Custom Order Form by Id

export const ClientGetSigningCompanyCustomOrderFormId = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/client/fetch/signingcompany/custom-order-form/${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//   * Company Update a Custom Product Types
// * @api {put} /signingcompany/product-types 02.0 Company Update a Custom Product Types

export const updateCustomProductTypes = (data) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/product-types`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Company Get All Product Types
// * @api {get} /signingcompany/product-types 01.0 Company Get All Product Types

export const companyGetAllProductTypes = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/product-types`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * @api {put} /signingcompany/custom-product/visibilty/:id 03.0 Company Update Custom Product Visibility for a client
//  * @apiName companyUpdateCustomProductVisibilityForClient

export const updateCustomProductVisibility = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/custom-product/visibilty/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentLogoutFromSocialMedia = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/soical-post/logout`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get all List of Clients
// * @api {get} /list/all/client 18.0 Get all List of Clients
// * @apiName getAllListOfClients

export const getAllListClients = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/beta/list/all/client`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
// Agent Get Welcome Videos
//  * @api {get} /agent/welcome/video 27.0 Agent Get Welcome Videos
//  * @apiName getAgentWelcomeVideos

export const getAgentWelcomeVideos = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/welcome/video`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// * Agent Request Admin For Beta Subscription
// * @api {get} /agent/subscription/request/beta 26.0  Agent Request Admin For Beta Subscription
// * @apiName agentRequestForBetaSubscription

export const agentRequestForBetaSubscription = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/subscription/request/beta`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Delete a company client by id
export const deleteCompanyClientById = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/signingcompany/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchAllAgentEnquiryForms = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/fetch/enquiry-forms`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get all List of Client's Assistant by Client Id
export const getAllListOfClientsAssistant = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL + `/list/all/client/assistants?clientId=${id}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company get all teams of client's
export const comapanyGetAllClientTeams = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/client/teams`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company add teams for client's assistant
export const companyAddAssistantTeams = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/client/teams/add`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company update teams for client's assistant
export const companyEditAssistantTeams = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/company/client/teams/edit/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company delete teams for client's assistant
export const companyDeleteAssistantTeam = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/company/client/teams/delete/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company get teams for client's assistant
// export const companyGetAssistantTeams = (id) => {
//   return new Promise((resolve, reject) => {
//     makeGetRequest(BASE_URL + `/company/client/teams/get/${id}`, true)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

// Company get teams for client's assistant new api
export const companyGetAssistantTeams = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/company/client/teams`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Company get list of teams of client
export const companyGetListTeamsOfClient = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/company/closing/client/teams/get/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client get list of teams of client
export const clientGetListTeamsOfClient = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/teams/get/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Client get teams for client's assistant
export const clientGetAssistantTeams = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/client/get/teams`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client add teams on assistant
export const clientAddAssistantTeams = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/teams/add`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client update teams for client's assistant
export const clientEditAssistantTeams = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/teams/edit/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// client delete teams for client's assistant
export const clientDeleteAssistantTeam = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/client/teams/delete/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Get all List of Client's Assistant - client interface
export const getAllListOfClientsAssistantClient = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/list/all/client-assistants`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// resend Invitation
export const companyResendLoginInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/company/resend-login/client/user/invitation`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// // Signing Company Create a new Invoice
// export const companyCreateANewInvoice = (payload) => {
//   return new Promise((resolve, reject) => {
//     makePostRequest(BASE_URL + `/signingcompany/invoice`, true, payload)
//       .then((res) => {
//         resolve(res);
//       })
//       .catch((e) => {
//         console.log("API call error: ", e);
//         reject(e);
//       });
//   });
// };

// Delete a Signing Company's Income by _id
export const deleteCompanyIncomeById = (id) => {
  const userType = REDUX_STORE.getState()?.userData?.user?.type?.toLowerCase();
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/${userType}/income/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent send Invoice attaching income
export const agentSendInvoiceAttachingIncome = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/invoice-send/income/paid/${id}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Delete a agent client by id
export const deleteAgentClientById = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(BASE_URL + `/agent/client/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Fetch Google Rating Using Place Id
export const fetchGoogleRating = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/fetch/google-rating/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Create Agent Invitation By Login Agent
export const createAgentInvitationByLoginAgent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/invite/another-agent`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent Update Enquiry Form by id
export const agentUpdateEnquiryForm = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/update/enquiry-form/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Fetch Invoice Details By Id
export const fetchInvoiceDetailsById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/fetch/invoice/details/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//Create Payment Link
export const createPaymentLink = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/create/payment-link`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//File Number unique check

export const FileNumberDuplicateCheck = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/user/closing/filenumber/unique/check`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// /user/closing/filenumber/match/check
export const FileNumberDuplicateMatchCheck = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/user/closing/filenumber/match/check`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// Agent fetch Google Search Console stats for agent-Website
export const getGoogleSearchConsoleStat = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      `${BASE_URL}/agent-website/fetch/google-search-console/stats`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const checkTeamMemberCanChangeEmail = (userId, clientId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(
      BASE_URL +
        `/company/client/user/email-change/status/${userId}?clientId=${clientId}`,
      true
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const companyFetchAllNotesForAgent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/fetch-all/notes/for/agent`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const companyCreateNotesForAgent = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/signingcompany/create/note/for/agent`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const companyUpdateNotesForAgent = (contentId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(
      BASE_URL + `/signingcompany/update/note/for/agent/${contentId}`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const sendKycRequest = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/company/send/kyc-url/agent/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchSigningCompanyCreditCard = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/signingcompany/cards`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const fetchSigningAgentCreditCard = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(BASE_URL + `/agent/cards`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addAgentCreditCard = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/card`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const addCompanyCreditCard = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/signingcompany/card`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const makeDefaultCompanyCreditCard = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/card/default/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const makeDefaultAgentCreditCard = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/card/default/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteCompanyCreditCard = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/signingcompany/card/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteAgentCreditCard = (id) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/card/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const createInvoiceClient = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/client/invoice`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//calender suthorization
export const calenderGoogleAuth = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/google-calendar/authorization`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

//secure payment

export const agentSecureUpgradeSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/payment-intent/agent/subscription/upgrade`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const agentSecureDowngradeSubscription = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/payment-intent/agent/subscription/downgrade`,
      true,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getSecondaryAgents = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(
      BASE_URL + `/agent/website-owner/get/foward-agent-list/${id}`,
      false,
      payload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const bidSentToOtherAgents = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/send/bid/closing/agents`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const companyCheckUniqueSlugName = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/check/duplicate/slug`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateSlugName = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(BASE_URL + `/agent/update/slug`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// BLOG PAGE API CALLS
export const createBlog = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/agent/blog`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const updateBlog = ({ payload, id }) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/agent/blog/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const deleteBlog = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/agent/blog/delete/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

// delete multiple blogs at once
export const deleteMultipleBlogs = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(BASE_URL + `/agent/blogs`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBlog = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/agent/blog/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const getBlogFilterData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/agent/blog/filters`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};

export const findAllBlogs = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/agent/blogs`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
