import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";

const PersonalWebsiteWarningModal = ({ isOpen, data, toggle }) => {
  const history = useHistory();

  const _closeModal = () => {
    let tabId = "4";
    // redirect to tab 4(Bank Info tab) if kyc not done else redirect to tab 3(My Schedule tab)  --  and if both are not done then redirect to tab 4(Bank Info tab)
    if (!_checkIfKycCompleted()) {
      tabId = "4";
    } else {
      tabId = "3";
    }

    history.push(`/agent/profile?${tabId}`);
  };

  const _checkIfKycCompleted = () => {
    if (data?.kyc && (data?.kyc?.paymentEnabled || data?.kyc?.payoutEnabled))
      return true;
    else return false;
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        size="lg"
        centered
        scrollable
        className="checkOutModal"
      >
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>
          <div className="text-center pt-4">
            <p>
              Make sure to complete the following things before proceeding
              ahead.
            </p>
            {!_checkIfKycCompleted() ? <p>* KYC should be completed.</p> : null}

            {/* {!data?.isAtleastOneStandardFeesAndWitnessFeeAvailable ? (
              <p>* Atleast one Standard fee should be present.</p>
            ) : null} */}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_closeModal}>
            Okay
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PersonalWebsiteWarningModal;
