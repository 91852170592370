import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import SvgIcons from "../../../../components/SvgIcons";
import LabelComponent from "./LabelComponent";
import CustomLoading from "../../../../components/CustomLoading";
import ImageUploader from "./ImageComponent";
import WebsiteFooterLinkPopOver from "./WebsiteFooterLinkPopOver";
import {
  formatPhoneNumberForWebsite,
  getCurrentYear,
} from "../../../../../../helper-methods";
import { personalWebsitePreviewUrlsConfig } from "../../../../../../config/agentConfig";

const PersonalWebsiteFooter = ({
  websiteData,
  rerender,
  onInputHandler,
  loading,
  manageLoading,
  previewUrls,
  handlePreviewUrl,
}) => {
  // const defaultLogo =
  //   "https://res.cloudinary.com/www-logic-square-com/image/upload/v1716366310/general/closewise-logo.png"; // Default logo URL

  // const [previewUrl, setPreviewUrl] = useState();
  const [openPopoverId, setOpenPopoverId] = useState(null); // Tracks which popover is open

  const socialMediaLinks = [
    {
      id: "facebook",
      key: "facebookLink",
      subKey: undefined,
      dataType: "string",
      imageType: "facebook",
      label: "Facebook",
      isShowIcon: true,
      isShowLabel: false,
    },
    {
      id: "instagram",
      key: "instagramLink",
      subKey: undefined,
      dataType: "string",
      imageType: "instagram",
      label: "Instagram",
      isShowIcon: true,
      isShowLabel: false,
    },
    {
      id: "linkedin",
      key: "linkedinLink",
      subKey: undefined,
      dataType: "string",
      imageType: "linkdin",
      label: "Linkdin",
      isShowIcon: true,
      isShowLabel: false,
    },
    {
      id: "twitter",
      key: "twitterLink",
      subKey: undefined,
      dataType: "string",
      imageType: "twitter",
      label: "Twitter",
      isShowIcon: true,
      isShowLabel: false,
    },
  ];

  const otherLinks = [
    {
      id: "privacyPolicy",
      key: "footerSection",
      subKey: "privacyPolicyLink",
      dataType: "object",
      imageType: undefined,
      label: "Privacy policy",
      isShowIcon: false,
      isShowLabel: true,
    },
    {
      id: "termsAndCondition",
      key: "footerSection",
      subKey: "termsAndConditionLink",
      dataType: "object",
      imageType: undefined,
      label: "Terms of service",
      isShowIcon: false,
      isShowLabel: true,
    },
  ];

  const togglePopover = (id) => {
    setOpenPopoverId(openPopoverId === id ? null : id); // Toggle popover for the selected item
  };

  return (
    <>
      <section className="getStartWrapper">
        <Container>
          <LabelComponent
            tag="h2"
            title={"bookingHeading"}
            data={websiteData?.readyToBook?.bookingHeading}
            editHandler={onInputHandler}
            titleKey={"readyToBook"}
            type={"object"}
            maxChar={50}
          />

          <LabelComponent
            tag="h2"
            title={"bookingSubHeading"}
            data={websiteData?.readyToBook?.bookingSubHeading}
            editHandler={onInputHandler}
            type={"object"}
            titleKey={"readyToBook"}
            maxChar={50}
          />
          <div className="getStartAction">
            {/* <NavLink contentEditable className="btn-light"> */}
            <LabelComponent
              className={"btn-white btn"}
              tag="span"
              title={"bookingScheduleButtonText"}
              data={websiteData?.readyToBook?.bookingScheduleButtonText}
              type={"object"}
              titleKey={"readyToBook"}
              editHandler={onInputHandler}
            />
            {/* </NavLink> */}
            <LabelComponent
              className={"btn-white outline btn"}
              tag="span"
              title={"bookingContactButtonText"}
              data={websiteData?.readyToBook?.bookingContactButtonText}
              editHandler={onInputHandler}
              type={"object"}
              titleKey={"readyToBook"}
            />

            {/* <NavLink className="btn-light outline">Give Us A Call</NavLink> */}
          </div>
        </Container>
      </section>
      <footer>
        <Container>
          <Row>
            <Col xl={5} md={4}>
              <div className="footerAbout">
                {loading?.imageUploadLoading === "footerSection_logo" ? (
                  <CustomLoading
                    text="Uploading Image..."
                    minHeight="200px"
                    size="small"
                  />
                ) : (
                  <ImageUploader
                    key={rerender}
                    path={websiteData?.footerSection?.logo}
                    onImageUpload={onInputHandler}
                    titleKey="footerSection"
                    title="logo"
                    placeholderText="Click to upload an image"
                    type="object"
                    index={0}
                    loadingKey={`footerSection_logo`}
                    manageLoading={manageLoading}
                    isDisabled={loading?.imageUploadLoading}
                    previewUrlKey={
                      personalWebsitePreviewUrlsConfig?.footerSection
                    }
                    previewUrl={
                      previewUrls?.[
                        personalWebsitePreviewUrlsConfig?.footerSection
                      ]
                    }
                    handlePreviewUrl={handlePreviewUrl}
                    allowImageCrop={true}
                  />
                )}
                <LabelComponent
                  tag="p"
                  title={"description"}
                  titleKey={"footerSection"}
                  data={websiteData?.footerSection?.description}
                  type={"object"}
                  editHandler={onInputHandler}
                  maxChar={300}
                />
              </div>

              <ul className="socialMedia">
                {socialMediaLinks?.map((link, index) => (
                  <WebsiteFooterLinkPopOver
                    key={link?.id + index}
                    link={link}
                    prefillValue={websiteData?.[link?.key]}
                    openPopoverId={openPopoverId}
                    togglePopover={togglePopover}
                    onEdit={onInputHandler}
                  />
                ))}
              </ul>
            </Col>
            <Col xl={4} md={4}>
              <div className="footerLink">
                <h3>Useful Links</h3>
                <ul className="menuLink">
                  <li>Client log in</li>
                  <li>Services</li>
                  <li>Services Area</li>
                  <li>Closing Services</li>
                  <li>Schedule an order</li>
                  <li>About Us</li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </Col>
            <Col xl={3} md={4}>
              <div className="footerLink">
                <LabelComponent
                  tag="h3"
                  titleKey={"footerContactUs"}
                  data={websiteData?.footerContactUs}
                  editHandler={onInputHandler}
                  type="string"
                />
                {/* <h3>Contact Us</h3> */}
                <ul className="contactInfo">
                  <li className="d-flex align-items-center">
                    <SvgIcons type={"message"} />
                    <LabelComponent
                      tag="span"
                      titleKey={"email"}
                      data={websiteData?.email}
                      editHandler={onInputHandler}
                      type="string"
                    />
                  </li>
                  <li className="d-flex align-items-center">
                    <SvgIcons type={"phone"} />
                    <LabelComponent
                      tag="span"
                      titleKey={"phone"}
                      data={formatPhoneNumberForWebsite(websiteData?.phone)}
                      editHandler={onInputHandler}
                      type="string"
                      inputValidate={/^\d{1,10}$/}
                    />
                  </li>
                  <li className="d-flex align-items-center">
                    <SvgIcons type={"mapMarker"} />
                    <LabelComponent
                      tag="span"
                      titleKey={"address"}
                      data={websiteData?.address}
                      editHandler={onInputHandler}
                      type="string"
                    />
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <div className="copyright">
            <span>
              ©{getCurrentYear()}{" "}
              {websiteData?._agent?.businessName
                ? websiteData?._agent?.businessName
                : "Closewise"}
              . All rights Reserved.
            </span>

            <div className="otherLinks">
              {otherLinks?.map((link, index) => (
                <WebsiteFooterLinkPopOver
                  key={link?.id + index}
                  tag="span"
                  link={link}
                  prefillValue={websiteData?.[link?.key]?.[link?.subKey]}
                  openPopoverId={openPopoverId}
                  togglePopover={togglePopover}
                  onEdit={onInputHandler}
                />
              ))}
            </div>
          </div>
        </Container>
      </footer>
    </>
  );
};

export default PersonalWebsiteFooter;
