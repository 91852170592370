import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  CustomInput,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Chips from "react-chips";
import { RegexConfig } from "../../../../config/RegexConfig";
import {
  errorHandler,
  openUrlOnNewTab,
  getLoggedInUserId,
  showToast,
} from "../../../../helper-methods";
import {
  agentSendInvoiceAttachingIncome,
  createAgentInvoiceFromClosing,
  createInvoiceAgentForPending,
  createInvoiceCompany,
  createInvoiceCompanyForPending,
} from "../../../../http/http-calls";
import { BASE_URL } from "../../../../config";

const DownloadInvoiceModal = ({
  isOpen,
  data,
  toggle,
  type,
  onSuccess,
  showExtraInfo = true,
}) => {
  const [chips, setChips] = useState([]);
  const [markAsPaid, setMarkAsPaid] = useState(false);
  const [loading, setLoading] = useState(false);

  // reset state
  const _resetModal = () => {
    setChips([]);
    setMarkAsPaid(false);
  };

  // check if email is valid or not when entered
  const _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      errorHandler({ reason: "Invalid Email" });
      return false;
    }
  };

  // handle inserted chips from react-chips
  const _onChipSelect = (chips) => {
    let isValid = chips?.length
      ? _isValidEmail(chips[chips?.length - 1])
      : true;
    if (isValid) {
      setChips(chips);
    }
  };

  // close function
  const _closeModal = () => {
    _resetModal();
    toggle();
  };

  // submit function
  const _onSubmit = async () => {
    try {
      setLoading(true);

      // now generating invoice and sending mails
      const payload = {
        emails: chips,
      };

      if (type === "signingCompany") {
        // const res = await companyCreateANewInvoice(payload);
        payload["closingId"] = [data?.id];
        let res;
        if (data?.status === "Pending" || data?.status === "Arrived") {
          res = await createInvoiceCompanyForPending(payload);
        } else {
          res = await createInvoiceCompany(payload);

          // downloading invoice for company
          openUrlOnNewTab(
            `${BASE_URL}/signingcompany/download/invoice/${
              res?.invoice?.id
            }?userId=${getLoggedInUserId()}`
          );
        }
      } else if (type === "agent") {
        if (data?._income?._id) {
          payload["incomeId"] = [data?._income?._id];
        }
        payload["isPaid"] = markAsPaid;
        let res;
        // const res = await createAgentInvoiceFromClosing(payload);
        if (data?.status === "Pending" || data?.status === "Arrived") {
          payload["closingId"] = [data?.id];
          res = await createInvoiceAgentForPending(payload);
        } else {
          res = await createAgentInvoiceFromClosing(payload);

          // downloading invoice for agent
          openUrlOnNewTab(
            `${BASE_URL}/agent/download/invoice/${
              res?.invoice?.id
            }?userId=${getLoggedInUserId()}`
          );
        }
        onSuccess();
      } else if (type === "agentIncome") {
        await agentSendInvoiceAttachingIncome(data?.id, payload);
      }

      if (type === "agentIncome") {
        showToast("Emails sent successfully", "success");
      } else {
        showToast("Invoice created successfully", "success");
      }
      setLoading(false);
      _closeModal();
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  };

  useEffect(() => {
    // set the values to the state
    if (data) {
      let chips = [];
      if (type === "signingCompany") {
        // const email = data?._client?.isSignedUp
        //   ? data?._client?.accountingEmail || undefined
        //   : data?._client?.accountingEmail?.length
        //   ? data?._client?.accountingEmail
        //   : data?._client?.email;

        // chips = email ? [email] : [];

        // let emailId = data?._clientAssistantTeam?.teamMembers
        //   .map((teamMember) => {
        //     if (teamMember.roles.includes(2) || teamMember.roles.includes(5)) {
        //       return teamMember?._user?.email;
        //     }
        //   })
        //   .filter((email) => email !== undefined);

        let emailId = data?._clientAssistantTeam?.teamMembers
          .map((teamMember) => {
            if (teamMember.roles.includes(2) || teamMember.roles.includes(5)) {
              if (teamMember._user) {
                if (teamMember._user.isActive) {
                  return teamMember._user.email;
                }
              } else {
                return teamMember.email;
              }
            }
            return null;
          })
          .filter((email) => email !== undefined);

        chips = emailId ? emailId : [];
      } else if (type === "agent") {
        const email = data?._signingCompany
          ? data?._signingCompany?.accountingEmail
            ? data?._signingCompany?.accountingEmail
            : data?._signingCompany?.email
            ? data?._signingCompany?.email
            : ""
          : data?._assistant
          ? data?._assistant?.email
          : data?._client
          ? data?._client?.email
          : "";
        chips = email ? [email] : [];
      } else if (type === "agentIncome") {
        const email = data?._signingCompany
          ? data?._signingCompany?.accountingEmail
            ? data?._signingCompany?.accountingEmail
            : data?._signingCompany?.email
            ? data?._signingCompany?.email
            : ""
          : data?._client
          ? data?._client?.email
          : data?._assistant
          ? data?._assistant?.email
          : "";
        chips = email ? [email] : [];
      }

      setChips(chips);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  console.log("Data >>", data);

  return (
    <Modal
      isOpen={isOpen}
      toggle={_closeModal}
      className="modal-md modal-dialog-scrollable"
    >
      <ModalHeader toggle={_closeModal}>
        Send the invoice to the recipient
      </ModalHeader>
      <ModalBody>
        {showExtraInfo ? (
          <div className="ml-2">
            <p style={{ fontSize: "14px", marginBottom: "5px" }}>
              Total Amount : $
              {(data?.status === "Pending" || data?.status === "Arrived") &&
              type === "agent" &&
              data?.agentFee !== undefined
                ? data?.agentFee
                : data?.amount
                ? data?.amount?.toFixed(2)
                : data?._income !== null
                ? data?._income?.amount?.toFixed(2)
                : data?.signingCompanyFee?.toFixed(2) || "0.00"}
            </p>
            <p style={{ fontSize: "14px", marginBottom: "5px" }}>
              {/* {console.log(
                "data?._income",
                data?._income,
                data?.amount,
                data?.duePayment
              )} */}
              Paid Amount : $
              {data?._income
                ? (data?._income?.amount - data?._income?.duePayment)?.toFixed(
                    2
                  )
                : data?.amount
                ? (data?.amount - data?.duePayment)?.toFixed(2)
                : "0.00"}
            </p>
            <p
              className="d-flex justify-content-between "
              style={{ fontSize: "14px", marginBottom: "5px" }}
            >
              Due Amount : $
              {data?.status === "Pending" ||
              (data?.status === "Arrived" &&
                type === "agent" &&
                data?.agentFee !== undefined)
                ? type === "agent"
                  ? data?.agentFee
                  : type === "signingCompany"
                  ? data?.signingCompanyFee
                  : "N/A"
                : data?._income
                ? data?._income?.duePayment?.toFixed(2)
                : data?.signingCompanyFee
                ? data?.signingCompanyFee?.toFixed(2)
                : data?.duePayment > 0
                ? data?.duePayment?.toFixed(2)
                : "0.00"}
              {data?._income?.isPaid || data?.isPaid ? (
                <span>
                  <Badge color="success">Paid</Badge>
                </span>
              ) : (
                <>
                  {type === "agent" &&
                    (data?.status !== "Pending" ||
                      data?.status !== "Arrived") &&
                    (!data?._income?.isPaid || data?.isPaid ? (
                      <CustomInput
                        id="isBusinessNameUsedCheckbox"
                        type="checkbox"
                        name="isBusinessNameUsedCheckbox"
                        className="mb-2"
                        checked={markAsPaid}
                        onChange={(e) => setMarkAsPaid(e.target.checked)}
                        disabled={
                          data?.isAgentWebsiteClosing ||
                          data?.isFreeBookingWebsiteClosing
                        } // Disable if closing is from website
                        label="Mark As Paid"
                      />
                    ) : null)}
                  <span>
                    <Badge color="danger">Pending</Badge>
                  </span>
                </>
              )}
            </p>
          </div>
        ) : null}
        <FormGroup className="mt-3 ChipsWrap floatingLabel valueAdded">
          <Chips
            value={chips}
            onChange={(arr) => _onChipSelect(arr)}
            // getChipValue={(e) => console.log(e)}
            createChipKeys={[9, 13]} //  Key codes to add chips
            placeholder={"+Add Email"}
          />
          <Label> Send Email To</Label>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button size="lg" color="primary" outline onClick={_closeModal}>
          Close
        </Button>
        <Button
          color="primary"
          size="lg"
          disabled={loading}
          onClick={_onSubmit}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DownloadInvoiceModal;
