import React from "react";
import { Spinner } from "reactstrap";

const CustomLoading = ({
  text = "Loading...",
  minHeight = "500px",
  size = "large", // "small" for circular image loaders, "large" for full-width divs
  color = "primary" // bootstrap colors
}) => {
  const isSmall = size === "small";

  return (
    <div
      style={{
        width: isSmall ? "40px" : "100%",
        height: isSmall ? "40px" : "100%",
        minHeight: isSmall ? "auto" : minHeight,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: isSmall ? "50%" : "0",
      }}
    >
      <Spinner color={color} size={isSmall ? "sm" : "md"} />
      {!isSmall && text && (
        <p style={{ marginTop: "0.5rem", marginBottom: 0, fontSize: "14px" }}>
          {text}
        </p>
      )}
    </div>
  );
};

export default CustomLoading;
