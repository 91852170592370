import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import moment from "moment";
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import {
  checkPermission,
  deepClone,
  errorHandler,
  // formatDateAsPerTimeZOne,
  formatOnlyDateMoment,
  openUrlOnNewTab,
  // getTimeZoneAbbr,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getAllListClients,
  getAllSigningCompanyClosing,
  getAllWorkingAgentsForSigningCompany,
  companyGetListTeamsOfClient,
  updateClosingDetailsById,
  editDraft,
  getCompanyDraftClosings,
  getClosingDetailsById,
} from "../../../http/http-calls";
import { addListData } from "../../../redux/actions/list";
import ScreenWidthHOC from "./ScreenWidthHOC";
import SvgIcons from "../components/SvgIcons";
import { signingCompanyClosingHeaderKeys } from "../../../config/stateConfig";
import ClosingDashboardFilter from "../components/ClosingDashboardFilter";
import ClosingDashboardCardData from "../components/ClosingDashboardCardData";
import ClosingDashboardDataFormat from "../components/ClosingDashboardDataFormat";
import { DateTimeShowWithCellRow } from "../components/Date-time-show";
import { BASE_URL } from "../../../config";
import AgentDashboardimportModal from "../components/AgentCompanyDashboardimportModal";
import { comapanyDraftClosingHeaderKeys } from "../../../config/agentConfig";

// Code Spliting imports
const CustomTable = lazy(() => import("../components/CustomTable"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const OrderDashboardExport = lazy(() =>
  import("../components/Modals/Company/OrderDashboardExport")
);
const ClosingAssignment = lazy(() =>
  import("../components/closing-assignment-modal")
);
const SigningStatusSigningModal = lazy(() =>
  import("../components/signing-status-signing")
);
const SignerAvailabilityModal = lazy(() =>
  import("../components/signer-availability-modal")
);
const SignerPopover = lazy(() => import("../components/common/popover"));

// For Closing Dashboard Company Site
class ClosingDashboard extends React.Component {
  state = {
    signingCompanyClosingList: [],
    companySetting: [],
    draftClosingsList: [],
    signingCompanyClosingTotalCount: 0,
    signingCompanyClosingHeaderKeys: signingCompanyClosingHeaderKeys,
    draftClosingHeaderKeys: comapanyDraftClosingHeaderKeys,
    signingCompanyClosingCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableData: [],
    tableDataTotalCount: 0,
    assignJobModal: {
      isOpen: false,
      data: null,
    },
    importModal: {
      isOpen: false,
      data: null,
    },
    editAgentModal: {
      isOpen: false,
      data: null,
    },
    quickbookModal: {
      isOpen: false,
      data: null,
    },
    signingStatusModal: {
      isOpen: false,
      data: null,
      status: null,
    },
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    isEditingAgent: false,
    tablePayload: {
      page: 1,
      rowsPerPage: 10,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    filters: {
      status: "",
      loanType: "",
      search: "",
      client: "",
      clientTeam: "",
      agentAssigned: "",
      attorneyAssigned: "",
      state: "",
      startDate: "",
      endDate: "",
      unassigned: false,
      showDraft: false,
      showUpcomingAppointment: false,
      // isReverse: false,
    },
    tableConfig: {
      skip: 0,
      limit: 30,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    draftTableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    dateRangeValue: null,
    filterDropdownValues: {
      clients: [],
      agents: [],
      clientTeamsList: [],
    },
    agents: [],
    clients: [],
    loanType: [],
    mapType: "google",
    isClassAdded: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    // check permission for company user
    if (
      checkPermission("dashboard", "canUpdateClosingStatusOrScheduling") ||
      checkPermission("dashboard", "canEditOrderEntry") ||
      checkPermission("dashboard", "canReviewDocumentsOrOrderDetail")
    ) {
      this.searchTimer = null;

      this._setFilterDataFromLocalStorage();
      // this._getAllListClients();
      // this._getAllWorkingAgents();

      let [searchName, path] = this.props.location.search?.split("/");

      if (searchName === "?_closingId" && path) {
        // debugger;
        getClosingDetailsById(path)
          .then((data) => {
            this._onToggleSignerAvailabilityModal(true, data.closing);
          })
          .catch((error) => console.log("error", error));
      } else if (path) {
        // open Assignment modal if coming from notification or onesignal notification
        this._onToggleAssignJobModal({ _id: path });
      }
      HeaderEventEmitter.subscribe("reset-filter", () => {
        // reset filters
        this._resetFilter();
        refreshFunc("company-closing-dashboard");
      });
      HeaderEventEmitter.subscribe("create-closing", () => {
        // redirect to create closing
        this._redirectToCreateClosing();
      });
      HeaderEventEmitter.subscribe("quickbooks-invoice", () => {
        // open qwickbook modal for bill
        this._onToggleQuickbookModal({ _id: path, type: "invoice" });
      });
      HeaderEventEmitter.subscribe("quickbooks-bill", () => {
        // open qwickbook modal for bill
        this._onToggleQuickbookModal({ _id: path, type: "bill" });
      });

      HeaderEventEmitter.subscribe("import-modal", () => {
        this._onToggleImportModal(true); // open import modal for draft closings
      });

      document.querySelector("#scroll").scrollTo(0, 0);
    } else {
      // redirect to profile
      this.props.history.push("/signingcompany/profile");
    }
  };

  // get data from local storage and set the state
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.companyClosingdashboard) {
      try {
        const filters = JSON.parse(localStorage.companyClosingdashboard);

        if (filters.dateRangeValue !== null) {
          let dateRange = [
            moment(filters.dateRangeValue[0]).toDate().toString(),
            moment(filters.dateRangeValue[1]).toDate().toString(),
          ];
          this.setState({ dateRangeValue: dateRange }, () => {
            // get all signing list
            this._getAllSigningClosing();
          });
        }
        this.setState(
          { filters: filters.filters, tableConfig: filters.tableConfig },
          () => {
            // let filterData = this._prepareFilterData();
            this._getAllSigningClosing();

            if (this.state.filters?.client) {
              // get company list of client teams
              this._getCompanyListTeamsOfClient(this.state.filters?.client);
            }
          }
        );
        // this.setState((prev)=>console.log("second",prev))
      } catch (e) {
        console.log("error", e);

        this._getAllSigningClosing();
      }
    } else {
      this._getAllSigningClosing();
    }
  };

  // get all client list
  _getAllListClients = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }
    try {
      const res = await getAllListClients(payload);

      const { filterDropdownValues } = deepClone(this.state);
      filterDropdownValues["clients"] = res?.clients;
      let options =
        res?.clients?.map((item) => ({
          value: item._id,
          label: item.companyName,
        })) || [];
      // options.unshift({ value: "", label: "All" });
      this.setState({ filterDropdownValues, clients: options });
      // save clients to redux
      this.props.addListData(filterDropdownValues);
      return options;
    } catch (err) {
      console.log("Closing Dashboard Error", err);
      errorHandler(err);
    }
  };

  // api call for client team list
  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      const { filterDropdownValues } = deepClone(this.state);

      filterDropdownValues["clientTeamsList"] = res?.teams;

      this.setState({ filterDropdownValues });
    } catch (err) {
      errorHandler(err);
    }
  };

  // api call for all working agents
  _getAllWorkingAgents = async (data) => {
    let payload = {};
    if (data) {
      payload = { search: data };
    }
    try {
      const res = await getAllWorkingAgentsForSigningCompany(payload);

      const { filterDropdownValues } = deepClone(this.state);
      filterDropdownValues["agents"] = res?.agents;
      let options =
        res?.agents?.map((item) => ({
          value: item._id,
          label: item.name.full,
        })) || [];
      // options.unshift({ value: "", label: "All" });
      this.setState({ filterDropdownValues, agents: options });
      this.props.addListData(filterDropdownValues);
      return options;
    } catch (err) {
      console.log("Closing Dashboard Error", err);
      errorHandler(err);
    }
  };

  // api call for all closing list
  _getAllSigningClosing = async () => {
    this._manageLoading("showTableLoading", true);

    const { filters, dateRangeValue } = this.state;
    let tableConfig = { ...this.state.tableConfig }; // Create a copy

    if (filters.status && filters.status.length)
      tableConfig.status = filters.status;
    if (filters.client && filters.client.length)
      Object.assign(tableConfig, { clientId: filters.client });

    if (filters?.client && filters?.clientTeam && filters?.clientTeam?.length)
      Object.assign(tableConfig, { clientAssistantTeam: filters?.clientTeam });

    if (filters.state && filters.state.length)
      tableConfig.state = filters.state;
    if (filters.agentAssigned && filters.agentAssigned.length)
      Object.assign(tableConfig, { agentId: filters.agentAssigned });

    if (filters.unassigned)
      Object.assign(tableConfig, { unassigned: filters.unassigned });

    if (filters.showDraft)
      Object.assign(tableConfig, { showDraft: filters.showDraft });

    if (filters?.showUpcomingAppointment)
      Object.assign(tableConfig, {
        showUpcomingAppointment: filters.showUpcomingAppointment,
        currentDateTime: moment(new Date()).format("DD/MM/YYYY hh:mm"),
      });

    if (filters.closingDeadline)
      tableConfig.closingDeadline = filters.closingDeadline;
    if (filters.assignedDate) tableConfig.assignedDate = filters.assignedDate;
    if (filters.loanType) tableConfig.loanType = filters.loanType;
    if (filters.search && filters.search.trim().length)
      tableConfig.search = filters.search.trim();

    if (dateRangeValue && dateRangeValue[0])
      tableConfig.startDate = formatOnlyDateMoment(dateRangeValue[0]);
    if (dateRangeValue && dateRangeValue[1])
      tableConfig.endDate = formatOnlyDateMoment(dateRangeValue[1]);

    if (
      dateRangeValue &&
      moment(dateRangeValue[0]).isSame(dateRangeValue[1], "day")
    )
      tableConfig.isReverse = true;

    // Remove unwanted properties
    delete tableConfig.sort;
    delete tableConfig.pageNumber;

    try {
      const res = await getAllSigningCompanyClosing(tableConfig);
      this.setState({
        signingCompanyClosingList: res?.closings,
        signingCompanyClosingTotalCount: res?.totalCount,
        companySetting: res?.companySetting,
      });
    } catch (error) {
      const errorMessage =
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after some time.";
      showToast(errorMessage, "error");
    } finally {
      this._manageLoading("showTableLoading", false);
    }
  };
  // function for assignment modal to open with data
  _onToggleAssignJobModal = (data) => {
    let { assignJobModal } = JSON.parse(JSON.stringify(this.state));
    assignJobModal.isOpen = !assignJobModal.isOpen;
    assignJobModal.data = data;
    this.setState({
      assignJobModal,
    });
    if (!assignJobModal.isOpen) {
      this._getAllSigningClosing();
    }
  };
  // function for quickbook modal
  _onToggleQuickbookModal = (data) => {
    let { quickbookModal } = deepClone(this.state);

    quickbookModal.isOpen = !quickbookModal.isOpen;
    quickbookModal.data = data;
    this.setState({
      quickbookModal,
    });
  };

  // function for signing status modal for updating closing status
  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  // function for signer availabilty modal for appointment confirmation
  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  // function for pagination
  _paginate = (pageNumber = 1, pageSize = 30) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllSigningClosing();
    });
  };
  // this function is not used which calls the above function
  _onSortChange = (sortName, sortOrder) => {
    this._paginate();
  };

  // function to handle on change date picker
  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };
  //  function to handle on change of filters
  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters, filterDropdownValues } = deepClone(this.state);
    filters[type] = value;

    console.log({ type }, { value });
    if (type === "client") {
      filters["clientTeam"] = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        filterDropdownValues["clientTeamsList"] = [];
      }
    }

    if (type === "showUpcomingAppointment") {
      this.setState({ dateRangeValue: null });
    }

    this.setState({ filters, filterDropdownValues }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
          this._persistFilter();
        }, 1000);
      } else {
        this._paginate();
        this._persistFilter();
      }
    });
  };
  // function to reset filter
  _resetFilter = () => {
    this.setState({
      filters: {
        status: "",
        loanType: "",
        search: "",
        client: "",
        clientTeam: "",
        agentAssigned: "",
        attorneyAssigned: "",
        state: "",
        startDate: "",
        endDate: "",
        unassigned: false,
        showDraft: false,
        showUpcomingAppointment: false,
        // isReverse: false,
      },
      dateRangeValue: null,
    });
    this._paginate();
  };

  // function for redirecting to create closing
  _redirectToCreateClosing = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/create-closing`);
  };

  // function for redirecting to  closing details
  _redirectToClosingDetails = (data) => {
    const { userData } = this.props;
    if (
      !(
        checkPermission("dashboard", "canReviewDocumentsOrOrderDetail") ||
        checkPermission("dashboard", "canEditOrderEntry")
      )
    ) {
      return;
    }

    this.props.history.push(`/${userData.type}/closing-details/${data.id}`);
  };
  // function for updating to  closing details by id

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // function for updating to  closing which is draft by id
  _updateSigningCompanyDraftClosing = (id, payload) => {
    return new Promise((resolve, reject) => {
      editDraft(id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // function for updating to closing status by id

  _updateClosingDetailsByIdStatus = async (data, status) => {
    if (status === "DNC" || status === "Closed") {
      if (!data?._agent) {
        errorHandler({ reason: "No Agent was assigned in this order" });
        return;
      }
    }
    if (status === "DNC" || status === "Closed" || status === "Cancelled") {
      this._onToggleSigningStatusModal(true, data, status);
    } else {
      try {
        this._manageLoading("statusChangeLoading", true);

        const updatedRes = data.isSavedAsDraft
          ? await this._updateSigningCompanyDraftClosing(data.id, { status })
          : await this._updateClosingDetailsById(data.id, { status });

        showToast("Status Updated Successfully", "success");
        const { signingCompanyClosingList } = deepClone(this.state);
        const closing = signingCompanyClosingList.find(
          (closing) => closing?.id === data?.id
        );
        closing["status"] = updatedRes?.closing?.status;
        this.setState({ signingCompanyClosingList });
        this._manageLoading("statusChangeLoading", false);
      } catch (error) {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      }
    }
  };

  // this is used to break string in multi line this is not used
  multiLineText = (str) => {
    return str.split(" ").join("\n");
  };

  // this function is used to add class for row
  _rowClassName = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled", "CCA"];
    if (row?.status && statusEnum.includes(row.status)) {
      return "rowStatus";
    }
    return "";
  };

  _dataFormat = (cell, row, header) => {
    return (
      <ClosingDashboardDataFormat
        header={header}
        row={row}
        cell={cell}
        _onToggleSignerAvailabilityModal={this._onToggleSignerAvailabilityModal}
        _redirectToClosingDetails={this._redirectToClosingDetails}
        _onToggleAssignJobModal={this._onToggleAssignJobModal}
        loading={this.state.loading}
        _updateClosingDetailsByIdStatus={this._updateClosingDetailsByIdStatus}
      />
    );
  };

  // this function is used to add background color for row
  _rowStyleFormat = (row) => {
    const statusEnum = ["Closed", "DNC", "Cancelled"];
    if (row?.status && statusEnum.includes(row.status)) {
      return { backgroundColor: "#eee" };
    }
    if (row?.isSavedAsDraft) {
      return { backgroundColor: "#edf0ff" };
    }

    return {};
  };

  // this function is used to store the value in the local storage
  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;

    if (
      (filters &&
        (filters.status ||
          filters.loanType ||
          filters.unassigned ||
          filters.client ||
          filters.agentAssigned ||
          filters.attorneyAssigned ||
          filters.state ||
          filters.showDraft ||
          filters.showUpcomingAppointment ||
          (filters.search && filters.search.length))) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.companyClosingdashboard = JSON.stringify(data);
    } else {
      delete localStorage.companyClosingdashboard;
    }
  };

  // in mobile view this function shows the card header
  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <Suspense fallback={<></>}>
                  <SignerPopover
                    data={row?._borrower}
                    targetId={row._id}
                    screenType="mobile"
                    displayType="signerDetails"
                  />
                </Suspense>

                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )}
                  ,{" "}
                  <span>
                    {getTimeZoneAbbr(
                      row?.appointmentDate,
                      row.closingAddress?.timeZone
                    )}
                  </span>
                </span>  */}
                <DateTimeShowWithCellRow
                  cell={row?.appointmentDate}
                  row={row}
                />
              </div>

              <Button
                color={`${
                  row?.appointmentStatus === "Confirmed" && !row.leftMessage
                    ? "success "
                    : row?.appointmentStatus === "Rescheduled" &&
                      !row.leftMessage
                    ? "danger "
                    : row.leftMessage
                    ? "warning"
                    : "danger "
                }`}
                className={`confirmButton btnConfirm`}
                onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
              >
                {row?.appointmentStatus === "Confirmed"
                  ? "Confirmed"
                  : "Confirm"}
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // in mobile view this function shows the card body
  _cardDataFormat = (row) => {
    return (
      <ClosingDashboardCardData
        row={row}
        _redirectToClosingDetails={this._redirectToClosingDetails}
        _onToggleAssignJobModal={this._onToggleAssignJobModal}
        loading={this.state.loading}
        _updateClosingDetailsByIdStatus={this._updateClosingDetailsByIdStatus}
      />
    );
  };

  _handleExportVendorClient = (userType) => {
    try {
      const params = {
        token: this.props.userData?.token,
      };

      const queryString = structureQueryParams(params);
      openUrlOnNewTab(
        `${BASE_URL}/signingcompany/export/${userType}${queryString}`
      );
    } catch (err) {
      errorHandler(err);
    }
  };

  // function for opening draft closings
  _onToggleImportModal = (isOpen = false, data = null) => {
    this.setState({
      importModal: {
        isOpen,
        data,
      },
    });
  };
  _getCompanyDraftClosings = () => {
    const { draftTableConfig } = this.state;

    let payload = {
      skip: draftTableConfig.skip,
      limit: draftTableConfig.limit,
    };
    getCompanyDraftClosings(payload)
      .then((res) => {
        this.setState({ draftClosingsList: res?.closings || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _paginateDraft = (pageNumber = 1, pageSize = 10) => {
    const { draftTableConfig } = this.state;
    draftTableConfig.skip = (pageNumber - 1) * pageSize;
    draftTableConfig.limit = pageSize;
    draftTableConfig["pageNumber"] = pageNumber;
    this.setState({ draftTableConfig }, () => {
      this._getCompanyDraftClosings();
    });
    // document.querySelector(".content").scrollIntoView();
  };

  render() {
    const {
      signingCompanyClosingTotalCount,
      dateRangeValue,
      filters,
      signingCompanyClosingHeaderKeys,
      signingCompanyClosingList,
      loading,
      signingStatusModal,
      assignJobModal,
      signerAvailabilityModal,
      tableConfig,
      filterDropdownValues,
      agents,
      clients,
      isClassAdded,
      importModal,
      draftClosingsList,
      draftTableConfig,
      draftClosingHeaderKeys,
      draftClosingTotalCount,
      signingCompanyClosingCardHeaderKeys,
    } = this.state;

    const { isForMobile } = this.props;

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <h2>Order Dashboard</h2>

            <div className="rightSide">
              <Button
                color="link"
                onClick={() => {
                  refreshFunc("company-closing-dashboard-responsive");
                  this._resetFilter();
                }}
              >
                <SvgIcons
                  type="refresh"
                  id="company-closing-dashboard-responsive"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <SvgIcons type="filterIcon" />
              </Button>
              <Button
                color="primary"
                size="sm"
                onClick={() => this._onToggleImportModal(true)}
              >
                Import
              </Button>
              <Button
                className="floatingButton"
                color="primary"
                onClick={this._redirectToCreateClosing}
              >
                + Create
              </Button>
              <UncontrolledDropdown>
                <DropdownToggle color="primary" caret>
                  Export
                </DropdownToggle>
                <DropdownMenu right>
                  {!this.props.userData?.user?.isTitleCompany && (
                    <DropdownItem
                      onClick={() =>
                        HeaderEventEmitter.dispatch("quickbooks-invoice")
                      }
                    >
                      Export Invoice
                    </DropdownItem>
                  )}
                  <DropdownItem
                    onClick={() =>
                      HeaderEventEmitter.dispatch("quickbooks-bill")
                    }
                  >
                    Export Bill
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this._handleExportVendorClient("agents")}
                  >
                    Export Vendor
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => this._handleExportVendorClient("client")}
                  >
                    Export Clients
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                    loading="lazy"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Ex.Lender or Product Officer or Order No."
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <SvgIcons type={"filterIcon"} />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                  loading="lazy"
                />
              </Button>
            </div>

            {/* filter component */}
            <ClosingDashboardFilter
              filters={filters}
              dateRangeValue={dateRangeValue}
              filterDropdownValues={filterDropdownValues}
              _filterOnChange={this._filterOnChange}
              _onChangeDatePicker={this._onChangeDatePicker}
              getAllWorkingAgentsForSigningCompany={(search) =>
                this._getAllWorkingAgents(search)
              }
              getAllListClients={(search) => this._getAllListClients(search)}
              agents={agents}
              clients={clients}
            />
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          {/* for mobile view first condition will be executed */}
          {isForMobile ? (
            <Suspense fallback={<></>}>
              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig?.pageNumber}
                  tableData={signingCompanyClosingList}
                  headerKeys={signingCompanyClosingCardHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={signingCompanyClosingTotalCount}
                  // rowSelection={false}
                  pageSize={tableConfig?.limit}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={false}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>
            </Suspense>
          ) : (
            <Suspense fallback={<></>}>
              <div className="hideMobile">
                {signingCompanyClosingHeaderKeys &&
                  signingCompanyClosingHeaderKeys?.length && (
                    <CustomTable
                      striped
                      isPageStartFromOne={true}
                      pageNumber={tableConfig.pageNumber}
                      tableData={signingCompanyClosingList}
                      headerKeys={signingCompanyClosingHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={signingCompanyClosingTotalCount}
                      pageSize={tableConfig.limit}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      rowClassName={(row, rowIdx) =>
                        this._rowClassName(row, rowIdx)
                      }
                      showTableLoading={loading.showTableLoading}
                    />
                  )}
              </div>
            </Suspense>
          )}

          {/* closing Assignment modal or Bidding Pop-up */}
          <Suspense fallback={<></>}>
            <ClosingAssignment
              {...this.props}
              isOpen={assignJobModal.isOpen}
              data={assignJobModal.data}
              agents={agents}
              resetDetails={() => this._getAllSigningClosing()}
              toggle={() => this._onToggleAssignJobModal()}
            />
          </Suspense>
          {/* Signer availability modal for confirm appointment*/}
          <Suspense fallback={<></>}>
            <SignerAvailabilityModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              resetDetails={() => this._paginate()}
              toggle={() => this._onToggleSignerAvailabilityModal()}
              AppointmentSchedulerConfig={{
                regular: { disable: false, show: true },
                dateRange: { disable: false, show: true },
                tbd: { disable: false, show: true },
              }}
            />
          </Suspense>
          {/* singing status modal for updating closing status*/}
          <Suspense fallback={<></>}>
            {signingStatusModal.isOpen ? (
              <SigningStatusSigningModal
                isOpen={signingStatusModal.isOpen}
                data={signingStatusModal.data}
                status={signingStatusModal.status}
                resetDetails={() => this._getAllSigningClosing()}
                toggle={() => this._onToggleSigningStatusModal()}
                isDateFormat={false}
                initialViewMode={"time"}
              />
            ) : null}
          </Suspense>

          <AgentDashboardimportModal
            userType="signingcompany"
            isOpen={importModal.isOpen}
            draftClosingsList={draftClosingsList}
            draftTableConfig={draftTableConfig}
            draftClosingHeaderKeys={draftClosingHeaderKeys}
            draftClosingTotalCount={draftClosingTotalCount}
            toggle={() => this._onToggleImportModal()}
            resetData={() => this._getCompanyDraftClosings()}
            paginateDraft={(pageNumber, pageSize) =>
              this._paginateDraft(pageNumber, pageSize)
            }
          />

          {/* quickbook modal component */}
          <Suspense fallback={<></>}>
            <OrderDashboardExport
              isOpen={this.state.quickbookModal.isOpen}
              data={this.state.quickbookModal.data}
              toggle={() => this._onToggleQuickbookModal()}
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addListData: (listData) => dispatch(addListData(listData)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(ClosingDashboard));
