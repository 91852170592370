import React, { useEffect, useRef } from "react";

const LabelComponent = ({
  title,
  editHandler,
  tag: Tag = "h3",
  data,
  className = "",
  type,
  titleKey,
  titleSubKey = undefined,
  index,
  arrayKey,
  id,
  maxChar,
  inputValidate = undefined // a regex to validate input fields
}) => {
  const contentRef = useRef(null);

  const onEditHandler = (title, data) => {
    editHandler({ title, data, type, key: titleKey, index, arrayKey, id, subKey: titleSubKey });
  };

  /**
   * Handles the input event triggered by the user editing the content.
   * The goal is to prevent the user from entering more characters than the
   * maximum allowed (maxChar).
   *
   * @param {Object} e The event object passed by the browser.
   */
  const handleInput = (e) => {
    const inputValue = e.target.textContent; // Get the current input value
    const trimmedValue = inputValue?.trim();

    // check if it's a valid input if inputValidate citeria is given
    if (inputValidate && !inputValidate?.test(trimmedValue)) {
      e.preventDefault(); // Stop the event to prevent invalid text from appearing
      e.currentTarget.textContent = trimmedValue.slice(0, -1); // Remove last invalid char
      _moveCursorToEnd(e.currentTarget);
      return;
    }

    // Check if the input exceeds maxChar
    if (maxChar && inputValue.length > maxChar) {
      // If it does, trim the input value to the maximum allowed characters
      e.currentTarget.textContent = inputValue.slice(0, maxChar);

      // Now, we need to move the cursor to the end of the input field. This is
      // a bit tricky because the input field is a contentEditable element, and
      // the cursor is represented by a range. So, we create a range and select
      // all the contents of the element. Then, we collapse the range to the end
      // of the selection, and add it to the selection.
      // const range = document.createRange();
      // const selection = window.getSelection();
      // range.selectNodeContents(e.target);
      // range.collapse(false);
      // selection.removeAllRanges();
      // selection.addRange(range);

      _moveCursorToEnd(e.currentTarget); // Call this after trimming
    }

    // Finally, we trigger the edit handler with the trimmed value. This will
    // update the state with the new value.
    onEditHandler(title, e.currentTarget.textContent);
  };

  const _moveCursorToEnd = (element) => {
    if (!element) return;

    const range = document.createRange();
    const selection = window.getSelection();

    // Focus the element to ensure the cursor is applied correctly
    element.focus();

    // Move cursor to the end of the last text node
    range.selectNodeContents(element);
    range.collapse(false); // false => move to end

    selection.removeAllRanges();
    selection.addRange(range);
  };

  // Ensure the content stays within the limit after initialization
  useEffect(() => {
    if (contentRef.current) {
      // Update content only if different to prevent unnecessary cursor jumps
      if (contentRef.current.textContent !== data) {
        contentRef.current.textContent = maxChar ? data?.slice(0, maxChar) : data;
      }
    }
  }, [data, maxChar]);


  const handlePaste = (e) => {
    e.preventDefault(); // Prevent default paste behavior

    // Get plain text from clipboard
    const text = e.clipboardData.getData("text/plain").trim();

    // Insert clean text without extra elements
    document.execCommand("insertText", false, text);
  };

  return (
    <Tag
      ref={contentRef}
      className={className}
      suppressContentEditableWarning={true}
      contentEditable
      onInput={handleInput}
      onPaste={handlePaste} // Attach paste handler
    />
    // {data}
    // </Tag>
  );
};

export default LabelComponent;
