export const BLOG_CATEGORIES = [
  { value: "Legal Solutions", label: "Legal Solutions" },
  { value: "Notary Professional Tools", label: "Notary Professional Tools" },
  { value: "Notary Services", label: "Notary Services" },
  {
    value: "Legal and Document Solutions",
    label: "Legal and Document Solutions",
  },
  {
    value: "Real Estate and Financial Services",
    label: "Real Estate and Financial Services",
  },
];
