import React from "react";
import { Button } from "reactstrap";
import CustomLoading from "../../../../components/CustomLoading";
import ImageUploader from "./ImageComponent";
import LabelComponent from "./LabelComponent";
// import { useSelector } from "react-redux";
import { formatPhoneNumberForWebsite } from "../../../../../../helper-methods";
import { personalWebsitePreviewUrlsConfig } from "../../../../../../config/agentConfig";

const PersonalWebsiteTopHeader = ({
  websiteData,
  show,
  loading,
  rerender,
  onInputHandler,
  setShow,
  manageLoading,
  previewUrls,
  handlePreviewUrl,
}) => {
  // const userData = useSelector((state) => state?.userData?.user);

  // logo setup
  // const [logo, setLogo] = useState(null);
  // const [previewUrl, setPreviewUrl] = useState(null);
  const defaultLogo =
    "https://www.closewise.com/wp-content/uploads/2023/01/closewise-dark-tagline.webp"; // Default logo URL

  return (
    <>
      <div className="headerTop">
        <>
          {loading?.imageUploadLoading === "logo" ? (
            <CustomLoading text="" size="small" minHeight="20px" />
          ) : (
            <ImageUploader
              key={rerender}
              path={websiteData?.logo || defaultLogo}
              onImageUpload={onInputHandler}
              type="string"
              titleKey={"logo"}
              loadingKey={"logo"}
              manageLoading={manageLoading}
              isDisabled={loading?.imageUploadLoading}
              // previewUrl={previewUrl}
              // setPreviewUrl={setPreviewUrl} // Update URL correctly based on id
              allowImageCrop={true}
              className="personalLogo"
              previewUrlKey={personalWebsitePreviewUrlsConfig?.headerSection}
              previewUrl={
                previewUrls?.[personalWebsitePreviewUrlsConfig?.headerSection]
              }
              handlePreviewUrl={handlePreviewUrl}
            />
          )}
        </>

        <div className="headerTopRight">
          {/* <Button outline color="primary" contenteditable="true">
            Call +1 900-000-0003
          </Button> */}

          <LabelComponent
            tag="div" // instead of "button"
            data={formatPhoneNumberForWebsite(websiteData?.topCallMeBtnText)}
            editHandler={onInputHandler}
            type={"string"}
            titleKey={"topCallMeBtnText"}
            className={"btn-outline btn phoneBtn"}
            role="button"
            tabIndex={0}
          />

          {/* <Button color="primary" contenteditable="true">
            Book Me
          </Button> */}
          <LabelComponent
            tag="div" // instead of "button"
            // title={"callButton"}
            data={websiteData?.bookmeText}
            editHandler={onInputHandler}
            type={"string"}
            titleKey={"bookmeText"}
            className={"btn-outline btn"}
            role="button"
            tabIndex={0}
            maxChar={12}
          />
          <Button
            className={`navbarToggle ${show ? "open" : ""}`}
            onClick={() => setShow((prev) => !prev)}
            color="link"
          >
            <span></span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default PersonalWebsiteTopHeader;
