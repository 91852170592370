import React, { Suspense, lazy } from "react";
import {
  agentTodayOrFutureDate,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  formatDateHideYearIfSame,
  getDropdownColor,
  // getTimeZoneAbbr,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";
import { Button, Input } from "reactstrap";
import { DateTimeShowWithCellRow } from "./Date-time-show";
const SignerPopover = lazy(() => import("./common/popover"));

const AgentDashboardDataFormat = ({
  header,
  row,
  cell,
  _onToggleSignerAvailabilityModal,
  _redirectToClosingDetails,
  loading,
  _updateClosingDetailsByIdStatus,
  _isDisabledCheckStatusDropdown,
}) => {
  switch (header) {
    case "appointmentDate": {
      return row ? (
        <>
          <div className="tableUserInfo">
            <div className="userImage">
              <img
                className="clientImg"
                src={
                  row?._signingCompany?.logo
                    ? row?._signingCompany?.logo
                    : row?._client?.logo
                    ? row?._client?.logo
                    : require("../../../assets/img/default_user_icon.svg")
                        .default
                }
                loading="lazy"
                alt="avatar"
              />
            </div>
            <div className="userContent">
              {row?._borrower?.length ? (
                <Suspense fallback={<></>}>
                  <SignerPopover
                    data={row?._borrower}
                    targetId={row._id}
                    screenType="table"
                    displayType="signerDetails"
                  />
                </Suspense>
              ) : null}
              {/* <span className="dateTimeFormat">
                {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)}{" "}
                <span>
                  {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                </span>
              </span> */}
              <DateTimeShowWithCellRow cell={cell} row={row} />

              <Button
                color={`${
                  row?.appointmentStatus === "Confirmed" && !row.leftMessage
                    ? "success "
                    : row?.appointmentStatus === "Rescheduled" &&
                      !row.leftMessage
                    ? "danger "
                    : row.leftMessage
                    ? "warning"
                    : "danger "
                }`}
                className={`confirmButton `}
                onClick={() => _onToggleSignerAvailabilityModal(true, row)}
              >
                {row?.appointmentStatus === "Confirmed"
                  ? "Confirmed"
                  : "Confirm"}
              </Button>
            </div>
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "fileNumber": {
      return row ? (
        <>
          <div
            className="tableLink"
            style={{
              minWidth: 80,
              whiteSpace: "normal",
              textDecoration: "underline",
            }}
            onClick={() => _redirectToClosingDetails(row)}
          >
            {row.fileNumber || "N/A"}
          </div>
        </>
      ) : (
        "N/A"
      );
    }
    case "loanType": {
      return (
        <>
          <div style={{ width: 100 }}>
            <span
              style={{
                marginRight: 6,
                display: "inline-block",
                fontWeight: 600,
              }}
            >
              {row.signingType} {","}
            </span>

            {cell && cell.length > 0 ? (
              cell.indexOf("Other") > -1 ? (
                <span
                  style={{
                    marginRight: 6,
                    display: "inline-block",
                    fontWeight: 600,
                  }}
                >
                  {row?.loanTypeOther}
                </span>
              ) : null
            ) : null}

            {React.Children.toArray(
              cell
                .filter((each) => each !== "Other")
                .map((item) => (
                  <span
                    key={item._id}
                    style={{
                      marginRight: 6,
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                  >
                    {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </span>
                ))
            )}
          </div>
        </>
      );
    }
    case "agentFee": {
      return (
        <>
          <div style={{ fontWeight: 600, width: 60 }}>
            {/* {cell ? formatCurrencyValue(cell) : formatCurrencyValue(0)} */}
            {row?.isAgentWebsiteClosing || row?.isFreeBookingWebsiteClosing
              ? row?._mainForwardAgent !== undefined &&
                row?._mainForwardAgent !== row?._agent?.id
                ? row?.agentFee
                  ? formatCurrencyValue(row?.agentFee)
                  : "TBD"
                : formatCurrencyValue(cell) || "TBD"
              : formatCurrencyValue(cell || 0)}
          </div>
        </>
      );
    }
    case "closingAddress": {
      return (
        <>
          <span style={{ fontWeight: 600, width: 120, display: "block" }}>
            {formatAddressInSingleText(cell) || "N/A"}
          </span>
          <Button
            color="link"
            className="p-0"
            title={formatAddressInSingleText(cell)}
            onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
          >
            <img
              src={
                require("../../../assets/img/Google_Maps_Logo_2020.svg").default
              }
              alt="map icon"
              className="mr-1"
            />
            Google Map
          </Button>
          <Button
            color="link"
            className="p-0"
            title={formatAddressInSingleText(cell)}
            onClick={() => openAppleMapOnNewTab(row.closingAddress)}
          >
            <img
              src={
                require("../../../assets/img/Apple_Maps_(WatchOS).svg").default
              }
              alt="map icon"
              className="mr-1"
            />
            Apple Map
          </Button>
        </>
      );
    }
    case "docs": {
      return row ? (
        <div className="docStatus">
          {/* Last Uploaded by Company: $DateTime */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.companyLastDocumentUploadedAt &&
              `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                row?.companyLastDocumentUploadedAt,
                row?.closingAddress?.timeZone
              )}`
            }
            onClick={() => _redirectToClosingDetails(row)}
          >
            {row?.documents?.length && !row.documents[0].isPrivate ? (
              row.isDocumentCompleted ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : row?.isDocumentUploadedByCompanyOrClient ? (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )
            ) : (
              <span
                className="fa fa-circle text-primary"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
            <span className="value">
              {row.isDocumentCompleted
                ? "Docs Ready"
                : row?.isDocumentUploadedByCompanyOrClient
                ? "Pending"
                : "Not Ready"}
            </span>
          </Button>

          {/* First downloaded by Agent: DateTime */}
          {/* turns green only when agent downloaded all documents  */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.isAgentViewDocumentTime &&
              `First downloaded by Agent:  ${formatDateAsPerTimeZOne(
                row?.isAgentViewDocumentTime,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row.isAllDocumentsDownloaded ? (
              <span
                className="fa fa-circle text-success"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            ) : (
              <span
                className="fa fa-circle text-warning"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
            <span className="value">
              {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
            </span>
          </Button>

          {/* Last Uploaded by Agent: DateTime */}
          <Button
            color="link"
            className="actionBtn"
            data-toggle="tooltip"
            data-placement="top"
            title={
              row?.agentLastDocumentUploadedAt &&
              `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                row?.agentLastDocumentUploadedAt,
                row?.closingAddress?.timeZone
              )}`
            }
          >
            {row.isScanBackNeeded ? (
              row.isDocumentVerified ? (
                <>
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="value">Shipping Approved</span>
                </>
              ) : row.isDocumentCompletedByAgent ? (
                <>
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span
                    className="value"
                    onClick={() => _redirectToClosingDetails(row)}
                  >
                    Scan Upload
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="fa fa-circle text-danger"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="value">Scan Needed</span>
                </>
              )
            ) : row?.documents?.length && !row.documents[0].isPrivate ? (
              row.isDocumentCompletedByAgent ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Upload</span>
                  </>
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )
            ) : (
              <span
                className="fa fa-circle"
                style={{ fontSize: 10, verticalAlign: 1 }}
              />
            )}
          </Button>
        </div>
      ) : (
        "N/A"
      );
    }
    case "status": {
      return cell ? (
        <div className="customSelectWrap" style={{ width: 120 }}>
          {/* {console.log({ row }, { cell })} */}
          <Input
            type="select"
            name="status"
            className={`status ${getDropdownColor(cell)}`}
            value={cell}
            disabled={
              row?.isAgentWebsiteClosing || row?.isFreeBookingWebsiteClosing
                ? row?.status !== "Pending" && row?.status !== "Arrived"
                  ? true
                  : loading.statusChangeLoading
                  ? true
                  : false
                : loading.statusChangeLoading
                ? true
                : false
            }
            onChange={(event) =>
              _updateClosingDetailsByIdStatus(row, event.target.value)
            }
          >
            <option key="CCA" value="CCA">
              Can't Complete
            </option>
            <option
              key="Pending"
              value="Pending"
              // disabled={row._signingCompany !== undefined}
              disabled={!row.isCreatedForAgent}
            >
              Pending
            </option>
            <option
              key="Closed"
              value="Closed"
              disabled={agentTodayOrFutureDate(row) && cell !== "Arrived"}
            >
              Closed
            </option>
            <option
              key="Cancelled"
              value="Cancelled"
              disabled={!row.isCreatedForAgent}
            >
              Cancelled
            </option>
            <option
              key="DNC"
              value="DNC"
              disabled={agentTodayOrFutureDate(row) && cell !== "Arrived"}
            >
              Did not close
            </option>
            <option
              key="Arrived"
              value="Arrived"
              disabled={
                _isDisabledCheckStatusDropdown(row) || cell === "Closed"
              }
            >
              Arrived
            </option>
          </Input>
          <div className="downArrow">
            <i className="fa fa-chevron-down"></i>
          </div>
        </div>
      ) : (
        "N/A"
      );
    }
    case "createdAt": {
      return cell ? formatDateHideYearIfSame(cell) : "N/A";
    }
    default: {
      return cell;
    }
  }
};

export default AgentDashboardDataFormat;
