/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from "react";
import {
  createAgentWebsiteDetail,
  getAgentWebsiteDetail,
  publishAgentWebsiteDetail,
  updateAgentWebsiteDetail,
} from "../../../../../../http/http-calls"; // Import API functions
import {
  errorHandler,
  unformatPhoneNumber,
} from "../../../../../../helper-methods";
// import { useSelector } from "react-redux";
import { RegexConfig } from "../../../../../../config/RegexConfig";
import { capitalize } from "../../../../../../helper-methods/index";

const validationConfigKeys = {
  topHeaderSection: ["topCallMeBtnText"],
  bannerSection: ["heading", "callButton"],
  contactUs: ["supportEmail", "clientEmail", "supportNumber"],
  footerSection: ["email", "phone"],
  aboutUsSection: ["logo"],
};

const useWebsiteDataManage = (togglewebsiteLaunchedModal) => {
  const [websiteData, setWebsiteData] = useState({});
  const [rerender, setRerender] = useState(false); // used for manual rerendering of the component, when data is updated => needed to use here cause sometimes data is being updated without rerendering the component (mostly image component, eg in footer etc)
  const [loading, setLoading] = useState({
    fetchLoading: false,
    submitLoading: false,
    imageUploadLoading: false,
  });

  const manageLoading = useCallback((key, value) => {
    setLoading((prev) => ({ ...prev, [key]: value }));
  }, []);

  const [error, setError] = useState(null);

  // Redux State
  // const userData = useSelector((state) => state?.userData);

  const getTheAgentWebsiteData = useCallback(async () => {
    manageLoading("fetchLoading", true);
    setError(null);
    try {
      const res = await getAgentWebsiteDetail();
      // setWebsiteData(mergeDefaults(websiteInitialData, res?.agentWebsite));
      setWebsiteData(res?.agentWebsite);
      setRerender(Math.random());
      // const updatedValue = mergeDefaults(websiteInitialData, res?.agentWebsite);
      // console.log("updatedValue >>", updatedValue);
    } catch (error) {
      errorHandler(error);
      setError(error);
      console.error("Error fetching website data:", error);
    } finally {
      manageLoading("fetchLoading", false);
    }
  }, []);

  const _validateWebsiteData = async (data) => {
    return new Promise((resolve) => {
      const errors = {
        text: {},
        email: {},
        phone: {},
        images: {},
      };
      // let isEmailError = false;
      // let isPhoneError = false;
      // let isImagesError = false;

      Object.keys(validationConfigKeys)?.forEach((key) => {
        // here check for all email validation rules for all the emails in various sections
        switch (key) {
          case "contactUs": {
            // Contact Us section validations
            validationConfigKeys?.[key]?.forEach((each) => {
              switch (each) {
                case "supportEmail": {
                  if (
                    !RegexConfig?.email?.test(
                      data?.contactUs?.leftSection?.[each]?.trim()
                    )
                  ) {
                    errors["email"][`${key}_${each}`] =
                      "*Invalid email address";
                  } else {
                    delete errors["email"][`${key}_${each}`];
                  }
                  break;
                }

                case "clientEmail": {
                  if (
                    !RegexConfig?.email?.test(
                      data?.contactUs?.leftSection?.[each]?.trim()
                    )
                  ) {
                    errors["email"][`${key}_${each}`] =
                      "*Invalid email address";
                  } else {
                    delete errors["email"][`${key}_${each}`];
                  }
                  break;
                }

                case "supportNumber": {
                  if (
                    !RegexConfig?.websitePhone?.test(
                      unformatPhoneNumber(
                        data?.contactUs?.leftSection?.[each]?.trim()
                      )
                    )
                  ) {
                    errors["phone"][`${key}_${each}`] = "*Invalid phone number";
                  } else {
                    delete errors["phone"][`${key}_${each}`];
                  }
                  break;
                }

                default:
              }
            });
            break;
          }

          case "footerSection": {
            // Contact Us section validations
            validationConfigKeys?.[key]?.forEach((each) => {
              switch (each) {
                case "email": {
                  if (!RegexConfig?.email?.test(data?.[each]?.trim())) {
                    errors["email"][`${key}_${each}`] =
                      "*Invalid email address";
                  } else {
                    delete errors["email"][`${key}_${each}`];
                  }
                  break;
                }

                case "phone": {
                  if (
                    !RegexConfig?.websitePhone?.test(
                      unformatPhoneNumber(data?.[each]?.trim())
                    )
                  ) {
                    errors["phone"][`${key}_${each}`] = "*Invalid phone number";
                  } else {
                    delete errors["phone"][`${key}_${each}`];
                  }
                  break;
                }

                default:
              }
            });
            break;
          }

          case "aboutUsSection": {
            validationConfigKeys?.[key]?.forEach((each) => {
              switch (each) {
                case "logo": {
                  data?.[key]?.["aboutList"]?.forEach(
                    (aboutItem, aboutItemIndex) => {
                      if (!aboutItem?.[each]) {
                        errors["images"][
                          `${key}_aboutList_${each}_${aboutItemIndex}`
                        ] = "*Required";
                      } else {
                        delete errors["images"][
                          `${key}_aboutList_${each}_${aboutItemIndex}`
                        ]; // Remove error if image is provided
                      }
                    }
                  );

                  break;
                }

                default:
              }
            });
            break;
          }

          case "bannerSection": {
            validationConfigKeys?.[key]?.forEach((each) => {
              switch (each) {
                case "heading": {
                  if (!data?.[key]?.[each]?.trim()?.length) {
                    errors["text"][`${key}_${each}`] = "*Required";
                  } else {
                    delete errors["text"][`${key}_${each}`];
                  }
                  break;
                }

                case "callButton": {
                  if (
                    !RegexConfig?.websitePhone?.test(
                      unformatPhoneNumber(data?.[key]?.[each]?.trim())
                    )
                  ) {
                    errors["phone"][`${key}_${each}`] = "*Invalid phone number";
                  } else {
                    delete errors["phone"][`${key}_${each}`];
                  }
                  break;
                }

                default:
              }
            });
            break;
          }

          case "topHeaderSection": {
            validationConfigKeys?.[key]?.forEach((each) => {
              switch (each) {
                case "topCallMeBtnText": {
                  if (
                    !RegexConfig?.websitePhone?.test(
                      unformatPhoneNumber(data?.[each]?.trim())
                    )
                  ) {
                    errors["phone"][`${key}_${each}`] = "*Invalid phone number";
                  } else {
                    delete errors["phone"][`${key}_${each}`];
                  }
                  break;
                }

                default:
              }
            });
            break;
          }

          default:
        }
      });

      console.log({ errors });
      resolve({ ...errors });
    });
  };

  const updateWebsiteData = useCallback(async (payload) => {
    return new Promise(async (resolve) => {
      try {
        // console.log("updateWebsiteData websiteData >>", payload);
        manageLoading("submitLoading", true);
        setError(null);

        const { email, phone, images, text } = await _validateWebsiteData(
          payload
        );

        let isFormValid = true;
        const errorGroups = { email, phone, images, text };

        Object.entries(errorGroups)?.forEach(([key, errors]) => {
          if (errors && Object.keys(errors)?.length) {
            // showing only unique section names
            const sectionNames = [
              ...new Set(
                Object.entries(errors)?.map(([section]) =>
                  capitalize(section?.split("_")?.[0])
                )
              ),
            ]?.join(", "); // Format errors for readability

            let err = "";
            if (key === "images") {
              err = `${capitalize(key)} required in section: ${sectionNames}`;
            } else if (key === "text") {
              err = `${capitalize(key)} required in section: ${sectionNames}`;
            } else {
              err = `Invalid ${key} in section: ${sectionNames}`;
            }

            errorHandler({
              reason: `${err}`,
            });

            isFormValid = false;
          }
        });

        if (!isFormValid) {
          resolve(false);
          return;
        }

        await updateAgentWebsiteDetail(payload);
        setRerender(Math.random());
        // showToast(`Website Updated Successfully`, "success");
        // togglewebsiteLaunchedModal(true);
        // setWebsiteData(res);

        resolve(true);
      } catch (error) {
        errorHandler(error);
        setError(error);
        console.error("Error updating website data:", error);
        resolve(false);
      } finally {
        manageLoading("submitLoading", false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createWebsiteData = useCallback(async (newData) => {
    // console.log("createWebsiteData websiteData >>", websiteData);
    // console.log("createWebsiteData newData >>", newData);
    // let payload = websiteData;
    // if (newData !== undefined) {
    //   payload = newData;
    // }

    return new Promise(async (resolve) => {
      try {
        manageLoading("submitLoading", true);
        setError(null);

        const { email, phone, images } = await _validateWebsiteData(newData);

        let isFormValid = true;
        const errorGroups = { email, phone, images };

        Object.entries(errorGroups)?.forEach(([key, errors]) => {
          if (errors && Object.keys(errors)?.length) {
            // showing only unique section names
            const sectionNames = [
              ...new Set(
                Object.entries(errors)?.map(([section]) =>
                  capitalize(section?.split("_")?.[0])
                )
              ),
            ]?.join(", "); // Format errors for readability

            let err = `Invalid ${key} in section: ${sectionNames}`;

            errorHandler({
              reason: `${err}`,
            });

            isFormValid = false;
          }
        });

        if (!isFormValid) {
          resolve(false);
          return;
        }

        await createAgentWebsiteDetail(newData);
        setRerender(Math.random());
        // setWebsiteData(res);
        // showToast(`Website Added Successfully`, "success");
        // togglewebsiteLaunchedModal(true);

        resolve(true);
      } catch (error) {
        errorHandler(error);
        setError(error);
        console.error("Error creating website data:", error);
        resolve(false);
      } finally {
        manageLoading("submitLoading", false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publishWebsiteData = useCallback(async () => {
    try {
      manageLoading("publishLoading", true);

      await publishAgentWebsiteDetail();

      setRerender(Math.random());
      togglewebsiteLaunchedModal(true);
    } catch (err) {
      errorHandler(error);
      setError(error);
      console.error("Error publishing website data:", error);
    } finally {
      manageLoading("publishLoading", false);
    }
  }, []);

  useEffect(() => {
    getTheAgentWebsiteData();
  }, []);

  useEffect(() => {
    setRerender(Math.random());
  }, [websiteData]);

  // console.log("useWebsiteDataManage payload >>", websiteData);

  // console.log(
  //   "websiteData >>",
  //   websiteData.bannerSection,
  //   websiteInitialData.bannerSection
  // );

  // console.log("useWebsiteDataManage websiteData >>", websiteData);

  return {
    websiteData,
    loading,
    error,
    setWebsiteData,
    getTheAgentWebsiteData,
    updateWebsiteData,
    createWebsiteData,
    publishWebsiteData,
    manageLoading,
    rerender,
  };
};

export default useWebsiteDataManage;
