import React, { useCallback, useEffect, useState } from "react";
import { useTransition } from "react";
import Cropper from "react-easy-crop";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import CustomLoading from "../CustomLoading";

const ImageCropModal = ({
  isOpen,
  imgData,
  fileName,
  fileType,
  toggle,
  onSuccess,
}) => {
  // console.log("called: ", { isOpen, imgData, fileName, fileType, toggle, onSuccess });
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);
  const [defaultAspectRation, setDefaultAspectRatio] = useState();

  const [isLoading, startTransition] = useTransition();

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const _closeModal = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedArea(null);
    setAspectRatio(4 / 3);
    toggle();
  };

  useEffect(() => {
    if (imgData) {
      const img = new Image();
      img.src = imgData;
      img.onload = () => {
        setAspectRatio(img?.naturalWidth / img?.naturalHeight); // Set aspect ratio dynamically
        setDefaultAspectRatio(img?.naturalWidth / img?.naturalHeight);
      };
    }
  }, [imgData]);

  // cropping the image using canvas and react-easy-crop library
  const _getCroppedImg = (sourceImg, crop) => {
    return new Promise((resolve, reject) => {
      try {
        let sourceImage = new Image();
        sourceImage.crossOrigin = "anonymous"; // Avoid CORS issues
        sourceImage.src = sourceImg;

        sourceImage.onload = () => {
          const canvas = document.createElement("canvas");
          const scaleX = sourceImage?.naturalWidth / sourceImage?.width;
          const scaleY = sourceImage?.naturalHeight / sourceImage?.height;
          canvas.width = crop?.width;
          canvas.height = crop?.height;

          const ctx = canvas.getContext("2d", { alpha: true }); // Enable transparency
          // Clear the canvas to preserve transparency
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          ctx.drawImage(
            sourceImage,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
          );

          canvas.toBlob(
            (file) => {
              resolve(file);
            },
            "image/png", // PNG supports transparency
            1 // Max quality
          );
        };

        sourceImage.onerror = (err) => reject(err);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await _getCroppedImg(imgData, croppedArea, 0);
      return croppedImage;
    } catch (error) {
      console.error(error);
    }
  }, [croppedArea, imgData]);

  const _handleAspectRatioChange = (event) => {
    if (event) event.stopPropagation();
    setAspectRatio(+event.target.value); // converting to number before setting it
  };

  const _onCropDone = async () => {
    const newImgData = await showCroppedImage();

    const imgFile = new File([newImgData], fileName, {
      type: "image/png",
    });

    onSuccess(imgFile, fileType);

    _closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      centered
      scrollable
      className="checkOutModal"
    >
      <ModalHeader toggle={() => _closeModal()}>Crop Image</ModalHeader>
      <ModalBody>
        <div
          className="text-center pt-4"
          style={{
            width: "400px",
            minHeight: "350px",
          }}
        >
          {imgData ? (
            <div>
              <Cropper
                image={imgData}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                // objectFit="cover"
                onZoomChange={setZoom}
                style={{
                  containerStyle: {
                    width: "100%",
                    height: "80%",
                    backgroundColor: "#fff",
                  },
                }}
              />
            </div>
          ) : null}
        </div>

        <Form className="w-50 d-flex justify-content-center align-items-center mx-auto">
          <Label className="my-0 mr-2">Zoom: </Label>
          <Input
            className="w-50 inputRangeCustom"
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              if (e) e.stopPropagation();
              setZoom(+e.target.value); // converting to number before setting it
            }}
          />
        </Form>

        <Form className="d-flex justify-content-center align-items-center gap-2">
          <Label>Aspect Ratio: </Label>
          <FormGroup check>
            <Label for="aspectRatio1" check>
              <Input
                id="aspectRatio1"
                type="radio"
                name="aspectRatio"
                value={1 / 1}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 1 / 1}
              />{" "}
              1:1
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="aspectRatio2" check>
              <Input
                id="aspectRatio2"
                type="radio"
                name="aspectRatio"
                value={4 / 3}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 4 / 3}
              />{" "}
              4:3
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="aspectRatio3" check>
              <Input
                id="aspectRatio3"
                type="radio"
                name="aspectRatio"
                value={16 / 9}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 16 / 9}
              />{" "}
              16:9
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label for="aspectRatio4" check>
              <Input
                id="aspectRatio4"
                type="radio"
                name="aspectRatio"
                value={5 / 4}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 5 / 4}
              />{" "}
              5:4
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="aspectRatio5" check>
              <Input
                id="aspectRatio5"
                type="radio"
                name="aspectRatio"
                value={defaultAspectRation}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === defaultAspectRation}
              />{" "}
              Default
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={_closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() =>
            startTransition(() => {
              _onCropDone(croppedArea);
            })
          }
        >
          Crop & Apply {isLoading ? <CustomLoading /> : null}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageCropModal;
