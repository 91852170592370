export const agentClosingHeaderKeys = [
  { id: "id", label: "id" },
  { id: "appointmentDate", label: "signer" },
  { id: "fileNumber", label: "File#" },
  { id: "loanType", label: "Product Type" },
  { id: "agentFee", label: "Fee" },
  { id: "docs", label: "Docs" },
  { id: "closingAddress", label: "Location" },
  { id: "status", label: "Status" },
];

export const agentClosingCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "appointmentDate", label: "signer" },
];

export const agentClosingCardBodyKeys = [
  { id: "id", label: "id" },
  { id: "fileNumber", label: "File#" },
  { id: "loanType", label: "Product Type" },
  { id: "agentFee", label: "Fee" },
  { id: "docs", label: "Docs" },
  { id: "closingAddress", label: "Location" },
  { id: "status", label: "Status" },
];

export const draftClosingHeaderKeys = [
  { id: "id", label: "id" },
  { id: "appointmentDate", label: "signer" },
  { id: "fileNumber", label: "File#" },
  { id: "loanType", label: "Product Type" },
  { id: "agentFee", label: "Fee" },
  { id: "closingAddress", label: "Location" },
  { id: "action", label: "Action" },
];

export const comapanyDraftClosingHeaderKeys = [
  { id: "id", label: "id" },
  { id: "appointmentDate", label: "signer" },
  { id: "fileNumber", label: "File#" },
  { id: "loanType", label: "Product Type" },
  { id: "agentFee", label: "Agent Fee" },
  { id: "comapnyFee", label: "Company Fee" },
  { id: "closingAddress", label: "Location" },
  { id: "action", label: "Action" },
];

export const agentPayPeriodBreakdownHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "serviceDate", label: "Service Date", noSort: true },
  { id: "_borrower", label: "Signer", noSort: true },
  { id: "address", label: "Address", noSort: true },
  { id: "city", label: "City", noSort: true },
  { id: "agentFee", label: "Fee", noSort: true },
  { id: "notes", label: "Notes", noSort: true },
  { id: "payStatus", label: "Payment", noSort: true },
  { id: "total", label: "Total", noSort: true },
];

export const agentPayrollHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "_agent", label: "Agent", noSort: true },
  { id: "jobs", label: "Jobs", noSort: true },
  { id: "amount", label: "Total Fee", noSort: true },
  { id: "paid", label: "Paid/Unpaid", noSort: true },
  { id: "action", label: "Action", noSort: true },
];

export const agentScheduleCompanyHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  {
    id: "appointmentDate",
    label: "Date",
    noSort: true,
    style: { width: 180 },
  },
  { id: "_borrower", label: "Signer", noSort: true, style: { width: 200 } },
  { id: "phone", label: "Phone", noSort: true, style: { width: 250 } },
  { id: "address", label: "Address", noSort: true, style: { width: 250 } },
  { id: "_client", label: "Title", noSort: true, style: { width: 180 } },
  { id: "signingCompanyFee", label: "Fee", noSort: true },
  { id: "status", label: "Status", noSort: true },
  { id: "agentFee", label: "Agent Fee", noSort: true },
];

export const agentsHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Name", noSort: true },
  { id: "workType", label: "Type", noSort: true },
  { id: "address", label: "Location", noSort: true },
  { id: "closingCompleted", label: "Jobs Completed", noSort: true },
  { id: "totalRating", label: "Ratings", noSort: true },
  { id: "credential", label: "Credential", noSort: true },
  { id: "isActive", label: "Status", noSort: true },
];

export const jobCompleted = [
  { name: "All", value: { min: undefined, max: undefined } },
  { name: "0", value: { min: 0, max: 0 } },
  { name: "1-100", value: { min: 1, max: 100 } },
  { name: "101-500", value: { min: 101, max: 500 } },
  { name: "501-1000", value: { min: 501, max: 1000 } },
  { name: "1000+", value: { min: 1001 } },
];

export const rating = [
  { name: "All", value: { min: undefined, max: undefined } },
  { name: "Less than 2", value: { min: 0, max: 2 } },
  { name: "Less than 3", value: { min: 0, max: 3 } },
  { name: "Less than 4", value: { min: 0, max: 4 } },
  { name: "4+", value: { min: 4 } },
];

export const fee = [
  { name: "All", value: { min: undefined, max: undefined } },
  { name: "Less than $50", value: { min: 0, max: 50 } },
  { name: "$50 - $75", value: { min: 50, max: 75 } },
  { name: "$75 - $100", value: { min: 75, max: 100 } },
  { name: "$100 - $150", value: { min: 100, max: 150 } },
  { name: "$150+", value: { min: 150 } },
];

export const billingHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "createdAt", label: "Billing Date", noSort: true },
  { id: "totalBillingAmount", label: "Amount", noSort: true },
  { id: "plan", label: "Plan", noSort: true },
  { id: "currentSubscription", label: "Description", noSort: true },
  { id: "downloadAction", label: " ", noSort: true },
];

export const pageBuilderImagesConfig = {
  defaultPlaceholderImage:
    "https://res.cloudinary.com/www-logic-square-com/image/upload/v1741766462/CloseWise/page-builder/placeholder3.jpg",
  contactUsPage:
    "https://res.cloudinary.com/www-logic-square-com/image/upload/v1741764846/CloseWise/page-builder/customer-support.webp",
  homeHeroSection:
    "https://res.cloudinary.com/www-logic-square-com/image/upload/v1741768847/CloseWise/page-builder/742.jpg",
  userDefaultImage:
    "https://res.cloudinary.com/www-logic-square-com/image/upload/v1741764994/CloseWise/page-builder/image_10.png",
};

export const personalWebsitePreviewUrlsConfig = {
  homeSection: "homeSection",
  headerSection: "headerSection",
  footerSection: "footerSection",
  testimonialSection: "testimonialSection",
  contactUsPage: "contactUsPage",
  aboutUsSection: "aboutUsSection",
  aboutListSection: "aboutListSection",
  serviceListSection: "serviceListSection",
};
