import React, { Suspense } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  Label,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Collapse,
  Table,
  FormGroup,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  formatCurrencyValue,
  getTimeZoneAbbr,
  formatTime,
  openGoogleMapOnNewTab,
  showToast,
  formatDateMoment,
  formatDateAsPerTimeZoneWithDateAndTime,
  errorHandler,
} from "../../../helper-methods";
import {
  getAgentClosingBidDetail,
  agentAcceptOrRejectBidding,
  agentCounterBidding,
  guestAgentCounterBidding,
  guestAgentAcceptOrRejectBidding,
  checkAgentGuestUser,
  agentAcceptOrRejectBiddingNew,
  agentCounterBiddingNew,
  bidSentToOtherAgents,
  getSecondaryAgents,
} from "../../../http/http-calls";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ScreenWidthHOC from "./ScreenWidthHOC";
import { DateTimeShowWithClosingDetails } from "../components/Date-time-show";
import ReadMore from "../components/readMore";

const ConformationModal = React.lazy(() =>
  import("../components/Modals/ConformationModal")
); // ConformationModal
class NewClosingAgentPublic extends React.Component {
  state = {
    closingDetails: null,
    loading: false,
    isOpenCounterForm: false,
    isOpenConformationModal: false,
    agentId: "",
    isShareOtherAgentBtnShow: false,
    secondaryAgents: [],
    counterForm: {
      counterAmount: {
        value: "",
        isDirty: false,
        error: null,
      },
      appointmentTime: {
        value: "",
        isDirty: false,
        error: null,
      },
    },
  };

  _extractAgentId = () => {
    return new Promise(async (resolve, reject) => {
      const parts = this.props.match.params.id.split("&");

      let agentId = null;

      for (const part of parts) {
        const [key, value] = part.split("=");
        if (key === "agentid") {
          agentId = value;
        }
      }
      resolve(agentId);
    });
  };

  _redirectToSignup = (e) => {
    if (e) e.preventDefault();
    // const { userData } = this.props;
    const parts = this.props.match.params.id.split("&");

    let agentId = null;

    // console.log("parts >>", parts);

    for (const part of parts) {
      const [key, value] = part.split("=");
      if (key === "agentid") {
        agentId = value;
      }
    }

    if (parts?.includes("guestuser=false")) {
      this.props.history.push(`/login`);
    } else {
      this.props.history.push(`/nna-signup/${agentId}`);
    }

    // this.props.history.push(`/nna-signup/${agentId}`);
  };

  componentDidMount = async () => {
    console.log("1111", this.props);

    try {
      const id = this.props.match.params.id.replace("guestuser=true", "");

      const agentId = await this._extractAgentId();
      console.log("agentId", agentId);

      this.props.showLoader("Fetching Closing Details...");

      const res = await this._getAgentClosingBidDetail(id);
      if (!res?.error) {
        // Check that this agent is primary agent
        if (
          agentId === res?.closing?._websiteAgent &&
          !res?.closing?.isFreeBookingWebsiteClosing
        ) {
          // fetch for secondary agents
          const isSecondaryAgentAvailable = await this._fetchForSecondaryAgents(
            agentId,
            res?.closing?._id
          );
          // console.log("res _fetchForSecondaryAgents", response);
          this.setState({
            isShareOtherAgentBtnShow: isSecondaryAgentAvailable,
          });
        }
      }

      this.props.hideLoader();
    } catch (error) {
      console.log("error _redirectToSignup >>", error);

      errorHandler(error);
      this.props.hideLoader();
      this._redirectToSignup();
    }
  };

  _fetchForSecondaryAgents = (id, closingId) => {
    return new Promise(async (resolve, reject) => {
      const payload = {
        closingId: closingId,
      };
      try {
        const res = await getSecondaryAgents(id, payload);
        if (!res?.error) {
          if (res?.agents?.length > 0) {
            resolve(true);
          } else {
            resolve(false);
          }
          this.setState({
            secondaryAgents: res?.agents || [],
          });
        }
      } catch (error) {
        errorHandler(error);
      }
    });
  };

  _bidSentToOtherAgents = async () => {
    try {
      this.props.showLoader("Sending Bids...");

      const payload = {
        closingId: this.state.closingDetails?.id,
        agentIds: this.state.secondaryAgents,
        agentId: this.state?.agentId,
      };

      const response = await bidSentToOtherAgents(payload);
      if (!response?.error) {
        this.props.hideLoader();
        showToast(
          `Bid sent sunscessfully to ${this.state?.secondaryAgents?.length} agents`,
          "success"
        );
      }
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _extractUrl = (url) => {
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    const cameViaValue = params.get("cameVia");
    const remainingParams = queryString.replace(`cameVia=${cameViaValue}&`, "");
    return cameViaValue + "&" + remainingParams;
  };
  _extractAgentId(url) {
    const match = url.match(/[?&]agentid=([^&]+)/);
    return match && match[1];
  }

  _checkAgentGuestUser = async () => {
    let agentId = this._extractAgentId(this.props.history.location.search);
    try {
      let response = await checkAgentGuestUser(agentId);
      if (response?.isGuest) {
        console.log("age", agentId);
      } else {
        this.props.history.push("/login");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  _getAgentClosingBidDetail = (id = null) => {
    const { userData } = this.props;

    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }

    const parts = this.props.match.params.id.split("&");

    let agentId = null;

    for (const part of parts) {
      const [key, value] = part.split("=");
      if (key === "agentid") {
        agentId = value;
      }
    }
    const payload = {
      closingId: parts[0],
      agentId,
    };
    return new Promise((resolve, reject) => {
      getAgentClosingBidDetail(payload)
        .then((res) => {
          this.setState(
            {
              closingDetails: res.closing,
              agentId,
            },
            () => {
              HeaderEventEmitter.dispatch("closing-details", {
                closingDetails: res.closing,
              });
            }
          );
          if (res?.closing?._agent !== undefined) {
            showToast("Job no longer available", "error");
            this.props.history.push(`/${userData.type}/dashboard`);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData?.type}/dashboard`);
  };

  _agentAcceptOrRejectBidding = async (status) => {
    try {
      this.setState({ loading: true });

      const payload = {
        status,
        closingId: this.state.closingDetails?.id,
        agentId: this.state.agentId,
      };

      if (
        this.state.closingDetails?.isAgentWebsiteClosing ||
        this.state.closingDetails?.isFreeBookingWebsiteClosing
      ) {
        await agentAcceptOrRejectBiddingNew(payload);
        this.setState({ loading: false });
        showToast(`Order ${status}`, "success");
        this._redirectToDashboard();
      } else if (this.state.closingDetails?.isGuest) {
        await guestAgentAcceptOrRejectBidding(payload);
        this.setState({ loading: false });
        showToast(`Order ${status}`, "success");
        this._redirectToSignup();
      } else {
        await agentAcceptOrRejectBidding(payload);
        this.setState({ loading: false });
        showToast(`Order ${status}`, "success");
        this._redirectToDashboard();
      }
    } catch (err) {
      this.setState({ loading: false });
      errorHandler(err);
    }
  };

  _validatecounterFormField = () => {
    return new Promise((resolve, reject) => {
      const { counterForm } = this.state;

      let isFormValid = true;

      Object.keys(counterForm).forEach((key) => {
        if (counterForm[key].isDirty) {
          switch (key) {
            case "appointmentTime": {
              if (
                counterForm[key].value &&
                counterForm[key].value.trim().length
              ) {
                counterForm[key].isDirty = false;
                counterForm[key].error = null;
              } else {
                counterForm[key].isDirty = true;
                counterForm[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "counterAmount": {
              if (
                counterForm[key].value &&
                counterForm[key].value.trim().length
              ) {
                if (
                  isNaN(counterForm[key].value) ||
                  Number(counterForm[key].value) < 1
                ) {
                  counterForm[key].isDirty = true;
                  counterForm[key].error = "*Invalid Fee";
                  isFormValid = false;
                } else {
                  counterForm[key].isDirty = false;
                  counterForm[key].error = null;
                }
              } else {
                counterForm[key].isDirty = true;
                counterForm[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ counterForm }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeCounterFormField = (fieldName, value) => {
    const { counterForm } = this.state;
    if (fieldName === "counterAmount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }
    if (fieldName === "appointmentTime") {
      counterForm[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      counterForm[fieldName].value = value;
    }
    counterForm[fieldName].isDirty = true;
    this.setState({ counterForm }, () => {
      // Validation
      this._validatecounterFormField();
    });
  };

  _markAllCounterFormFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { counterForm } = this.state;
      Object.keys(counterForm).forEach((key) => {
        counterForm[key].isDirty = true;
      });
      this.setState({ counterForm }, () => resolve(true));
    });
  };

  _onSubmitCounterOffer = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllCounterFormFieldDirty();

      const isFormValid = await this._validatecounterFormField();

      if (isFormValid) {
        this.setState({ loading: true });

        const { counterForm, closingDetails, agentId } = deepClone(this.state);

        let payload = {
          closingId: closingDetails?.id,
          counterAmount: Number(counterForm.counterAmount.value.trim()),
          appointmentTime: formatDateMoment(counterForm.appointmentTime.value),
          agentId,
        };

        if (
          closingDetails?.isAgentWebsiteClosing ||
          closingDetails?.isFreeBookingWebsiteClosing
        ) {
          await agentCounterBiddingNew(payload);
          this.setState({ loading: false });
          showToast(`Order counter offer has been sent.`, "success");
          this._redirectToDashboard();
        } else if (closingDetails?.isGuest) {
          await guestAgentCounterBidding(payload);
          this.setState({ loading: false });
          showToast(`Order counter offer has been sent.`, "success");
          this._redirectToSignup();
        } else {
          await agentCounterBidding(payload);
          this.setState({ loading: false });
          showToast(`Order counter offer has been sent.`, "success");
          this._redirectToDashboard();
        }
      }
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _setCounterFormField = () => {
    const { counterForm, closingDetails } = deepClone(this.state);

    counterForm.counterAmount.value =
      closingDetails?.biddingAmount?.toString() || "";
    // counterForm.appointmentTime.value = closingDetails?.appointmentDate || "";
    counterForm.appointmentTime.value = closingDetails?.appointmentDate
      ? formatDateAsPerTimeZoneWithDateAndTime(
        closingDetails.appointmentDate,
        closingDetails.closingAddress.timeZone
      )
      : "";
    Object.keys(counterForm).forEach((key) => {
      counterForm[key].isDirty = false;
      counterForm[key].error = null;
    });
    this.setState({ counterForm });
  };

  _toggleCounterFormCard = () => {
    if (!this.state.isOpenCounterForm) {
      this._setCounterFormField();
    }
    this.setState({ isOpenCounterForm: !this.state.isOpenCounterForm });
  };

  _toggleConformationModal = (value) => {
    this.setState({
      isOpenConformationModal: !this.state.isOpenConformationModal,
    });

    if (value) {
      this._bidSentToOtherAgents();
      console.log("Call _bidSentToOtherAgents >>");
    }
  };

  render() {
    const { closingDetails, loading, counterForm, isOpenCounterForm } =
      this.state;
    // console.log(this.state);
    const { isForMobile } = this.props;
    return (
      <>
        <div className="p-3">
          <div className="publicPageTitle">
            <h2>
              New Signing Offer qwertyu
              {closingDetails && (
                <span>Order No #{closingDetails?.closingNumber || "N/A"}</span>
              )}
            </h2>
          </div>

          {closingDetails && (
            <Row>
              <Col sm="12" lg="6">
                <div className="CardWrapper">
                  <div className="CardTitle">
                    <h2 className="sectionTtile">Order Details</h2>
                  </div>
                  {!isForMobile ? (
                    <Suspense fallback={<></>}>
                      <div className="CustomTableWrapper hideMobile">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>Appointment Date</th>
                              <th>Address</th>
                              <th>Fees Offered</th>
                              <th>Product type</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {closingDetails.appointmentDate ? (
                                  <>
                                    <div>
                                      <DateTimeShowWithClosingDetails
                                        closingDetails={closingDetails}
                                      />
                                      {" | "}
                                      <span>
                                        {getTimeZoneAbbr(
                                          closingDetails.appointmentDate,
                                          closingDetails.closingAddress.timeZone
                                        )}
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>
                                <span
                                  style={{
                                    fontWeight: 600,
                                    width: 150,
                                    display: "block",
                                  }}
                                >
                                  {closingDetails?.closingAddress ? (
                                    <>
                                      {closingDetails?.closingAddress?.line1
                                        ? `${closingDetails?.closingAddress?.line1}, `
                                        : ""}
                                      {closingDetails?.closingAddress?.line2
                                        ? `${closingDetails?.closingAddress?.line2}, `
                                        : ""}
                                      {closingDetails?.closingAddress?.city
                                        ? `${closingDetails?.closingAddress?.city}, `
                                        : ""}
                                      {closingDetails?.closingAddress?.state
                                        ? `${closingDetails?.closingAddress?.state}, `
                                        : ""}
                                      {closingDetails?.closingAddress?.zip
                                        ? `${closingDetails?.closingAddress?.zip}`
                                        : ""}
                                    </>
                                  ) : (
                                    "N/A"
                                  )}
                                </span>

                                {closingDetails.closingAddress ? (
                                  <Button
                                    color="link"
                                    // className="showMap"
                                    onClick={() =>
                                      openGoogleMapOnNewTab(
                                        closingDetails.closingAddress
                                      )
                                    }
                                  >
                                    <img
                                      className="mr-1"
                                      src={
                                        require("../../../assets/img/map-marker.svg")
                                          .default
                                      }
                                      alt="final"
                                    />
                                    Map
                                  </Button>
                                ) : null}
                              </td>
                              <td>
                                {closingDetails.biddingAmount
                                  ? formatCurrencyValue(
                                    closingDetails.biddingAmount
                                  )
                                  : "TBD"}
                              </td>
                              <td>
                                {closingDetails.loanType &&
                                  closingDetails.loanType.length > 0 &&
                                  [
                                    closingDetails.loanType.includes("Other")
                                      ? closingDetails.loanTypeOther
                                      : null,
                                    ...closingDetails.loanType
                                      .filter((each) => each !== "Other")
                                      .map((item) =>
                                        item.replace(/([a-z])([A-Z])/g, "$1 $2")
                                      ),
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </Suspense>
                  ) : (
                    <Suspense fallback={<></>}>
                      <Card className="responsiveTableCard hideDesktop">
                        <ul className="cardInfo">
                          <li>
                            <div className="dataWrap">
                              <Label style={{ fontSize: 12 }}>
                                Appointment Date:{" "}
                              </Label>
                              <div style={{ fontWeight: 600, fontSize: 12 }}>
                                {closingDetails?.appointmentDate ? (
                                  <>
                                    <>
                                      <DateTimeShowWithClosingDetails
                                        closingDetails={closingDetails}
                                      />
                                      {" | "}
                                      <span style={{ fontWeight: 400 }}>
                                        {getTimeZoneAbbr(
                                          closingDetails?.appointmentDate,
                                          closingDetails?.closingAddress
                                            ?.timeZone
                                        )}
                                      </span>
                                    </>
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </div>
                            <div className="dataWrap">
                              <Label style={{ fontSize: 12 }}>Address </Label>
                              <div style={{ fontWeight: 600, fontSize: 12 }}>
                                {closingDetails?.closingAddress ? (
                                  <>
                                    {closingDetails?.closingAddress?.line1
                                      ? `${closingDetails?.closingAddress?.line1}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.line2
                                      ? `${closingDetails?.closingAddress?.line2}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.city
                                      ? `${closingDetails?.closingAddress?.city}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.state
                                      ? `${closingDetails?.closingAddress?.state}, `
                                      : ""}
                                    {closingDetails?.closingAddress?.zip
                                      ? `${closingDetails?.closingAddress?.zip}`
                                      : ""}
                                  </>
                                ) : (
                                  "N/A"
                                )}

                                {closingDetails?.closingAddress ? (
                                  <Button
                                    color="link"
                                    // className="showMap"
                                    onClick={() =>
                                      openGoogleMapOnNewTab(
                                        closingDetails?.closingAddress
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        require("../../../assets/img/map-marker.svg")
                                          .default
                                      }
                                      alt="final"
                                    />
                                  </Button>
                                ) : null}
                              </div>
                            </div>
                          </li>

                          <li>
                            <div className="dataWrap">
                              <Label style={{ fontSize: 12 }}>
                                Fees Offered:{" "}
                              </Label>
                              <div style={{ fontSize: 12, fontWeight: 600 }}>
                                {closingDetails?.biddingAmount
                                  ? formatCurrencyValue(
                                    closingDetails?.biddingAmount
                                  )
                                  : "N/A"}
                              </div>
                            </div>
                            <div className="dataWrap">
                              <Label style={{ fontSize: 12 }}>
                                Product Type:{" "}
                              </Label>
                              <div style={{ fontWeight: 600, fontSize: 12 }}>
                                {closingDetails.loanType &&
                                  closingDetails.loanType.length > 0 &&
                                  [
                                    closingDetails.loanType.includes("Other")
                                      ? closingDetails.loanTypeOther
                                      : null,
                                    ...closingDetails.loanType
                                      .filter((each) => each !== "Other")
                                      .map((item) =>
                                        item.replace(/([a-z])([A-Z])/g, "$1 $2")
                                      ),
                                  ]
                                    .filter(Boolean)
                                    .join(", ")}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Card>
                    </Suspense>
                  )}
                </div>
              </Col>

              <Col sm="12" lg="6">
                <div className="CardWrapper mt-3 mt-xl-0">
                  <div className="CardTitle">
                    <h2 className="sectionTtile">Signing Instructions</h2>
                  </div>
                  <Card>
                    <CardHeader>
                      <CustomInput
                        type="checkbox"
                        id="isScanBackNeeded"
                        checked={closingDetails.isScanBackNeeded}
                        label="Scan Backs Needed"
                        disabled={true}
                      />
                    </CardHeader>
                    <CardBody>
                      {closingDetails.bidInstructions ? (
                        <>
                          <ReadMore text={closingDetails?.bidInstructions} />

                          {/* <TextEditor
                            disabled
                            content={closingDetails.bidInstructions}
                            onChange={() => { }} // caution: don't remove
                          /> */}
                        </>
                      ) : (
                        "N/A"
                      )}
                    </CardBody>
                  </Card>
                </div>
              </Col>

              <Col md="12">
                <div className="signingOfferButton mt-4">
                  <Button
                    color="primary"
                    outline
                    size="lg"
                    onClick={() => this._agentAcceptOrRejectBidding("Accepted")}
                  >
                    Accept
                  </Button>
                  <Button
                    color="danger"
                    outline
                    size="lg"
                    className="mx-3"
                    onClick={() => this._agentAcceptOrRejectBidding("Rejected")}
                  >
                    Decline
                  </Button>
                  <Button
                    size="lg"
                    color="primary"
                    onClick={this._toggleCounterFormCard}
                  >
                    Counter Offer
                  </Button>
                  {this.state.isShareOtherAgentBtnShow && (
                    <Button
                      size="lg"
                      color="primary"
                      onClick={() => this._toggleConformationModal()}
                    >
                      Share with others
                    </Button>
                  )}
                </div>

                <Collapse isOpen={isOpenCounterForm}>
                  <div className="counterOfferWrap">
                    <Row>
                      <Col md={6}>
                        <FormGroup
                          className={`floatingLabel ${counterForm.appointmentTime.value ||
                            this.state.isOpenReactDatetime
                            ? "valueAdded"
                            : ""
                            }`}
                        >
                          <ReactDatetime
                            inputProps={{
                              className: "form-control",
                              placeholder: " ",
                              value: formatTime(
                                counterForm.appointmentTime.value
                              ),
                            }}
                            value={
                              counterForm.appointmentTime.value
                                ? new Date(counterForm.appointmentTime.value)
                                : ""
                            }
                            onChange={(e) =>
                              this._onChangeCounterFormField(
                                "appointmentTime",
                                e._d
                              )
                            }
                            onOpen={() =>
                              this.setState({ isOpenReactDatetime: true })
                            }
                            onClose={() =>
                              this.setState({ isOpenReactDatetime: false })
                            }
                            dateFormat={false}
                            timeFormat={true}
                            timeConstraints={{ minutes: { step: 15 } }}
                          />
                          <Label>Appointment Time</Label>
                        </FormGroup>
                        {counterForm.appointmentTime.error && (
                          <div className="validation-error">
                            {counterForm.appointmentTime.error}
                          </div>
                        )}
                      </Col>

                      <Col md={6}>
                        <FormGroup className="floatingLabel withInputGroup">
                          <InputGroup>
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <img
                                  src={
                                    require("../../../assets/img/dollar.svg")
                                      .default
                                  }
                                  height={12}
                                  alt="doller"
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              type="text"
                              placeholder=" "
                              value={counterForm.counterAmount.value}
                              onChange={(e) =>
                                this._onChangeCounterFormField(
                                  "counterAmount",
                                  e.target.value
                                )
                              }
                            />
                            <Label>Fee</Label>
                          </InputGroup>
                        </FormGroup>
                        {counterForm.counterAmount.error && (
                          <div className="validation-error">
                            {counterForm.counterAmount.error}
                          </div>
                        )}
                      </Col>
                    </Row>
                    <div className="text-center my-3">
                      <Button
                        color="primary"
                        onClick={this._onSubmitCounterOffer}
                      >
                        Submit Offer
                      </Button>
                    </div>
                  </div>
                </Collapse>

                {loading && (
                  <div className="table-overlay">
                    <div>
                      <i className="fa fa-spinner fa-spin" />
                    </div>
                  </div>
                )}
              </Col>
              {/* <Col>
                {closingDetails?._mainForwardAgent?.toString() ===
                  this.state.agentId.toString() && (
                  <p className="text-center">
                    You have already shared your feedback. Please wait for
                    Client's response.
                  </p>
                )}
              </Col> */}
            </Row>
          )}
        </div>
        <Suspense fallback={<div>Loading...</div>}>
          {this.state.isOpenConformationModal ? (
            <ConformationModal
              isOpen={this.state.isOpenConformationModal}
              toggle={(value) => this._toggleConformationModal(value)}
              title="Are you sure you want to pass the bid to other agents"
            />
          ) : null}
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(NewClosingAgentPublic));
