import ClosingMasterScheduleCompany from "./modules/private/pages/closing-master-schedule-company";
import ClosingDashboard from "./modules/private/pages/closing-dashboard";
import Agents from "./modules/private/pages/agents";
import Clients from "./modules/private/pages/Clients";
import ClientsAgent from "./modules/private/pages/clients-agent";
import Users from "./modules/private/pages/users";
import CompanyProfile from "./modules/private/pages/company-profile";
import AgentPayPeriodBreakdownCompany from "./modules/private/pages/agent-pay-period-breakdown-company";
import Subscription from "./modules/private/pages/subscription";

// below pg is for the agents
import ClosingMasterScheduleAgent from "./modules/private/pages/closing-master-schedule-agents";
import AgentDashboard from "./modules/private/pages/agent-dashboard";
import MileageLogReportingAgent from "./modules/private/pages/mileage-log-reporting-agent";
import SubscriptionAgent from "./modules/private/pages/subscription-agent";
import AgentProfile from "./modules/private/pages/agent-profile";
import AgentScheduleCompany from "./modules/private/pages/agent-schedule-company";
import OrderDashboard from "./modules/private/pages/OrderDashboard";
import OrderClient from "./modules/private/pages/OrderClient";
import SigningCompanies from "./modules/private/pages/SigningCompanies";
import ClientProfile from "./modules/private/pages/ClientProfile";
import ClientTeamMember from "./modules/private/pages/ClientTeamMember";
import ClientPayment from "./modules/private/pages/ClientPayment";
import NotarialLog from "./modules/private/pages/NotarialLog";
import Templates from "./modules/private/pages/AgentPages/Templates";
import PersonalWebsiteManagement from "./modules/private/pages/AgentPages/PersonalWebsiteManagement";
import AgentCompanyInvoicing from "./modules/private/pages/AgentCompanyInvoicing";
import AgentCompanyExpenses from "./modules/private/pages/AgentCompanyExpenses";
import AgentCompanyProfitLoss from "./modules/private/pages/AgentCompanyProfitLoss";
import AgentCompanySummaries from "./modules/private/pages/AgentCompanySummaries";
import AgentCompanyStatusReport from "./modules/private/pages/AgentCompanyStatusReport";
import AgentCompanyIncomeListAccounting from "./modules/private/pages/AgentCompanyIncomeListAccounting";

import PersonalWebsite from "./modules/private/pages/AgentPages/personalWebsiteMangement/PersonalWebsite";
// import AgentCreditCard from "./modules/private/pages/AgentPages/AgentCreditCard";
import GoogleSearchConsole from "./modules/private/pages/google-console";

const importSideMenuImgIcon = (iconPathName) => {
  try {
    if (iconPathName) return require(`./assets/img/sidemenu/${iconPathName}`);

    return null;
  } catch (error) {
    return null;
  }
};

export const signingCompanyRoutes = [
  // for Signing Company
  {
    path: "/signingcompany/closing-master-schedule",
    name: "Master Schedule",
    headerName: "Master Schedule",
    imgIcon: importSideMenuImgIcon("signingClosingMasterSchedule.png"),
    svgIcon: "masterSchedule",
    component: ClosingMasterScheduleCompany,
    layout: "/admin",
  },
  {
    path: "/signingcompany/closing-dashboard",
    name: "Order Dashboard",
    headerName: "Order Dashboard",
    imgIcon: importSideMenuImgIcon("signingClosingDashboard.png"),
    svgIcon: "orderDashboard",
    component: ClosingDashboard,
    layout: "/admin",
  },
  {
    path: "/signingcompany/agents",
    name: "Agents",
    headerName: "Agents",
    imgIcon: importSideMenuImgIcon("signingAgent.png"),
    svgIcon: "agent",
    component: Agents,
    layout: "/admin",
  },
  {
    path: "/signingcompany/clients",
    name: "Clients",
    headerName: "Clients",
    imgIcon: importSideMenuImgIcon("signingClients.png"),
    svgIcon: "clientManagement",
    component: Clients,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Accounting",
    imgIcon: importSideMenuImgIcon("agentAccounting.svg"),
    svgIcon: "accounting",
    state: "accountingCollapse",
    views: [
      {
        path: "/signingcompany/accounting/income",
        name: "Income",
        headerName: "Income List",
        imgIcon: importSideMenuImgIcon("income.png"),
        component: AgentCompanyIncomeListAccounting,
        layout: "/admin",
      },
      {
        path: "/signingcompany/accounting/expenses",
        name: "Expenses",
        headerName: "Expenses",
        imgIcon: importSideMenuImgIcon("billing.png"),
        component: AgentCompanyExpenses,
        layout: "/admin",
      },
      {
        path: "/signingcompany/accounting/invoicing",
        name: "Invoicing",
        headerName: "Invoicing",
        imgIcon: importSideMenuImgIcon("invoicing.png"),
        component: AgentCompanyInvoicing,
        layout: "/admin",
      },
      // {
      //   path: "/signingcompany/accounting/agent-payroll",
      //   name: "Agent Payroll",
      //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
      //   component: AgentPayrollCompany,
      //   layout: "/admin",
      // },

      // {
      //   path: "/signingcompany/accounting/profit-loss",
      //   name: "Profit & Loss",
      //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
      //   component: ProfitAndLossAccountingCompany,
      //   layout: "/admin",
      // },
      {
        path: "/signingcompany/accounting/agent-pay-period-breakdown",
        name: "Agent Pay Breakdown",
        headerName: "Agent Pay Period Breakdown",
        imgIcon: importSideMenuImgIcon("chart.png"),
        component: AgentPayPeriodBreakdownCompany,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Reporting",
    imgIcon: importSideMenuImgIcon("signingReporting.png"),
    svgIcon: "reporting",
    state: "reportingCollapse",
    views: [
      {
        path: "/signingcompany/reporting/agent-schedule",
        name: "Agent Schedule",
        headerName: "Agent Schedule",
        imgIcon: importSideMenuImgIcon("schedule.png"),
        component: AgentScheduleCompany,
        layout: "/admin",
      },
      // {
      //   path: "/signingcompany/reporting/agent-pay-period-breakdown",
      //   name: "Agent Pay Period Breakdown",
      //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
      //   component: AgentPayPeriodBreakdownCompany,
      //   layout: "/admin",
      // },
      {
        path: "/signingcompany/reporting/status-report",
        name: "Status Report",
        headerName: "Status Report",
        imgIcon: importSideMenuImgIcon("summary.png"),
        component: AgentCompanyStatusReport,
        layout: "/admin",
      },
      {
        path: "/signingcompany/reporting/profit-loss",
        name: "Profit & Loss",
        headerName: "Profit & Loss",
        imgIcon: importSideMenuImgIcon("profit-loss.png"),
        // component: ProfitAndLossReportingCompany,
        component: AgentCompanyProfitLoss,
        layout: "/admin",
      },
      // {
      // path: "/signingcompany/reporting/receipts",
      // name: "Receipts",
      // imgIcon: importSideMenuImgIcon("billing.png"),
      // component: ReceiptsReportingCompany,
      // layout: "/admin",
      // },
      {
        path: "/signingcompany/reporting/expense-summaries",
        name: "Summaries",
        headerName: "Summaries",
        imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
        component: AgentCompanySummaries,
        layout: "/admin",
      },
    ],
  },
  // {
  //   path: "/signingcompany/accounting/invoicing",
  //   name: "Invoicing",
  //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
  //   component: InvoicingCompany,
  //   layout: "/admin",
  // },

  // {
  //   path: "/signingcompany/billing",
  //   name: "Billing",agent
  //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
  //   component: BillingCompany,
  //   layout: "/admin",
  // },

  {
    path: "/signingcompany/users",
    name: "Team Members",
    headerName: "Team Members",
    imgIcon: importSideMenuImgIcon("signingUsers.png"),
    svgIcon: "teamMembers",
    component: Users,
    layout: "/admin",
  },
  {
    path: "/signingcompany/profile",
    name: "Company Profile",
    headerName: "Company Profile",
    imgIcon: importSideMenuImgIcon("agentProfile.svg"),
    svgIcon: "profile",
    component: CompanyProfile,
    layout: "/admin",
  },
  // {
  //   path: "/signingcompany/settings",
  //   name: "Settings",
  //   imgIcon: importSideMenuImgIcon("settings.png"),
  //   component: Settings,
  //   layout: "/admin",
  // },
  // {
  //   path: "/signingcompany/achsettings",
  //   name: "Ach Settings",
  //   imgIcon: importSideMenuImgIcon("settings.png"),
  //   component: AchSettings,
  //   layout: "/admin",
  // },
  {
    path: "/signingcompany/subscription",
    name: "Subscription",
    headerName: "My Subscription",
    imgIcon: importSideMenuImgIcon("subscription.svg"),
    svgIcon: "subscription",
    component: Subscription,
    layout: "/admin",
  },
  // {
  //   path: "/signingcompany/faq",
  //   name: "FAQ",
  //   imgIcon: importSideMenuImgIcon("faq.png"),
  //   component: FAQCOMPANY,
  //   layout: "/admin",
  // },
  // {
  //   path: "/signingcompany/help",
  //   name: "Help",
  //   headerName: "Help",
  //   imgIcon: importSideMenuImgIcon("faq.png"),
  //   component: Help,
  //   layout: "/admin",
  // },
];

export const agentRoutes = [
  // For Notary/Attorney
  {
    path: "/agent/closing-master-schedule",
    name: "Master Schedule",
    headerName: "Master Schedule",
    imgIcon: importSideMenuImgIcon("signingClosingMasterSchedule.png"),
    svgIcon: "masterSchedule",
    component: ClosingMasterScheduleAgent,
    layout: "/admin",
  },
  {
    path: "/agent/dashboard",
    name: "Order Dashboard",
    headerName: "Order Dashboard",
    imgIcon: importSideMenuImgIcon("signingClosingDashboard.png"),
    svgIcon: "orderDashboard",
    component: AgentDashboard,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Marketing",
    imgIcon: importSideMenuImgIcon("signingReporting.png"),
    svgIcon: "marketing",
    state: "marketingCollapse",
    views: [
      {
        // path: "/agent/clients",
        path: "/agent/marketing/clients",
        name: "Clients",
        headerName: "Clients",
        imgIcon: importSideMenuImgIcon("signingClients.png"),
        component: ClientsAgent,
        layout: "/admin",
      },
      {
        path: "/agent/marketing/templates",
        name: "Templates",
        headerName: "Templates",
        imgIcon: importSideMenuImgIcon("template.svg"),
        component: Templates,
        layout: "/admin",
      },
      // {
      //   path: "/agent/marketing/social-media-management",
      //   name: "Social Media",
      //   headerName: "Social Media Management",
      //   imgIcon: importSideMenuImgIcon("socialmedia.svg"),
      //   component: SocialMediaManagement,
      //   layout: "/admin",
      // },
      {
        path: "/agent/marketing/personal-website-management",
        name: "Personal Website Management ",
        headerName: "Personal Website Management",
        imgIcon: importSideMenuImgIcon("agentSubscription.svg"),
        // component: PersonalWebsite,
        component: PersonalWebsiteManagement,
        layout: "/admin",
      },
    ],
  },
  {
    collapse: true,
    name: "Accounting",
    imgIcon: importSideMenuImgIcon("agentAccounting.svg"),
    svgIcon: "accounting",
    state: "accountingCollapse",
    views: [
      {
        path: "/agent/accounting/income-list",
        name: "Income",
        headerName: "Income List",
        imgIcon: importSideMenuImgIcon("income.png"),
        component: AgentCompanyIncomeListAccounting,
        layout: "/admin",
      },
      {
        path: "/agent/accounting/expenses",
        name: "Expenses",
        headerName: "Expenses",
        imgIcon: importSideMenuImgIcon("billing.png"),
        component: AgentCompanyExpenses,
        layout: "/admin",
      },
      {
        path: "/agent/accounting/invoicing",
        name: "Invoicing",
        headerName: "Invoicing",
        imgIcon: importSideMenuImgIcon("invoicing.png"),
        component: AgentCompanyInvoicing,
        layout: "/admin",
      },
      {
        path: "/agent/accounting/profit-loss",
        name: "Profit & Loss",
        headerName: "Profit & Loss",
        imgIcon: importSideMenuImgIcon("profit-loss.png"),
        // component: ProfitAndLossReportingAgent,
        component: AgentCompanyProfitLoss,
        layout: "/admin",
      },
      // {
      //   path: "/agent/accounting/summary",
      //   name: "Summary",
      //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
      //   component: SummaryAccountingAgent,
      //   layout: "/admin",
      // },
      // {
      //   path: "/agent/accounting/mileage",
      //   name: "Mileage",
      //   imgIcon: importSideMenuImgIcon("closing-master-schedule.png"),
      //   component: MileageAccountingAgent,
      //   layout: "/admin",
      // },
    ],
  },
  {
    collapse: true,
    name: "Reporting",
    imgIcon: importSideMenuImgIcon("signingReporting.png"),
    svgIcon: "reporting",
    state: "reportingCollapse",
    views: [
      {
        path: "/agent/reporting/mileage-log",
        name: "Mileage Log",
        headerName: "Mileage Log",
        imgIcon: importSideMenuImgIcon("mileage.png"),
        component: MileageLogReportingAgent,
        layout: "/admin",
      },
      // {
      //   path: "/agent/reporting/expense-summaries",
      //   name: "Expense Summaries",
      //   imgIcon: importSideMenuImgIcon("billing.png"),
      //   component: ExpenseSummariesReportingAgent,
      //   layout: "/admin",
      // },
      // {
      //   path: "/agent/reporting/receipts",
      //   name: "Receipts",
      //   imgIcon: importSideMenuImgIcon("billing.png"),
      //   component: ReceiptsReportingAgent,
      //   layout: "/admin",
      // },
      {
        path: "/agent/reporting/status-report",
        name: "Status Report",
        headerName: "Status Report",
        imgIcon: importSideMenuImgIcon("summary.png"),
        component: AgentCompanyStatusReport,
        layout: "/admin",
      },
      {
        path: "/agent/reporting/notarial-log",
        name: "Notarial Log",
        headerName: "Notarial Log",
        imgIcon: importSideMenuImgIcon("summary.png"),
        component: NotarialLog,
        layout: "/admin",
      },
      {
        path: "/agent/reporting/summaries",
        name: "Summaries",
        headerName: "Summaries",
        imgIcon: importSideMenuImgIcon("summary.png"),
        // component: SummariesReportingAgent,
        component: AgentCompanySummaries,
        layout: "/admin",
      },
    ],
  },
  // {
  //   path: "/agent/console",
  //   name: "search console",
  //   headerName: "Search Console",
  //   imgIcon: importSideMenuImgIcon("subscription.svg"),
  //   svgIcon: "consoleIcon",
  //   component: GoogleSearchConsole,
  //   layout: "/admin",
  // },
  {
    path: "/agent/subscription",
    name: "Subscription",
    headerName: "Subscription",
    imgIcon: importSideMenuImgIcon("subscription.svg"),
    svgIcon: "subscription",
    component: SubscriptionAgent,
    layout: "/admin",
  },
  // {
  //   path: "/agent/credit-card",
  //   name: "Credit Card",
  //   headerName: "Credit Card",
  //   imgIcon: importSideMenuImgIcon("subscription.svg"),
  //   svgIcon: "subscription",
  //   component: AgentCreditCard,
  //   layout: "/admin",
  // },
  {
    path: "/agent/profile",
    name: "Profile",
    headerName: "My Profile",
    imgIcon: importSideMenuImgIcon("agentProfile.svg"),
    svgIcon: "profile",
    component: AgentProfile,
    layout: "/admin",
  },
];

export const clientRoutes = [
  // for client Company
  {
    path: "/client/order-dashboard",
    name: "Order Dashboard",
    headerName: "Order Dashboard",
    imgIcon: importSideMenuImgIcon("signingClosingDashboard.png"),
    svgIcon: "orderDashboard",
    component: OrderDashboard,
    layout: "/admin",
  },
  {
    path: "/client/order-client",
    name: "Clients Management",
    headerName: "Order Client",
    imgIcon: importSideMenuImgIcon("clientsManagement.svg"),
    svgIcon: "clientManagement",
    component: OrderClient,
    layout: "/admin",
  },
  {
    path: "/client/signing-company",
    // name: "Signing & Closing Connect Companies",
    name: "Connected Companies",
    headerName: "Signing Companies",
    imgIcon: importSideMenuImgIcon("clientSigningClosing.svg"),
    svgIcon: "company",
    component: SigningCompanies,
    layout: "/admin",
  },
  {
    path: "/client/client-payment",
    // name: "Connect Pay",
    name: "Connect Pay",
    headerName: "Connect Pay",
    imgIcon: importSideMenuImgIcon("clientConnectPay.svg"),
    svgIcon: "connectPay",
    component: ClientPayment,
    layout: "/admin",
  },
  {
    path: "/client/team-member",
    name: "Team Members",
    headerName: "Team Members",
    imgIcon: importSideMenuImgIcon("signingUsers.png"),
    svgIcon: "teamMembers",
    component: ClientTeamMember,
    layout: "/admin",
  },
  {
    path: "/client/profile",
    name: "Profile",
    headerName: "Client Profile",
    imgIcon: importSideMenuImgIcon("clientprofile.svg"),
    svgIcon: "profile",
    component: ClientProfile,
    layout: "/admin",
  },
  // {
  //   path: "/client/terms",
  //   name: "Terms",
  //   headerName: "Terms",
  //   imgIcon: importSideMenuImgIcon("settings.png"),
  //   svgIcon: "signingClosingMasterSchedule",
  //   component: Terms,
  //   layout: "/admin",
  // },
];
