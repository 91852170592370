import { useEffect } from "react";
import { convertHexToRgbColor } from "../../../../../../helper-methods";
// import { useGetThemeLazyQuery } from "@/graphql/generated-types";

const useTheme = (websiteDetail) => {
  // const [getTheme] = useGetThemeLazyQuery();
  // const [themeData, setThemeData] = useState({
  //   appLogo: "",
  //   appName: "",
  //   appThemeHexColor: "",
  //   domainName: "",
  //   error: false,
  //   reason: null,
  // });

  // console.log("websiteDetail >>", websiteDetail);
  const customColor = websiteDetail?.isDefaultUiSelected
    ? websiteDetail?.defaultUiSetting
    : websiteDetail?.customizedUiSetting;

  const _setThemeColor = (color, attribute) => {
    // console.log("Sauvik", { color, attribute });
    try {
      if (!color) return;

      document.documentElement.style.setProperty(attribute, color);

      const { r, g, b } = convertHexToRgbColor(color);

      const rgbaColor = `${r}, ${g}, ${b}`;

      document.documentElement.style.setProperty(`${attribute}-rgb`, rgbaColor);
      document.documentElement.style.setProperty("--visible", "visible");
    } catch (error) {
      // errorHandler(error);
    }
  };

  useEffect(() => {
    _setThemeColor(customColor?.primary, "--primaryWebsite");
    _setThemeColor(customColor?.secondary, "--secondaryWebsite");
    _setThemeColor(customColor?.secondaryText, "--secondaryTextWebsite");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customColor]);

  // no need to return anything as we are directly setting the css variables from here
  return "";
};

export default useTheme;
