import React, { lazy, Suspense } from "react";
import PersonalWebsiteNavBar from "./component/PersonalWebsiteNavBar";
import PersonalWebsiteFooter from "./component/PersonalWebsiteFooter";
import PersonalWebsiteTopHeader from "./component/PersonalWebsiteTopHeader";
import { useState } from "react";
import SvgIcons from "../../../components/SvgIcons";
import ColorSelectorSection from "./component/ColorSelectorSection";
import useWebsiteDataManage from "./hooks/useWebsiteDataManage";
import { Button } from "reactstrap";
import CustomLoading from "../../../components/CustomLoading";
import useTheme from "./hooks/useTheme";
import { showToast } from "../../../../../helper-methods";
const WebsiteLaunchedModal = lazy(() =>
  import("../../../components/Agents/WebsiteLaunchedModal")
);

const PersonalWebsite = ({
  websiteLaunchedModal,
  togglewebsiteLaunchedModal,
}) => {
  const [colorSetup, setColorSetup] = useState(false);
  const [previewUrls, setPreviewUrls] = useState({});

  const _handlePreviewUrl = (key, url, id = undefined, isMultiple = false) => {
    console.log("_handlePreviewUrl >>", { key, id, url, isMultiple });

    setPreviewUrls((prev) => {
      if (isMultiple) {
        if (id) {
          return {
            ...prev,
            [key]: {
              ...(prev[key] || {}),
              [id]: url, // Store URL with unique ID
            },
          };
        }
      } else {
        return {
          ...prev,
          [key]: url, // Store single URL
        };
      }
    });
  };

  const [show, setShow] = useState(false);

  const {
    websiteData,
    setWebsiteData,
    loading,
    createWebsiteData,
    updateWebsiteData,
    publishWebsiteData,
    manageLoading,
    rerender,
  } = useWebsiteDataManage(togglewebsiteLaunchedModal);

  useTheme(websiteData);

  console.log("websiteData PersonalWebsite>>", websiteData);

  // basically the previous "publish" api is now the "save" api and there's a new api for "publish" btn
  const handleCreateOrUpdateWebsite = (isShowToaster = false) => {
    return new Promise(async (resolve) => {
      console.log("handleCreateOrUpdateWebsite websiteData >>", websiteData);
      // console.log("handleCreateOrUpdateWebsite homepageData >>", homepageData);

      let res = false;
      try {
        if (websiteData?._id) {
          // console.log("updateWebsiteData >>", websiteData);
          res = await updateWebsiteData(websiteData);
          resolve(res);
        } else {
          res = await createWebsiteData();
          resolve(res);
        }

        if (isShowToaster && res) {
          showToast("Website Saved Successfully", "success");
        }
      } catch (error) {
        resolve(false);
        console.error("Error creating/updating website:", error);
      }
    });
  };

  // newpublish btn api: here we will first make the save call to save data, and then a publish website call to publish it to the website
  const _handlePublishBtn = async () => {
    try {
      const saveDataRes = await handleCreateOrUpdateWebsite();
      console.log({ saveDataRes });

      // if saved properly , then make a publish call
      if (saveDataRes) {
        await publishWebsiteData();
      } else {
        throw new Error();
      }
    } catch (err) {
      console.log({ err });
      // errorHandler({ reason: "Error publishing website" });
    }
  };

  const onInputHandler = ({
    title,
    data,
    type,
    key,
    index,
    arrayKey,
    id,
    action = "update",
    subKey = undefined,
  }) => {
    console.log("onInputHandler >>", {
      title,
      data,
      type,
      key,
      index,
      arrayKey,
      id,
      action,
      subKey,
    });

    try {
      setWebsiteData((prev) => {
        const updatedData = { ...prev };

        if (type === "object") {
          // Handle object updates (e.g., faqtitle)
          if (subKey) {
            // handle object updates with nested keys
            updatedData[key][subKey] = {
              ...updatedData[key][subKey],
              [title]: data,
            };
          } else {
            updatedData[key] = {
              ...updatedData[key],
              [title]: data,
            };
          }
        } else if (type === "array") {
          if (!updatedData[key]) {
            updatedData[key] = {};
          }

          if (!Array.isArray(updatedData[key][arrayKey])) {
            updatedData[key][arrayKey] = [];
          }

          if (action === "add") {
            // Add a new item to the array
            updatedData[key][arrayKey] = [...updatedData[key][arrayKey], data];
          } else if (action === "remove" && index !== undefined) {
            // Remove item at specified index
            updatedData[key][arrayKey] = updatedData[key][arrayKey].filter(
              (_, i) => i !== index
            );
          } else if (action === "update" && index !== undefined) {
            // Update existing object at index
            if (title) {
              if (updatedData[key][arrayKey][index]) {
                updatedData[key][arrayKey][index] = {
                  ...updatedData[key][arrayKey][index],
                  [title]: data,
                };
              }
            } else {
              // done this specifically to handle cases where we have to directly store string values in array eg: featureImages array in aboutUsSection
              // if (updatedData[key][arrayKey][index]) {
              updatedData[key][arrayKey][index] = data;
              // }
            }
          }

          // Ensure proper array structure
          updatedData[key][arrayKey] = [...updatedData[key][arrayKey]];
        } else if (type === "string") {
          updatedData[key] = data; // simple assignment for string values
        } else if (type === "overwrite") {
          Object.assign(updatedData, data); // simply overwrite that part of website data with the incoming data in 'data' key => useful when need to update data of multiple keys at once
        }

        console.log("updatedData >>", updatedData);
        return updatedData;
      });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  if (loading?.fetchLoading) {
    return (
      <CustomLoading text="Setting up your website builder... Hang tight!" />
    );
  }

  console.log("previewUrls >> ", previewUrls);
  return (
    <>
      <div className="agentPageBuilder">
        <div
          className={`themeColorSetup ${colorSetup === true ? "show" : " "}`}
        >
          <div
            className="settingIcon"
            onClick={() => setColorSetup(!colorSetup)}
          >
            <SvgIcons type={"gearIcon"} />
          </div>

          <ColorSelectorSection
            websiteData={websiteData}
            onInputHandler={onInputHandler}
            loading={loading}
            setColorSetup={setColorSetup}
          />
        </div>

        <PersonalWebsiteTopHeader
          websiteData={websiteData}
          show={show}
          setShow={setShow}
          onInputHandler={onInputHandler}
          loading={loading}
          manageLoading={manageLoading}
          rerender={rerender}
          previewUrls={previewUrls}
          handlePreviewUrl={_handlePreviewUrl}
        />
        <PersonalWebsiteNavBar
          show={show}
          websiteData={websiteData}
          setShow={setShow}
          onInputHandler={onInputHandler}
          setWebsiteData={setWebsiteData}
          loading={loading}
          manageLoading={manageLoading}
          rerender={rerender}
          previewUrls={previewUrls}
          handlePreviewUrl={_handlePreviewUrl}
        />
        <PersonalWebsiteFooter
          websiteData={websiteData}
          onInputHandler={onInputHandler}
          loading={loading}
          manageLoading={manageLoading}
          rerender={rerender}
          previewUrls={previewUrls}
          handlePreviewUrl={_handlePreviewUrl}
        />

        <div className="publishBottomWrapper" style={{ zIndex: 999 }}>
          <Button
            color="info"
            outline
            onClick={() => handleCreateOrUpdateWebsite(true)}
            disabled={
              loading?.submitLoading ||
              loading?.imageUploadLoading ||
              loading?.publishLoading
            }
            className="mr-2"
          >
            {loading?.submitLoading ? "Saving..." : "Save"}{" "}
            {loading?.submitLoading ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}
          </Button>

          <Button
            color="info"
            onClick={_handlePublishBtn}
            disabled={
              loading?.publishLoading ||
              loading?.imageUploadLoading ||
              loading?.submitLoading
            }
          >
            {loading?.publishLoading ? "Publishing..." : "Publish"}{" "}
            {loading?.publishLoading ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}
          </Button>
        </div>
      </div>

      <Suspense fallback={<></>}>
        <WebsiteLaunchedModal
          isOpen={websiteLaunchedModal?.isOpen}
          toggle={togglewebsiteLaunchedModal}
          agentWebsiteDetails={websiteData}
        />
      </Suspense>
    </>
  );
};

export default PersonalWebsite;
