/* eslint-disable no-unused-vars */
import ReactQuill from "react-quill";
import Select from "react-select";
import "react-quill/dist/quill.snow.css";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import {
  capitalize,
  convertImageToBase64,
  deepCompare,
  errorHandler,
  uploadFileOnServer,
} from "../../../../../helper-methods/index";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import useFormValidation from "../../../hooks/useFormValidation";
import { addBlogValidationConfig } from "../../../../../config/addBlogValidation";
import { BLOG_CATEGORIES } from "../../../../../config/blog-config";
import SvgIcons from "../../SvgIcons";
import ImageCropModal from "../../Agents/ImageCropModal";

const CreateBlogModal = ({
  data,
  isOpen,
  tags,
  toggle,
  // onAddOrEditBlog,
  onClose,
  onLoad,
  createBlog,
  updateBlog,
}) => {
  console.log({ data });
  const [loading, setLoading] = useState({
    draftLoading: false,
    publishLoading: false,
  });

  const publishStatusRef = useRef("draft");
  const modalRef = useRef(null);
  const reactQuillRef = useRef(null);

  const [imageCropModal, setImageCropModal] = useState({
    isOpen: false,
    data: null,
    fieldName: null,
    fileType: null,
  });

  const _manageLoading = (key, value) => {
    setLoading((prev) => ({ ...prev, [key]: value }));
  };

  const {
    title: draftTitle,
    url: draftUrl,
    description: draftDescription,
    tags: draftTags,
    category: draftCategory,
    content: draftContent,
    coverImage: draftCoverImage,
    metaTitle: draftMetaTitle,
    authorName: deaftAuthorName,
    alt: deaftAlt,
  } = data?.draftFields?.[0] || {};

  const blogCategoryOptions = draftCategory?.length
    ? draftCategory
    : data?.category;
  const blogTagOptions = draftTags?.length ? draftTags : data?.tags;

  const categories = blogCategoryOptions?.map((category) => ({
    label: category,
    value: category,
  }));
  const tagOptions = tags?.map((tag) => ({ label: tag, value: tag }));
  const blogTags = blogTagOptions?.map((tag) => ({ label: tag, value: tag }));

  const initialFormValues = useMemo(() => {
    return {
      title: draftTitle || data?.title || "",
      url: draftUrl || data?.url || "",
      description: draftDescription || data?.description || "",
      category: draftCategory || categories?.length ? categories : "",
      tags: blogTags?.length ? blogTags : "",
      content: draftContent || data?.content || "",
      coverImage: {
        file: {
          uploadData: null,
          previewBlob: null,
          uploadedUrl: draftCoverImage || data?.coverImage || null,
        },
      },
      metaTitle: draftMetaTitle || data?.metaTitle || "",
      authorName: deaftAuthorName || data?.authorName || "",
      alt: deaftAlt || data?.alt || "",
    };
  }, [
    draftTitle,
    data?.title,
    data?.url,
    data?.description,
    data?.content,
    data?.coverImage,
    data?.metaTitle,
    data?.authorName,
    data?.alt,
    draftUrl,
    draftDescription,
    draftCategory,
    categories,
    blogTags,
    draftContent,
    draftCoverImage,
    draftMetaTitle,
    deaftAuthorName,
    deaftAlt,
  ]);

  const {
    formErrors,
    formValues,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    hasError,
    setFormValues,
    setFormErrors,
  } = useFormValidation(initialFormValues, _onSubmit, addBlogValidationConfig);

  // Checking if any field in the blog is updated
  const isBlogUpdated = useMemo(() => {
    const isSame = deepCompare(initialFormValues, formValues);
    return !isSame;
  }, [formValues, initialFormValues]);

  const IS_EDIT_MODE = data !== null;
  const blogStatus = data?.draftFields?.length
    ? "Draft"
    : capitalize(data?.status) || "N/A";

  const _closeModal = () => {
    toggle(false);
    setFormValues({
      title: "",
      url: "",
      category: "",
      tags: "",
      content: "",
    });
    setFormErrors({});
    onClose();
  };

  const _toggleImageCropModal = (
    isOpen = false,
    data = null,
    fieldName = "",
    fileType = "",
    fileName = ""
  ) => {
    setImageCropModal({ isOpen, data, fieldName, fileType, fileName });
  };

  const _setCroppedImg = (objFile, fileType) => {
    const newFormValues = { ...formValues };

    newFormValues.coverImage.file = {
      previewBlob: URL.createObjectURL(objFile),
      uploadData: objFile,
      type: fileType === "application" ? "pdf" : fileType,
    };
    if (newFormValues?.coverImage?.uploadedUrl) {
      delete newFormValues?.coverImage?.["uploadedUrl"];
    }

    setFormValues(newFormValues);
  };

  const _handleChange = (key, value, type = "text") => {
    // Regex to find links that don't start with http:// or https://
    const updatedValue =
      key === "content"
        ? value?.replace(
          /<a\s+(?:[^>]*?\s+)?href=["'](?!https?:\/\/)([^"']*)["']/g,
          (match, p1) => {
            return match.replace(p1, `https://${p1}`);
          }
        )
        : value;

    // simulating event object here
    handleInputChange({ target: { name: key, value: updatedValue, type } });
  };

  const _handleBlur = (key, value, type = "text") => {
    // simulating event object here
    handleInputBlur({
      target: { name: key, value: formValues?.[key], type },
    });
  };

  const _handleUploadCoverImage = async (e) => {
    const file = e.target?.files?.[0];
    const objFileType = file?.type?.split("/")?.[0];

    delete formErrors?.coverImage;
    setFormErrors(formErrors);

    _toggleImageCropModal(
      true,
      URL.createObjectURL(file),
      "coverImage",
      objFileType,
      file?.name
    );
  };

  const _removeCoverImage = () => {
    setFormValues({
      ...formValues,
      coverImage: {
        file: {
          uploadData: null,
          previewBlob: null,
          uploadedUrl: null,
        },
      },
    });
  };

  const _imageUploadHandler = useCallback(() => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();
    input.onchange = async () => {
      if (input !== null && input.files !== null) {
        const file = input.files[0];
        const base64 = await convertImageToBase64(file);
        const quill = reactQuillRef.current;
        if (quill) {
          const range = quill.getEditorSelection();
          range && quill.getEditor().insertEmbed(range.index, "image", base64);
        }
      }
    };
  }, []);

  const _saveBlogAsDraft = async (e) => {
    _manageLoading("draftLoading", true);
    publishStatusRef.current = "draft";

    await handleSubmit(e, formValues);

    // if (hasError) modalRef?.current?.scrollIntoView({ behavior: "smooth" });
    _manageLoading("draftLoading", false);
  };

  const _publishBlog = async (e) => {
    _manageLoading("publishLoading", true);
    publishStatusRef.current = "published";

    await handleSubmit(e, formValues);
    // if (hasError) modalRef?.current?.scrollIntoView({ behavior: "smooth" });
    _manageLoading("publishLoading", false);
  };

  async function _onSubmit(values) {
    try {
      const payload = {
        ...values,
        category: values?.category.map((category) => category.value),
        tags: values?.tags.map((tag) => tag.value),
        status: publishStatusRef?.current || "draft",
        isPublished: publishStatusRef?.current === "published" ? true : false, // whether the blog is published or not
      };

      if (values?.coverImage?.file?.uploadData) {
        const uploadedFilesRes = await uploadFileOnServer([
          values?.coverImage?.file,
        ]);
        payload["coverImage"] = uploadedFilesRes?.[0]?.url;
      } else if (values?.coverImage?.file?.uploadedUrl) {
        payload["coverImage"] = values?.coverImage?.file?.uploadedUrl;
      } else {
        payload["coverImage"] = "";
      }

      const res = IS_EDIT_MODE
        ? await updateBlog({
          payload,
          id: data?._id,
          previewUrl: values?.coverImage?.file?.previewBlob || undefined, // to be used for preview url for opening edit modal again without refreshing the page as we are optimistically updating the blog, so the image is not shown in the edit modal due to permission issue
        })
        : await createBlog({
          payload,
          previewUrl: values?.coverImage?.file?.previewBlob || undefined,
        });

      if (!res?.error) {
        // onAddOrEditBlog();
        _closeModal();
        // showToast(
        //   IS_EDIT_MODE
        //     ? "Blog updated successfully"
        //     : "Blog created successfully",
        //   "success"
        // );
      }
    } catch (error) {
      errorHandler(error);
    }
  }

  useEffect(() => {
    if (hasError) modalRef.current.scrollIntoView({ behavior: "smooth" });
  }, [hasError]);

  useEffect(() => {
    if (onLoad) onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log({ formValues });
  return (
    <>
      <Modal isOpen={isOpen} size="lg" centered>
        <ModalHeader toggle={_closeModal}>
          {IS_EDIT_MODE ? "Edit Blog" : "Create New Blog"}{" "}
          {loading?.publishLoading || loading?.draftLoading ? (
            <i className="fa fa-spinner fa-spin ml-2" />
          ) : null}
        </ModalHeader>
        <ModalBody>
          <div ref={modalRef}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Title</Label>
                  <Input
                    placeholder="Enter"
                    name="title"
                    value={formValues?.title}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  {formErrors?.title ? (
                    <div className="validation-error">{formErrors?.title}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Slug</Label>
                  <i className="fa fa-info-circle ml-1" id="slug" />
                  <UncontrolledTooltip placement="right" target="slug">
                    This contains parts of the title separated by "-" or "_"
                    that helps in SEO ranking
                  </UncontrolledTooltip>
                  <Input
                    placeholder="E.g. my_blog_post or my-blog-post"
                    name="url"
                    value={formValues?.url}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  {formErrors?.url ? (
                    <div className="validation-error">{formErrors?.url}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Tags</Label>
                  <CreatableSelect
                    isMulti // Enables multiple selection
                    options={tagOptions}
                    placeholder="Select tags..."
                    name="tags"
                    value={formValues?.tags}
                    onChange={(options) =>
                      _handleChange("tags", options, "select")
                    }
                    onBlur={() =>
                      _handleBlur("tags", formValues?.tags, "select")
                    }
                  />
                  {formErrors?.tags ? (
                    <div className="validation-error">{formErrors?.tags}</div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Category</Label>
                  <Select
                    isMulti // Enables multiple selection
                    options={BLOG_CATEGORIES}
                    placeholder="Select category..."
                    name="category"
                    value={formValues?.category}
                    onChange={(options) =>
                      _handleChange("category", options, "select")
                    }
                    onBlur={() =>
                      _handleBlur("category", formValues?.category, "select")
                    }
                  />
                  {formErrors?.category ? (
                    <div className="validation-error">
                      {formErrors?.category}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Author name</Label>
                  <Input
                    placeholder="Enter"
                    name="authorName"
                    value={formValues?.authorName}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  {formErrors?.authorName ? (
                    <div className="validation-error">
                      {formErrors?.authorName}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label>Meta Title</Label>
                  <Input
                    placeholder="Enter"
                    name="metaTitle"
                    value={formValues?.metaTitle}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  {formErrors?.metaTitle ? (
                    <div className="validation-error">
                      {formErrors?.metaTitle}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label>Meta Description</Label>
                  <i className="fa fa-info-circle ml-1" id="description_seo" />
                  <UncontrolledTooltip
                    placement="right"
                    target="description_seo"
                  >
                    This contains description that helps in SEO ranking
                  </UncontrolledTooltip>
                  <Input
                    type="textarea"
                    rows={5}
                    placeholder="Enter"
                    name="description"
                    value={formValues?.description}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                  />
                  {formErrors?.description ? (
                    <div className="validation-error">
                      {formErrors?.description}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label className="uploadBtn outline mr-3">
                    <Input
                      hidden
                      // key={coverImageKey}
                      type="file"
                      name="coverImage"
                      onChange={_handleUploadCoverImage}
                      accept="image/*"
                    />
                    <SvgIcons type={"upload"} />
                    Upload Cover Image
                  </Label>

                  {formErrors?.coverImage ? (
                    <div className="validation-error">
                      {formErrors?.coverImage}
                    </div>
                  ) : null}

                  {(formValues?.coverImage?.file?.previewBlob ||
                    formValues?.coverImage?.file?.uploadedUrl) && (
                      <div className="blogCoverImage">
                        <img
                          src={
                            formValues?.coverImage?.file?.previewBlob ||
                            formValues?.coverImage?.file?.uploadedUrl
                          }
                          alt="coverImage"
                        />
                        <div className="closeImage" onClick={_removeCoverImage}>
                          <i className="fa fa-close" />
                        </div>
                      </div>
                    )}
                </FormGroup>
              </Col>
              {(formValues?.coverImage?.file?.previewBlob ||
                formValues?.coverImage?.file?.uploadedUrl) && (
                  <Col md={6}>
                    <FormGroup>
                      <Label>Alt Text</Label>
                      <Input
                        placeholder="Enter"
                        name="alt"
                        value={formValues?.alt}
                        onChange={handleInputChange}
                        onBlur={handleInputBlur}
                      />
                      {formErrors?.alt ? (
                        <div className="validation-error">{formErrors?.alt}</div>
                      ) : null}
                    </FormGroup>
                  </Col>
                )}

              <Col md={12}>
                <FormGroup>
                  <Label>Description</Label>
                  <ReactQuill
                    ref={reactQuillRef}
                    theme="snow"
                    value={formValues?.content}
                    modules={{
                      toolbar: {
                        container: [
                          [{ header: [1, 2, 3, 4, 5, false] }],
                          // [{ size: [] }],
                          ["bold", "italic", "underline", "strike"],
                          [
                            { list: "ordered" },
                            { list: "bullet" },
                            { indent: "-1" },
                            { indent: "+1" },
                          ],
                          [
                            { align: "" },
                            { align: "center" },
                            { align: "right" },
                          ],
                          ["link", "image"],
                        ],
                        handlers: {
                          image: _imageUploadHandler,
                        },
                      },
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    onChange={(value) => _handleChange("content", value)}
                    onBlur={() => _handleBlur("content", formValues?.content)}
                    placeholder="Write something amazing..."
                  />
                  {formErrors?.content ? (
                    <div className="validation-error">
                      {formErrors?.content}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            disabled={
              loading?.draftLoading ||
              loading?.publishLoading ||
              (IS_EDIT_MODE && !isBlogUpdated) ||
              data?.status === "published"
            }
            onClick={_saveBlogAsDraft}
            outline
          >
            Draft{" "}
            {loading?.draftLoading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : null}
          </Button>{" "}
          <Button
            color="primary"
            onClick={_publishBlog}
            disabled={
              loading?.publishLoading ||
              loading?.draftLoading ||
              (IS_EDIT_MODE && blogStatus !== "Draft" && !isBlogUpdated)
            }
          >
            Publish{" "}
            {loading?.publishLoading ? (
              <i className="fa fa-spinner fa-spin" />
            ) : null}
          </Button>
        </ModalFooter>

        {imageCropModal?.isOpen ? (
          <ImageCropModal
            isOpen={imageCropModal?.isOpen}
            imgData={imageCropModal?.data}
            fieldName={imageCropModal?.fieldName}
            fileType={imageCropModal?.fileType}
            toggle={_toggleImageCropModal}
            onSuccess={(objFile, fileType) => _setCroppedImg(objFile, fileType)}
          />
        ) : null}
      </Modal>
    </>
  );
};

export default CreateBlogModal;

// comment
