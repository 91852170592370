import React, { lazy, Suspense, useState } from "react";
import { Nav, NavLink, NavItem, TabContent, TabPane } from "reactstrap";

const PersonalWebsiteHome = lazy(() => import("../pages/PersonalWebsiteHome"));
const PersonalWebsiteAbout = lazy(() =>
  import("../pages/PersonalWebsiteAbout")
);
const PersonalWebsiteClientLogin = lazy(() =>
  import("../pages/PersonalWebsiteClientLogin")
);
const PersonalWebsiteClosingServices = lazy(() =>
  import("../pages/PersonalWebsiteClosingServices")
);
const PersonalWebsiteServiceArea = lazy(() =>
  import("../pages/PersonalWebsiteServiceArea")
);
const PersonalWebsiteContactus = lazy(() =>
  import("../pages/PersonalWebsiteContactus")
);

const PersonalWebsiteNavBar = ({
  websiteData,
  show,
  loading,
  rerender,
  setShow,
  onInputHandler,
  setWebsiteData,
  manageLoading,
  previewUrls,
  handlePreviewUrl,
}) => {
  const personalNavLink = [
    {
      path: "/agent/marketing/personal-website-management/home",
      name: "Home",
      headerName: "Agents",
      layout: "/admin",
      components: PersonalWebsiteHome,
    },
    {
      path: "/agent/marketing/personal-website-management/closing-services",
      name: "Services",
      headerName: "Agents",
      layout: "/admin",
      components: PersonalWebsiteClosingServices,
    },
    {
      path: "/agent/marketing/personal-website-management/services-area",
      name: "Service Areas",
      headerName: "Agents",
      layout: "/admin",
      components: PersonalWebsiteServiceArea,
    },
    {
      path: "/agent/marketing/personal-website-management/about",
      name: "About Us",
      headerName: "Agents",
      layout: "/admin",
      components: PersonalWebsiteAbout,
    },
    {
      path: "/agent/marketing/personal-website-management/client-login",
      name: "Client Portal",
      headerName: "Agents",
      layout: "/admin",
      components: PersonalWebsiteClientLogin,
    },
    {
      path: "/agent/marketing/personal-website-management/contactUs",
      name: "Contact Us",
      headerName: "Agents",
      layout: "/admin",
      components: PersonalWebsiteContactus,
    },
  ];
  const [activeTab, setActiveTab] = useState("Home");

  const onToggle = (name) => {
    setActiveTab(name);
  };

  return (
    <>
      <Nav className={`headerBottom ${show ? "open" : ""}`}>
        {personalNavLink?.map((link, index) => (
          <NavItem key={index}>
            <NavLink
              className={activeTab === `${link.name}` ? "active" : ""}
              onClick={() => {
                setShow((prev) => !prev);
                onToggle(link.name);
              }}
            >
              {link.name}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {personalNavLink?.map((link, index) => {
          const ComponentRender = link.components;
          if (link.name === activeTab) {
            return (
              <TabPane key={index} tabId={link.name}>
                <Suspense fallback={<></>}>
                  <ComponentRender
                    websiteData={websiteData}
                    loading={loading}
                    rerender={rerender}
                    setWebsiteData={setWebsiteData}
                    setActiveTab={setActiveTab}
                    onInputHandler={onInputHandler}
                    manageLoading={manageLoading}
                    previewUrls={previewUrls}
                    handlePreviewUrl={handlePreviewUrl}
                  />
                </Suspense>
              </TabPane>
            );
          } else {
            return <></>;
          }
        })}
      </TabContent>
    </>
  );
};

export default PersonalWebsiteNavBar;
