import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import {
  deepClone,
  formatCurrencyValue,
  formatOnlyDateMoment,
  getLoggedInUserId,
  isRegularUser,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
  uploadFileOnServer,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { BASE_URL, itemListOptionsConfig } from "../../../config";
import {
  deleteAgentExpense,
  deleteCompanyExpense,
  fetchExpensesOfSigningCompany,
  getAllAgentExpense,
  terminateExpenseRecurring,
  updateAgentExpense,
  updateExpenseSigningCompany,
} from "../../../http/http-calls";
import { Button, Label } from "reactstrap";
import { useSelector } from "react-redux";
import InfoCard from "../components/infoCard";
import AddNotesModal from "../components/add-notes-income-list-modal";
import AddExpenseAccountingCompany from "../components/add-expense-accounting-company-modal";
import ViewReceiptModal from "../components/view-receipt-modal";
import DocumentViewModal from "../components/documentViewModal";
import useScreenWidth from "../components/HelpModule/useScreenWidth";
import {
  accountCompanyHeader,
  accountHeader,
} from "../../../config/stateConfig";
import AddExpense from "../components/add-expense-accounting-agent-modal";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import SvgIcons from "../components/SvgIcons";
import { Link } from "react-router-dom";
import DeleteModal from "../components/Modals/DeleteModal";

const CustomTable = lazy(() => import("../components/CustomTable"));
const CustomCard = lazy(() => import("../components/CustomCard"));
const CompanyAgentDataFormat = lazy(() =>
  import("../components/CompanyAgentDataFormat")
);
const ExpensesFilterComponent = lazy(() =>
  import("../components/ExpensesFilterComponent")
);

const DataFormatExpenses = lazy(() =>
  import("../components/expensesAgentCompanyDaraFormat")
);

const AgentCompanyExpenses = () => {
  const userData = useSelector((state) => state.userData);
  const [deleteid, setDeleteId] = useState(null);
  const [isdeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const { isForMobile } = useScreenWidth();
  const tableRef = useRef(null);
  const hasMounted = useRef(false);
  const DEFAULT_KEY_FOR_COMPANY = "signingcompany";

  const [payload, setPayload] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
    filters: {
      item: "",
      type: "",
    },
  });
  const payloadRef = useRef(payload);

  const [expensesList, setExpensesList] = useState([]);
  const [loading, setLoading] = useState({
    showTableLoading: false,
    terminateExpenseLoading: false,
    receiptLoading: false,
    deleteExpenseModal: false,
  });
  const [expenseType, setExpenseType] = useState([]);
  const [expensesTotalCount, setExpensesTotalCount] = useState(0);
  const [expensesTotalAmount, setExpensesTotalAmount] = useState(0);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [receipt, setReceipt] = useState({
    file: {
      previewBlob: null,
      uploadData: null,
      type: null,
    },
    error: null,
    isValidate: false,
    uploadingFileId: null,
  });
  const [expenseSelectedRows, setExpenseSelectedRows] = useState([]);

  const [addExpenseAccountingCompany, setAddExpenseAccountingCompany] =
    useState({
      isOpen: false,
      data: null,
    });

  const [viewReceiptModal, setViewReceiptModal] = useState({
    isOpen: false,
    data: null,
  });
  const [addNotesModal, setAddNotesModal] = useState({
    isOpen: false,
    data: null,
  });
  const [documentViewModalData, setDocumentViewModalData] = useState({
    isOpen: false,
    externalUrl: null,
    modalHeading: null,
    fileType: null,
  });
  const [addExpense, setAddExpense] = useState({ isOpen: false, data: null });
  const [upgradeAccountModal, setUpgradeAccountModal] = useState({
    isOpen: false,
    data: null,
  });

  const expensesCardHeaderKeys = [
    { id: "id", label: "id" },
    { id: "name", label: "Name" },
  ];

  const _onToggleAddExpenseAccountingCompany = (
    isOpen = false,
    data = null
  ) => {
    setAddExpenseAccountingCompany({
      isOpen: isOpen,
      data: data,
    });
  };

  const _onToggleViewReceiptModal = (isOpen = false, data = null) => {
    setViewReceiptModal({ isOpen, data });
  };

  const _onToggleAddNotesModal = (isOpen = false, data = null) => {
    setAddNotesModal({ isOpen, data });
  };

  const _onToggleDocumentViewModal = (isOpen = false, expense = null) => {
    if (expense && isOpen) {
      setDocumentViewModalData({
        isOpen: true,
        externalUrl: expense.receipt,
        modalHeading: "View Receipt",
        fileType: expense.docType,
      });
    } else {
      setDocumentViewModalData({
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      });
    }
  };
  const _onConfirmationDeletetoggle = (modalopen = false, id) => {
    setIsDeleteModalOpen(modalopen);
    setDeleteId(id);
  };

  const _onToggleAddExpense = (isOpen = false, data = null) => {
    setAddExpense({ isOpen, data });
  };
  const _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    setUpgradeAccountModal({ isOpen, data });
  };

  const _manageLoading = (loaderName, value) => {
    setLoading((prevLoading) => ({
      ...prevLoading,
      [loaderName]: value,
    }));
  };

  const filterShowMobile = () => {
    setIsClassAdded((prevIsClassAdded) => !prevIsClassAdded);
  };

  const _FetchExpensesSigningCompany = async () => {
    _manageLoading("showTableLoading", true);
    const newPayload = deepClone(payload);

    if (newPayload.filters?.dateRangeValue) {
      newPayload.filters.startDate = newPayload.filters?.dateRangeValue[0];
      newPayload.filters.endDate = newPayload.filters?.dateRangeValue[1];
      delete newPayload.filters.dateRangeValue;
    }

    try {
      const res = await fetchExpensesOfSigningCompany(newPayload);
      setExpensesList(res.expenses);
      setExpenseType(
        res.expenseTypes.map((item) => ({
          label: item,
          value: item,
        }))
      );
      setExpensesTotalCount(res.totalCount);
      setExpensesTotalAmount(res.totalAmount);
      _manageLoading("showTableLoading", false);
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
      _manageLoading("showTableLoading", false);
    }
  };

  const _getAllAgentExpense = async () => {
    _manageLoading("showTableLoading", true);

    const newPayload = deepClone(payload);

    if (newPayload.filters?.dateRangeValue) {
      newPayload.filters.startDate = newPayload.filters?.dateRangeValue[0];
      newPayload.filters.endDate = newPayload.filters?.dateRangeValue[1];
      delete newPayload.filters.dateRangeValue;
    }

    getAllAgentExpense(newPayload)
      .then((res) => {
        setExpensesList(res.expenses);
        setExpenseType(
          res.expenseTypes.map((item) => ({
            label: item,
            value: item,
          }))
        );
        setExpensesTotalCount(res.totalCount);
        setExpensesTotalAmount(res.totalAmount);
        _manageLoading("showTableLoading", false);
      })
      .catch((error) => {
        showToast(
          error && error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
        _manageLoading("showTableLoading", false);
      });
  };

  const _setFilterDataFromLocalStorage = () => {
    const isCompany = userData?.type === DEFAULT_KEY_FOR_COMPANY;
    const localStorageKey = isCompany ? "companyexpense" : "agentexpense";
    const fetchExpenses = isCompany
      ? _FetchExpensesSigningCompany
      : _getAllAgentExpense;

    if (localStorage?.getItem(localStorageKey)) {
      try {
        const storedData = JSON.parse(localStorage.getItem(localStorageKey));
        setPayload(storedData);
      } catch (error) {
        fetchExpenses();
      }
    } else {
      fetchExpenses();
    }
  };

  // function to save filter data in local storage
  const _persistFilter = () => {
    const currentPayload = payloadRef.current;

    if (
      currentPayload?.filters &&
      (currentPayload?.filters.item ||
        currentPayload?.filters.type ||
        currentPayload?.filters?.dateRangeValue !== null ||
        currentPayload.pageNumber !== 0)
    ) {
      const data = currentPayload;

      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        localStorage.setItem("companyexpense", JSON.stringify(data));
      } else {
        localStorage.setItem("agentexpense", JSON.stringify(data));
      }
    } else {
      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        localStorage.removeItem("companyexpense");
      } else {
        localStorage.removeItem("agentexpense");
      }
    }
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const stateData = deepClone(payload);

    stateData.skip = (pageNumber - 1) * pageSize;
    stateData.limit = pageSize;
    stateData["pageNumber"] = pageNumber;

    setPayload(stateData);
  };

  const _resetFilter = () => {
    setPayload({
      skip: 0,
      limit: 10,
      pageNumber: 1,
      filters: {
        item: "",
        type: "",
      },
    });
  };

  //For download receipt both agent and company side check userType
  const _downloadData = (action = "download") => {
    if (!expensesList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const finalPayload = {
      userId: getLoggedInUserId(),
    };

    if (payload?.filters?.dateRangeValue) {
      finalPayload["startDate"] = formatOnlyDateMoment(
        payload?.filters?.dateRangeValue[0]
      );
      finalPayload["endDate"] = formatOnlyDateMoment(
        payload?.filters?.dateRangeValue[1]
      );
    }
    // else {
    //   finalPayload["startDate"] = "";
    //   finalPayload["endDate"] = "";
    // }

    if (payload.filters.item) finalPayload["item"] = payload?.filters?.item;
    if (payload.filters.type) finalPayload["type"] = payload?.filters?.type;

    const queryParams = structureQueryParams(finalPayload);

    if (action === "download") {
      let expenseSelectedRowsIds = expenseSelectedRows?.map((item) => item._id);

      if (expenseSelectedRowsIds?.length) {
        openUrlOnNewTab(
          `${BASE_URL}/${userData?.type}/download/expenses${queryParams}&&expenseIds=${expenseSelectedRowsIds}`
        );
      } else {
        openUrlOnNewTab(
          `${BASE_URL}/${userData?.type}/download/expenses${queryParams}`
        );
        // }
      }
    }
  };

  const _downloadAllData = async (action = "downloadAll") => {
    const finalPayload = {
      userId: getLoggedInUserId(),
    };

    if (payload?.filters?.dateRangeValue) {
      finalPayload["startDate"] = formatOnlyDateMoment(
        payload?.filters?.dateRangeValue?.[0]
      );
      finalPayload["endDate"] = formatOnlyDateMoment(
        payload?.filters?.dateRangeValue?.[1]
      );
    }

    if (payload?.filters?.item) finalPayload["item"] = payload?.filters?.item;
    if (payload?.filters?.type) finalPayload["type"] = payload?.filters?.type;

    const queryParams = structureQueryParams(finalPayload);

    if (action === "downloadAll") {
      openUrlOnNewTab(
        `${BASE_URL}/${userData?.type}/download/all/expenses${queryParams}`
      );
    }
  }

  const _filterOnChange = (type, value) => {
    let newFilters = deepClone(payload);
    newFilters.filters[type] = value;

    setPayload({ ...newFilters, skip: 0, limit: 10, pageNumber: 1 });
  };

  // This is for terminate recurring
  const _terminateExpenseRecurring = async (expenseId) => {
    _manageLoading("terminateExpenseLoading", expenseId);
    try {
      await terminateExpenseRecurring(expenseId);
      const updatedExpensesList = expensesList.map((expense) => {
        if (expense._id === expenseId) {
          return { ...expense, recurring: false };
        }
        return expense;
      });
      setExpensesList(updatedExpensesList);
      showToast("Expense Recurring Terminated.", "success");
      _manageLoading("terminateExpenseLoading", false);
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
      _manageLoading("terminateExpenseLoading", false);
    }
  };

  //For delete agent Expense
  const _agentDeleteExpense = (id) => {
    _manageLoading("showTableLoading", true);
    _manageLoading("deleteExpenseModal", true);

    deleteAgentExpense(id)
      .then((res) => {
        _manageLoading("showTableLoading", false);
        _manageLoading("deleteExpenseModal", false);
        showToast("Deleted Successfully!", "success");
        _getAllAgentExpense();
      })
      .catch((error) => {
        showToast(
          error && error.reason && error.reason.length
            ? error.reason
            : "Server error. Try again after sometime.",
          "error"
        );
        _manageLoading("showTableLoading", false);
        _manageLoading("deleteExpenseModal", false);
      })
      .finally(() => {
        setIsDeleteModalOpen(false);
        setDeleteId(null);
        _manageLoading("showTableLoading", false);
        _manageLoading("deleteExpenseModal", false);
      });
  };

  // /For delete company Expense
  const _companyDeleteExpense = async (id) => {
    _manageLoading("deleteExpenseModal", true);
    _manageLoading("showTableLoading", true);

    try {
      await deleteCompanyExpense(id);
      showToast("Deleted Successfully!", "success");
      _FetchExpensesSigningCompany();
      _manageLoading("deleteExpenseModal", false);
      _manageLoading("showTableLoading", false);
    } catch (error) {
      showToast(
        error && error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime.",
        "error"
      );
      _manageLoading("deleteExpenseModal", false);
      _manageLoading("showTableLoading", false);
    } finally {
      _manageLoading("deleteExpenseModal", false);
      _manageLoading("showTableLoading", false);
      setIsDeleteModalOpen(false);
      setDeleteId(null);
    }
  };

  //save note for company and agent
  const _onSaveNotes = async (id, notes, updatedReceipt) => {
    try {
      let payload = {
        notes: notes?.trim() ? notes.trim() : "",
      };

      if (
        updatedReceipt &&
        updatedReceipt.file &&
        updatedReceipt.file.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          updatedReceipt.file,
        ]);
        payload["receipt"] = uploadedFilesRes[0].url;
        payload["docType"] = uploadedFilesRes[0].docType;
        payload["receiptName"] = uploadedFilesRes[0].title;
      }
      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        await updateExpenseSigningCompany(id, payload);
        _FetchExpensesSigningCompany();
      } else {
        await updateAgentExpense(id, payload);
        _getAllAgentExpense();
      }
      setReceipt({
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isValidate: false,
      });
    } catch (error) {
      throw error;
    }
  };

  //Receipt upload function
  const _updateFile = async (event, cell) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      const objFile = event.target.files[0];
      const objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        const updatedReceipt = {
          ...receipt,
          file: {
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          },
        };
        setReceipt(updatedReceipt);
        await _onSaveNotes(cell._id, null, updatedReceipt);
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  // data format manage in another component
  const _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <DataFormatExpenses
          cell={cell}
          row={row}
          header={header}
          _onToggleAddNotesModal={_onToggleAddNotesModal}
          _onToggleDocumentViewModal={_onToggleDocumentViewModal}
          _updateFile={_updateFile}
          _toggleUpgradeAccountModal={_toggleUpgradeAccountModal}
          loading={loading}
          receipt={receipt}
          _onToggleAddExpenseAccountingCompany={
            _onToggleAddExpenseAccountingCompany
          }
          _onToggleAddExpense={_onToggleAddExpense}
          _companyDeleteExpense={_companyDeleteExpense}
          _agentDeleteExpense={_agentDeleteExpense}
          _terminateExpenseRecurring={_terminateExpenseRecurring}
          _onConfirmationDeletetoggle={_onConfirmationDeletetoggle}
        />
      </Suspense>
    );
  };

  const _setSelectedRows = (expenseSelectedRows = []) => {
    try {
      expenseSelectedRows = expenseSelectedRows.filter(
        (each) => each.receipt !== undefined || each.receipt !== null
      );

      setExpenseSelectedRows(expenseSelectedRows);
    } catch (error) {
      console.log({ error });
    }
  };

  //download as zip for both side donload
  const _downloadDataAsZip = () => {
    if (expenseSelectedRows.length) {
      let expenseSelectedRowsIds = expenseSelectedRows.map((item) => item._id);
      _manageLoading("showTableLoading", true);
      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        openUrlOnNewTab(
          `${BASE_URL}/expense/download-receipts?expenseids=${JSON.stringify(
            expenseSelectedRowsIds
          )}&token=${userData.token}`
        );
      } else {
        openUrlOnNewTab(
          `${BASE_URL}/expense/download-receipts?expenseids=${JSON.stringify(
            expenseSelectedRowsIds
          )}&token=${userData.token}`
        );
      }
      _setSelectedRows();
      _manageLoading("showTableLoading", false);
    } else {
      showToast("Please select expense", "error");
    }
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="cardTableTitle">
              <Label>Type</Label>
              {userData?.type === DEFAULT_KEY_FOR_COMPANY ? (
                <span>{row?.item ? row?.item : "N/A"}</span>
              ) : (
                <span>
                  {row.item
                    ? itemListOptionsConfig.find(
                      (obj) => obj.value === row.item
                    )?.label || row.item
                    : "N/A"}
                </span>
              )}
            </div>

            <>
              <Button
                color="link"
                onClick={() =>
                  userData?.type === DEFAULT_KEY_FOR_COMPANY
                    ? _onToggleAddExpenseAccountingCompany(true, row)
                    : _onToggleAddExpense(true, row)
                }
                disabled={loading.showTableLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
              <Button
                color="link"
                className="pl-0"
                onClick={
                  () => {
                    _onConfirmationDeletetoggle(true, row?._id);
                    // console.log("mini");
                    // setIsDeleteModalOpen(true);
                    // setDeleteId(row._id);
                  }
                  // userData?.type === DEFAULT_KEY_FOR_COMPANY
                  //   ? _companyDeleteExpense(row?._id)
                  //   : _agentDeleteExpense(row?._id)
                }
                disabled={loading.showTableLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/deleteIcon.svg").default}
                  alt="delete"
                />
              </Button>
            </>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  //card data format manage in another component
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <CompanyAgentDataFormat
          row={row}
          _onToggleAddNotesModal={_onToggleAddNotesModal}
          _onToggleDocumentViewModal={_onToggleDocumentViewModal}
          _updateFile={_updateFile}
          _toggleUpgradeAccountModal={_toggleUpgradeAccountModal}
          _terminateExpenseRecurring={_terminateExpenseRecurring}
          loading={loading}
        />
      </Suspense>
    );
  };

  //This is showing data For Transactions and Total Expenses
  const _infoCardData = () => {
    let infoArray = [
      {
        name: "Transactions",
        value: expensesTotalCount,
        image: require("../../../assets/img/Transactions.svg").default,
        icon: "Transactions",
      },
      {
        name: "Total Expenses",
        value: formatCurrencyValue(expensesTotalAmount),
        image: require("../../../assets/img/TotalExpense.svg").default,
        icon: "TotalExpense",
      },
    ];
    return infoArray;
  };

  useEffect(() => {
    _setFilterDataFromLocalStorage();
    if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
      //this for company side
      const resetFilterHandler = () => {
        _resetFilter();
        refreshFunc("company-accounting-expenses");
      };

      const addExpenseHandler = () => {
        _onToggleAddExpenseAccountingCompany(true);
      };

      const downloadExpenseHandler = () => {
        _downloadData();
      };

      HeaderEventEmitter.subscribe("reset-filter", resetFilterHandler);
      HeaderEventEmitter.subscribe("add-expense", addExpenseHandler);
      HeaderEventEmitter.subscribe("download-expense", downloadExpenseHandler);

      return () => {
        HeaderEventEmitter?.unsubscribe("reset-filter", resetFilterHandler);
        HeaderEventEmitter?.unsubscribe("add-expense", addExpenseHandler);
        HeaderEventEmitter?.unsubscribe(
          "download-expense",
          downloadExpenseHandler
        );
        _persistFilter();
      };
    } else {
      //this is for agent
      const resetFilterSubscription = HeaderEventEmitter.subscribe(
        "reset-filter",
        () => {
          _resetFilter();
          refreshFunc("agent-accounting-expenses");
        }
      );

      const addExpenseSubscription = HeaderEventEmitter.subscribe(
        "add-expense",
        () => {
          _onToggleAddExpense(true);
        }
      );

      // Clean-up function to unsubscribe when component unmounts
      return () => {
        resetFilterSubscription?.unsubscribe();
        addExpenseSubscription?.unsubscribe();
        _persistFilter();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    payloadRef.current = payload;
    if (hasMounted.current) {
      if (userData?.type === DEFAULT_KEY_FOR_COMPANY) {
        _FetchExpensesSigningCompany();
      } else {
        _getAllAgentExpense();
      }
    } else {
      hasMounted.current = true;
    }
    // _persistFilter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload]);

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Expenses</h2>
          <div className="rightSide">
            <Button
              className="floatingButton"
              color="primary"
              onClick={() =>
                userData?.type === DEFAULT_KEY_FOR_COMPANY
                  ? _onToggleAddExpenseAccountingCompany(true)
                  : isRegularUser()
                    ? _onToggleAddExpense(true)
                    : _toggleUpgradeAccountModal(true)
              }
            >
              Add Expenses
            </Button>
            {userData?.type === DEFAULT_KEY_FOR_COMPANY ? null : (
              <Link to={`/agent/bulk-upload?expenses`} className="ml-2">
                Bulk upload
              </Link>
            )}

            <Button
              id="company-accounting-expenses-responsive"
              color="link"
              onClick={_resetFilter}
            >
              {/* <img

                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              /> */}
              <SvgIcons type="refresh" />
            </Button>

            <Button
              className="filterBarIcon"
              Mileage
              color="link"
              onClick={filterShowMobile}
            >
              <SvgIcons type="filterIcon" />
            </Button>
          </div>
        </div>
        <div className={`mobileFilterView ${isClassAdded ? "show" : ""}`}></div>
        <Suspense fallback={<></>}>
          <div className="d-flex justify-content-between align-items-center">
            <ExpensesFilterComponent
              isClassAdded={isClassAdded}
              filterShowMobile={filterShowMobile}
              filters={payload}
              expenseType={expenseType}
              _filterOnChange={_filterOnChange}
              _downloadData={_downloadData}
            />

            {userData?.type === "agent" ? <Button
              color="primary"
              outline
              onClick={() =>
                isRegularUser()
                  ? _downloadAllData()
                  : _toggleUpgradeAccountModal(true)
              }
              disabled={!expensesList?.length}
            >
              Download All <SvgIcons type={"download"} />
            </Button> : null}
          </div>
        </Suspense>
        <div className="downloadButtonPos">
          {userData?.type === "agent"
            ?
            <Button
              color="link"
              outline
              onClick={() =>
                isRegularUser()
                  ? _downloadAllData()
                  : _toggleUpgradeAccountModal(true)
              }
              disabled={!expensesList?.length}
            >
              Download All <SvgIcons type={"download"} />
            </Button>
            : null}

          <Button color="link" outline onClick={() =>
            isRegularUser()
              ? _downloadData()
              : _toggleUpgradeAccountModal(true)
          }>
            Download
            {/* <img
              src={require(`../../../assets/img/download_blue.png`)}
              alt="download"
            /> */}
            <SvgIcons type={"download"} />
          </Button>
        </div>
        <InfoCard
          infoCardArray={_infoCardData()}
          loading={loading.showTableLoading}
          page="expense"
        />
        {expenseSelectedRows.length ? (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginBottom: 25 }}
          >
            {/* when the checkboxes in the below table are selected/checked then below text is to be shown */}
            <div style={{ fontWeight: 500, fontSize: 14 }}>
              {expenseSelectedRows.length}
              {expenseSelectedRows.length > 1 ? " Records" : " Record"} are
              selected
            </div>
            {userData?.type === DEFAULT_KEY_FOR_COMPANY ? (
              <Button
                color="link"
                outline
                className="ml-auto"
                onClick={() => _downloadDataAsZip()}
              >
                Download Zip
                {/* <img
                  src={require(`../../../assets/img/download_blue.png`)}
                  alt="download"
                /> */}
                <SvgIcons type={"download"} />
              </Button>
            ) : (
              <Button
                color="link"
                outline
                className="ml-auto"
                onClick={() =>
                  isRegularUser()
                    ? _downloadData()
                    : _toggleUpgradeAccountModal(true)
                }
              >
                Download
                {/* <img
                  src={require(`../../../assets/img/download_blue.png`)}
                  alt="download"
                /> */}
                <SvgIcons type={"download"} />
              </Button>
            )}
          </div>
        ) : null}
        {isForMobile ? (
          <Suspense fallback={<></>}>
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={payload.pageNumber}
                tableData={expensesList}
                headerKeys={expensesCardHeaderKeys}
                dataFormat={_dataFormat}
                totalCount={expensesTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  _paginate(pageNumber, pageSize)
                }
                rowSelection={true}
                selectedRows={expenseSelectedRows}
                setSelectedRows={(selectedRows) =>
                  _setSelectedRows(selectedRows)
                }
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={_cardHeaderFormat}
                cardDataFormat={_cardDataFormat}
              />
            </div>
          </Suspense>
        ) : (
          <Suspense fallback={<></>}>
            <div className="hideMobile">
              {accountCompanyHeader &&
                accountCompanyHeader.length &&
                userData?.type === DEFAULT_KEY_FOR_COMPANY && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    pageNumber={payload.pageNumber}
                    tableData={expensesList}
                    headerKeys={accountCompanyHeader}
                    dataFormat={_dataFormat}
                    totalCount={expensesTotalCount}
                    rowSelection={true}
                    onPaginate={(pageNumber, pageSize) =>
                      _paginate(pageNumber, pageSize)
                    }
                    onSortChange={(sortName, sortOrder) => _paginate()}
                    selectedRows={expenseSelectedRows}
                    setSelectedRows={(selectedRows) =>
                      _setSelectedRows(selectedRows)
                    }
                    showTableLoading={loading.showTableLoading}
                    ref={tableRef}
                  />
                )}
              {accountHeader &&
                accountHeader.length &&
                userData?.type !== DEFAULT_KEY_FOR_COMPANY && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    pageNumber={payload.pageNumber}
                    tableData={expensesList}
                    headerKeys={accountHeader}
                    dataFormat={_dataFormat}
                    totalCount={expensesTotalCount}
                    rowSelection={true}
                    onPaginate={(pageNumber, pageSize) =>
                      _paginate(pageNumber, pageSize)
                    }
                    onSortChange={(sortName, sortOrder) =>
                      // sort function is disabled
                      _paginate()
                    }
                    selectedRows={expenseSelectedRows}
                    setSelectedRows={(selectedRows) =>
                      _setSelectedRows(selectedRows)
                    }
                    showTableLoading={loading.showTableLoading}
                    ref={tableRef}
                  />
                )}

              {/* accountHeader */}
            </div>
          </Suspense>
        )}
      </div>
      <Suspense fallback={<></>}>
        {/* for view notes */}
        <AddNotesModal
          isOpen={addNotesModal.isOpen}
          data={addNotesModal.data}
          toggle={() => _onToggleAddNotesModal()}
          onSaveNotes={(id, notes) => _onSaveNotes(id, notes)}
        />
      </Suspense>

      <Suspense fallback={<></>}>
        {/* add expense modal for agent or company */}
        {userData?.type === DEFAULT_KEY_FOR_COMPANY ? (
          <AddExpenseAccountingCompany
            isOpen={addExpenseAccountingCompany.isOpen}
            data={addExpenseAccountingCompany.data}
            toggle={() => _onToggleAddExpenseAccountingCompany()}
            resetTable={() => {
              _paginate();
              _FetchExpensesSigningCompany();
            }}
            expenseType={expenseType}
          ></AddExpenseAccountingCompany>
        ) : (
          <AddExpense
            isOpen={addExpense.isOpen}
            data={addExpense.data}
            toggle={() => _onToggleAddExpense()}
            resetDetails={() => {
              _paginate();
              _getAllAgentExpense();
            }}
            expenseType={expenseType}
          />
        )}
      </Suspense>
      <Suspense fallback={<></>}>
        {/* for view receipt */}
        <ViewReceiptModal
          isOpen={viewReceiptModal.isOpen}
          creatorData={viewReceiptModal.data}
          toggle={() => _onToggleViewReceiptModal()}
        ></ViewReceiptModal>
      </Suspense>
      <Suspense fallback={<></>}>
        {/* for view documents */}
        {documentViewModalData.isOpen && (
          <DocumentViewModal
            isOpen={documentViewModalData.isOpen}
            externalUrl={documentViewModalData.externalUrl}
            fileType={documentViewModalData.fileType}
            modalHeading={documentViewModalData.modalHeading}
            toggle={_onToggleDocumentViewModal}
          />
        )}
      </Suspense>
      <Suspense fallback={<></>}>
        <UpgradeAccountModal
          isOpen={upgradeAccountModal.isOpen}
          toggle={_toggleUpgradeAccountModal}
        />
      </Suspense>
      <DeleteModal
        isOpen={isdeleteModalOpen}
        toggle={() => {
          setIsDeleteModalOpen(false);
          setDeleteId(null);
        }}
        deleteExpense={() => {
          userData?.type === DEFAULT_KEY_FOR_COMPANY
            ? _companyDeleteExpense(deleteid)
            : _agentDeleteExpense(deleteid);
        }}
        isLoaderActive={loading.deleteExpenseModal}
        title={"Expense"}
      />
    </>
  );
};

export default AgentCompanyExpenses;
