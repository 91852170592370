import { useRef, useState } from "react";

const useFormValidation = (initialValues, submitHandler, validationConfig) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});

  const errorRef = useRef(null);

  const asyncTimerRef = useRef({ current: null });

  const _checkUnique = ({ name, value, validator }) => {
    if (asyncTimerRef?.current) clearTimeout(asyncTimerRef?.current);

    asyncTimerRef.current = setTimeout(async () => {
      try {
        const errorMessage = await validator.checkUnique?.(value);

        if (typeof errorMessage === "string") {
          updateErrorState(name, errorMessage);
          return;
        }

        updateErrorState(name, "");
      } catch (error) {
        console.log({ error });
        updateErrorState(name, "");
      }
    }, 1000);
  };

  const validateField = (name, value, onBlur = false, type = "text") => {
    // Handling field that can be multiple
    const key = name.split("_")[0];

    let errorMessage = "";
    let validator = validationConfig[key];

    // Check if the field is array
    if (validator && Array.isArray(validator)) {
      const index = +name.split("_")[1];
      validator = validator[index];
    }

    const child_key = name.split("_")[2];

    if (child_key) {
      validator = validator?.[child_key];
    }

    if (!validator) return errorMessage; // Ignore the field if no validation config is present

    // trim the value except for multi select dropdown (as it will be array)
    if (!(type === "select" && Array.isArray(value))) {
      value = typeof value === "string" ? value?.trim() || "" : value;
    }
    if (validator.required && !String(value)?.length) {
      errorMessage = validator.errorMessage?.required || "*Required";
    } else if (
      value?.length &&
      ((validator.minLength && value?.length < validator.minLength) ||
        (validator.maxLength && value?.length > validator.maxLength))
    ) {
      errorMessage =
        validator.errorMessage?.length ||
        `*Must be between ${validator.minLength} and ${validator.maxLength} characters`;
    } else if (
      value?.length &&
      validator.pattern &&
      !validator.pattern?.test(value)
    ) {
      errorMessage = validator.errorMessage?.pattern || "*Invalid format";
    } else if (validator.validator) {
      /* Checking for:-
         1. Any custom validations
         2. If the field has any dependencies on other fields. If yes, then pass the entire form values to the validator along with the current value     
      */
      errorMessage = validator.validator?.(value, formValues);
    } else if (onBlur && validator.checkUnique) {
      /* 
        Checking for:-
        unique email, phone, username
      */
      errorMessage = "Checking...";
      _checkUnique({ name, value: value, validator });
    }

    return errorMessage;
  };

  const updateErrorState = (name, errorMessage) => {
    const newErrors = { ...formErrors, [name]: errorMessage };

    // Delete the error if no error occurred
    if (!errorMessage) {
      delete newErrors[name];
    }

    if (Object.keys(newErrors).length) errorRef.current = true;
    else errorRef.current = false;

    setFormErrors(newErrors);
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    const isCheckbox = type === "checkbox";

    // Handling field that can be multiple
    const key = name.split("_")[0];
    if (Array.isArray(formValues[key]) && type !== "select") {
      // Check if the field is array and also if it's not a multi-select input field
      const index = +name.split("_")[1];

      const child_key = name.split("_")[2];

      if (child_key) {
        formValues[key][index][child_key] = value;
      } else {
        formValues[key][index] = value;
      }

      setFormValues({ ...formValues });
    } else {
      setFormValues({
        ...formValues,
        [name]: isCheckbox ? e.target.checked : value, // Handle checkbox
      });
    }

    const errorMessage = validateField(name, value, false, type);
    updateErrorState(name, errorMessage); // Update the error state
  };

  const handleInputBlur = (e) => {
    const { name, value, type } = e.target;
    const errorMessage = validateField(name, value, true, type);
    updateErrorState(name, errorMessage); // Update the error state
  };

  const handleSubmit = async (e, formValues) => {
    return new Promise(async (resolve) => {
      e.preventDefault();

      // Validate all fields before submitting
      const errors = {};

      for (const key in formValues) {
        // Check if the form field is an array i.e. the field can be multiple
        if (Array.isArray(formValues[key]) && formValues[key]?.length) {
          // Mapping over all the fields in the array and validating each field individually
          formValues[key].forEach((field, index) => {
            // check for multiple forms
            if (typeof field === "object" && !Array.isArray(field)) {
              Object.keys(field).forEach((child_key) => {
                const errorMessage = validateField(
                  `${key}_${index}_${child_key}`,
                  formValues[key][index][child_key]
                );
                if (errorMessage) {
                  errors[`${key}_${index}_${child_key}`] = errorMessage;
                }
              });
            } else {
              const errorMessage = validateField(
                `${key}_${index}`,
                formValues[key][index]
              );
              if (errorMessage) {
                errors[`${key}_${index}`] = errorMessage;
              }
            }
          });
        } else {
          const errorMessage = validateField(key, formValues[key]);
          if (errorMessage) {
            errors[key] = errorMessage;
          }
        }
      }

      setFormErrors(errors);

      // If any errors are present then don't proceed
      if (Object.keys(errors).length) {
        errorRef.current = true;
        resolve(errors);
        return;
      }

      errorRef.current = false;

      await submitHandler(formValues);
      resolve();
    });
  };

  return {
    formErrors,
    formValues,
    handleInputBlur,
    handleInputChange,
    handleSubmit,
    hasError: errorRef.current,
    setFormErrors,
    setFormValues,
  };
};

export default useFormValidation;
