import React, { Component, Suspense, lazy } from "react";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  errorHandler,
  showToast,
} from "../../../helper-methods";
import { deleteCard, fetchAllCards } from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import ReKYCCard from "../components/re-kycalert";
import SvgIcons from "../components/SvgIcons";

const KYCAlertCard = lazy(() => import("../components/kyc-alert"));
const AddAccountModal = lazy(() => import("../components/add-account-modal"));

class AchSettings extends Component {
  state = {
    accounts: [],
    addAccountModalData: {
      isOpen: false,
      data: null,
    },
  };

  componentDidMount = async () => {
    this.props.showLoader();

    try {
      await this._getDefaultCard();
      this.props.hideLoader();
    } catch (error) {
      this.setState({ error });
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  //Get details of default added card
  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this.props.showLoader();
      try {
        let res = await fetchAllCards();
        let accounts =
          (res?.cards?.length &&
            res?.cards?.filter((item) => item.object === "bank_account")) ||
          [];
        this.setState({ accounts });
        this.props.hideLoader();
      } catch (error) {
        this.props.hideLoader();
        reject(error);
      }
    });
  };

  _toggleAddAccountModal = (isOpen = false, data = null) => {
    this.setState({
      addAccountModalData: {
        isOpen,
        data,
      },
    });
  };

  //For delete account
  _deleteAccount = (cardId) => {
    this.props.showLoader();

    deleteCard(cardId)
      .then(async (res) => {
        this._toggleAddAccountModal();
        await this._getDefaultCard();
        this.props.hideLoader();
        showToast("Account has been removed", "success");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  render() {
    const { addAccountModalData, accounts } = deepClone(this.state);
    const { kyc } = this.props?.userData?.user;

    return (
      <>
        <Card>
          {/* show this if iskycDone: false and isReKycNeeded:false or iskycDone: false and isReKycNeeded: true , apart from other conditions */}
          {kyc &&
            !kyc.paymentEnabled &&
            !kyc.payoutEnabled &&
            ((!kyc?.iskycDone && kyc?.isReKycNeeded) ||
              (!kyc?.iskycDone && !kyc?.isReKycNeeded)) ? (
            <Suspense fallback={<></>}>
              <KYCAlertCard />
            </Suspense>
          ) : null}
          {/* show this if iskycDone: true and isReKycNeeded: true and showKycBanner: false */}
          {kyc?.isReKycNeeded && kyc?.iskycDone && !kyc?.showKycBanner && (
            <ReKYCCard hideCancelButton={true} hideDoNotShowButton={true} />
          )}
          <div className="BankSettingCard">
            <div className="CardTitle">
              <h2 className="sectionTtile"> ACH Settings </h2>
            </div>
            <Row>
              {accounts?.length ? (
                accounts.map((item) => {
                  return (
                    <Col xl={4} key={item.id}>
                      <Card className="addedBankAccount">
                        <CardHeader>
                          <CardTitle>
                            {capitalize(item.bank_name.toLowerCase())}
                          </CardTitle>
                          {item.status !== "verified" ? (
                            <span
                              // color="success"
                              className="ml-auto text-warning"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this._toggleAddAccountModal(true, {
                                  type: "verify",
                                  item,
                                })
                              }
                            >
                              {/* <i className="fa fa-check" /> */}
                              Verify
                              <span
                                href="#"
                                id={"achSettingsUncomntrolledTooltip"}
                                className="ml-1"
                              >
                                <SvgIcons type="info" />
                              </span>
                              <UncontrolledTooltip
                                placement="right"
                                target={"achSettingsUncomntrolledTooltip"}
                              >
                                <p>
                                  Stripe will deposit two small amounts (less than 1$ each) into your bank account.
                                  Enter these in your Stripe Dashboard to verify your account.
                                </p>
                              </UncontrolledTooltip>
                            </span>
                          ) : (
                            <span
                              color="link"
                              className="ml-auto text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this._toggleAddAccountModal(true, {
                                  type: "delete",
                                  item,
                                })
                              }
                            >
                              Delete
                              {/* <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              /> */}
                            </span>
                          )}
                        </CardHeader>
                        <CardBody>
                          <p>{item.account_holder_name}</p>
                          <p>
                            <span>XXX</span>
                            <span>XXXX</span>
                            {item.last4}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col md={12}>
                  <h5 className="noAccount">No Accounts Added!</h5>
                </Col>
              )}
            </Row>
            <div className="text-center my-3">
              <Button
                color="primary"
                outline
                onClick={() =>
                  this._toggleAddAccountModal(true, { type: "add" })
                }
              >
                Add Account
              </Button>
            </div>
          </div>
        </Card>

        {/* For add new count */}
        <Suspense fallback={<></>}>
          <Elements>
            <AddAccountModal
              isOpen={addAccountModalData.isOpen}
              data={addAccountModalData.data}
              resetTable={() => this._getDefaultCard()}
              toggle={() => this._toggleAddAccountModal()}
              deleteAccount={(item) => this._deleteAccount(item.id)}
            />
          </Elements>
        </Suspense>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AchSettings);
