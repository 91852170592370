import { useCallback, useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Button,
} from "reactstrap";
// import { updateAgentWebsiteDetail } from "../../../../../../http/http-calls";
import { errorHandler, showToast } from "../../../../../../helper-methods";
import { RegexConfig } from "../../../../../../config/RegexConfig";

const ColorSelectorSection = ({ websiteData, setColorSetup, onInputHandler }) => {
  const [uISettings, setUISettings] = useState(null);
  const [isDefaultUiSelected, setIsDefaultUiSelected] = useState(true);
  const [loading, setLoading] = useState(false);

  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState({});

  const handleColorChange = (field, value, type = "") => {
    const newUiSettings = { ...uISettings };
    const newIsDirty = { ...isDirty };

    newUiSettings[field] = value;
    newIsDirty[field] = true;

    setIsDefaultUiSelected(false);
    if (type === "color-picker") {
      document.activeElement?.blur(); // Close color picker after selection
    }

    setUISettings(newUiSettings);
    _validateFields({ newUiSettings, newIsDirty });
  };

  const _markAllIsDirty = () => {
    return new Promise(resolve => {
      const newIsDirty = {};

      Object.keys(isDirty)?.forEach(key => {
        newIsDirty[key] = true
      })

      resolve(newIsDirty);
    })
  }

  const updateWebsiteData = useCallback(async () => {
    setLoading(true);
    try {
      const newUiSettings = { ...uISettings };
      const newIsDirty = await _markAllIsDirty();

      const isFormValid = await _validateFields({ newUiSettings, newIsDirty });

      if (!isFormValid) {
        return
      }

      // // check if entered color codes are valid codes or not
      // if (
      //   !RegexConfig?.hexCode?.test(uISettings?.primary) ||
      //   !RegexConfig?.hexCode?.test(uISettings?.secondary) ||
      //   !RegexConfig.hexCode.test(uISettings?.secondaryText)
      // ) {
      //   errorHandler({ reason: "Please enter valid color codes only" });
      //   setLoading(false);
      //   return;
      // }

      let payload = {};
      if (isDefaultUiSelected) {
        payload = {
          isDefaultUiSelected,
          defaultUiSetting: uISettings,
        };
      } else {
        payload = {
          isDefaultUiSelected,
          customizedUiSetting: uISettings,
        };
      }

      console.log("payload >>", payload);

      onInputHandler({ data: payload, type: "overwrite", });
      // const res = await updateAgentWebsiteDetail(payload);
      // if (!res?.error) {
      setLoading(false);
      setColorSetup(false);
      showToast("UI Settings Updated", "success");
      // 

      // if success, get the upadted data and set the state with updated colors using useTheme hook in PersonalWebsite component
      // getTheAgentWebsiteData()
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      console.error("Error updating website data:", error);
    } finally {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uISettings, isDefaultUiSelected]);

  const _validateFields = ({ newUiSettings, newIsDirty }) => {
    return new Promise(resolve => {
      const newErrors = {};
      let isFormValid = true;

      Object.keys(newUiSettings)?.forEach(key => {
        if (newIsDirty[key]) {
          if (!newUiSettings?.[key]?.length) {
            newErrors[key] = "*Required";
            isFormValid = false
          } else if (!RegexConfig?.hexCode?.test(newUiSettings?.[key])) {
            newErrors[key] = "*Please enter valid color codes only";
            isFormValid = false;
          } else {
            newErrors[key] = "";
            newIsDirty[key] = false
          }
        }
      })

      setIsDirty(newIsDirty);
      setErrors(prev => ({ ...prev, ...newErrors }));

      resolve(isFormValid);
    })
  }

  useEffect(() => {
    if (websiteData?.isDefaultUiSelected) {
      setUISettings(websiteData?.defaultUiSetting);
      setIsDefaultUiSelected(true);
    } else {
      setUISettings(websiteData?.customizedUiSetting);
      setIsDefaultUiSelected(false);
    }
  }, [websiteData]);

  return (
    <div>
      <div className="themeColor">
        <h1>Select UI Color</h1>

        <FormGroup className="floatingLabel withInputGroup withColorInput">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Input
                  type="color"
                  value={uISettings?.primary}
                  onChange={(e) => handleColorChange("primary", e.target.value, "color-picker")}
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder=" " value={uISettings?.primary} onChange={(e) => handleColorChange("primary", e.target.value)} />
            <Label>Primary Color</Label>
          </InputGroup>
          {errors["primary"] ? (
            <div className="validation-error">
              {errors["primary"]}
            </div>
          ) : null}
          <small className="text-muted">
            Button and anchor text use the primary colour.
          </small>
        </FormGroup>

        <FormGroup className="floatingLabel withInputGroup withColorInput">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Input
                  type="color"
                  value={uISettings?.secondary}
                  onChange={(e) =>
                    handleColorChange("secondary", e.target.value, "color-picker")
                  }
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder=" " value={uISettings?.secondary} onChange={(e) =>
              handleColorChange("secondary", e.target.value)
            } />
            <Label>Secondary Color</Label>
          </InputGroup>
          {errors["secondary"] ? (
            <div className="validation-error">
              {errors["secondary"]}
            </div>
          ) : null}
        </FormGroup>

        <FormGroup className="floatingLabel withInputGroup withColorInput">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <Input
                  type="color"
                  value={uISettings?.secondaryText}
                  onChange={(e) =>
                    handleColorChange("secondaryText", e.target.value, "color-picker")
                  }
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input placeholder=" " value={uISettings?.secondaryText} onChange={(e) =>
              handleColorChange("secondaryText", e.target.value)
            } />
            <Label>Secondary Text Color</Label>
          </InputGroup>
          {errors["secondaryText"] ? (
            <div className="validation-error">
              {errors["secondaryText"]}
            </div>
          ) : null}
          <small className="text-muted">
            Text uses the secondary text colour.
          </small>
        </FormGroup>

        <div className="text-center mt-4">
          <Button
            color="primary"
            size="lg"
            onClick={updateWebsiteData}
            disabled={loading}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ColorSelectorSection;
