import React, { useState, useEffect, useCallback, useMemo, memo } from "react";
import { debounce } from "../../../helper-methods";
import { FormGroup, Input, ListGroup, ListGroupItem } from "reactstrap";

const SearchableDropdown = ({
  fetchData,
  onSelectChange,
  options,
  selectedData,
  isNeedModifiedOptions = true,
}) => {
  const [selectedItem, setSelectedItem] = useState("");
  const [isOptionsShow, setIsOptionsShow] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const fetchDataAsync = async (search = "") => {
    await fetchData(search);
  };

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce(async (search) => {
      fetchDataAsync(search);
      if (!search?.length) {
        // Need this for API call when user clear search
        onSelectChange("");
      }
    }, 1000),
    []
  );

  const handleInputFocus = () => {
    setIsOptionsShow(true); // open the dropdown
  };

  const handleInputChange = (event) => {
    const search = event.target.value;
    setSelectedItem(search); // for search input
    debouncedSearch(search); // for debounce call with search input

    //if not have any data just simply do it blank no need to call dbounce search
    if (!search.length) {
      onSelectChange(""); // Reset in parent when input is cleared
    }
    setIsOptionsShow(true); // open the dropdown
    setIsSearching(true);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        event.target.closest("#searchable-dropdown") === null &&
        isOptionsShow
      ) {
        setIsOptionsShow(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line
  }, [isOptionsShow]);

  useEffect(() => {
    fetchDataAsync();
    // eslint-disable-next-line
  }, []);

  // need to reset the selected item
  useEffect(() => {
    // console.log("selectedData", selectedData);

    if (!selectedData?.length && options?.length) {
      // console.log("reseting selected item>>", selectedData?.length);
      if (isSearching) {
        setIsSearching(false);
      } else {
        setSelectedItem("");
      }
    } else {
      // this for hold the selected item
      // mainly for persist the selected item
      let selectedItem = options?.find(
        (item) => item.value === selectedData
      )?.label;
      setSelectedItem(selectedItem);
    }
    // eslint-disable-next-line
  }, [selectedData, options]);

  const modifiedOptions = useMemo(() => {
    if (!isNeedModifiedOptions) {
      return options;
    }
    return [{ value: "", label: "All" }, ...options];
  }, [options]);

  // console.log("typeof selectedItem >>", typeof selectedItem);
  // console.log("selectedItem >>", selectedItem);

  return (
    <>
      <FormGroup className="position-relative" id="searchable-dropdown">
        <Input
          type="text"
          placeholder="Search"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          value={selectedItem}
        />
      </FormGroup>
      {isOptionsShow && modifiedOptions.length > 0 ? (
        <ListGroup className="instituteList">
          {modifiedOptions.map((item, i) => (
            <ListGroupItem
              key={i}
              value={item.value}
              onClick={() => {
                onSelectChange(item.value); // this is for API call on select change
                setSelectedItem(item?.label?.trim()); // for select in input field
                setIsOptionsShow(false); // close the dropdown
              }}
            >
              {item.label}
            </ListGroupItem>
          ))}
        </ListGroup>
      ) : null}
    </>
  );
};

export default memo(SearchableDropdown);
