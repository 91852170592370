import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  CustomInput,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  errorHandler,
  formatDate,
  isPastDate,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  createCompanyExpense,
  updateExpenseSigningCompany,
} from "../../../http/http-calls";
import {
  // itemListOptionsConfig,
  recurringFrequencyConfig,
} from "../../../config";
import CreatableSelect from "react-select/creatable";

class AddExpenseAccountingCompany extends Component {
  state = {
    formFields: {
      date: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      item: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      amount: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      receipt: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
          uploadedUrl: null,
          docType: null,
          uploadedReceiptName: null,
        },
        error: null,
        isDirty: false,
        isValidate: false,
      },
      notes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      recurringFrequency: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      isShowRecurringFrequency: {
        value: false,
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    const defaultValue = {
      value: "",
      error: null,
      isDirty: false,
      isValidate: true,
    };
    this.setState({
      formFields: {
        date: {
          ...defaultValue,
        },
        item: {
          ...defaultValue,
        },
        amount: {
          ...defaultValue,
        },
        notes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        receipt: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
            uploadedUrl: null,
            docType: null,
            uploadedReceiptName: null,
          },
          error: null,
          isDirty: false,
          isValidate: false,
        },
        recurringFrequency: {
          ...defaultValue,
        },
        isShowRecurringFrequency: {
          value: false,
          error: null,
          isDirty: false,
          isValidate: false,
        },
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen && data) {
      this._setFormFields(data);
    }
  };

  _setFormFields = (data) => {
    const { formFields } = this.state;

    formFields.date.value = data?.date;
    formFields.amount.value = data?.amount;
    formFields.item.value = data?.item;
    formFields.notes.value = data?.notes;
    formFields.isShowRecurringFrequency.value = data?.recurring;
    formFields.recurringFrequency.value = data?.period;

    if (data?.receipt) {
      formFields.receipt.file.uploadedUrl = data?.receipt;
      formFields.receipt.file.uploadedReceiptName = data?.receiptName;
      formFields.receipt.file.docType = data?.docType;
    }

    this.setState({ formFields }, () => console.log(this.state.formFields));
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "item":
            case "date": {
              if (formFields[key].value?.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "recurringFrequency": {
              if (formFields.isShowRecurringFrequency.value) {
                if (String(formFields[key].value)?.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "amount": {
              if (String(formFields[key].value)?.length) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Rate must be a positive number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    console.log("fff", fieldName, value);
    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "date") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
      formFields["recurringFrequency"].value = "";
      formFields["isShowRecurringFrequency"].value = false;
    } else formFields[fieldName].value = value;

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _deleteFile = () => {
    const { formFields } = deepClone(this.state);
    formFields["receipt"] = {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
        uploadedUrl: null,
        docType: null,
        uploadedReceiptName: null,
      },
      error: null,
      isValidate: false,
    };
    this.setState({ formFields });
  };

  _addExpense = async (e) => {
    if (e) e.preventDefault();

    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.setState({ loading: true });

      const { formFields } = this.state;

      const payload = {
        date: formFields.date.value,
        amount: String(formFields?.amount?.value)?.trim(),
        item: formFields.item.value,
        notes: formFields.notes.value ? formFields.notes.value.trim() : "",
      };

      if (
        formFields.isShowRecurringFrequency.value &&
        formFields.recurringFrequency.value
      )
        payload["period"] = Number(formFields.recurringFrequency.value);

      if (
        formFields.receipt &&
        formFields.receipt.file &&
        formFields.receipt.file.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          formFields.receipt.file,
        ]);
        payload["receipt"] = uploadedFilesRes[0].url;
        payload["docType"] = uploadedFilesRes[0].docType;
        payload["receiptName"] = uploadedFilesRes[0].title;
      } else if (formFields?.receipt?.file?.uploadedUrl) {
        payload["receipt"] = formFields?.receipt?.file?.uploadedUrl;
        payload["docType"] = formFields?.receipt?.file?.docType;
        payload["receiptName"] = formFields?.receipt?.file?.uploadedReceiptName;
      } else {
        payload["receipt"] = "";
        payload["docType"] = "";
        payload["receiptName"] = "";
      }

      if (this.props.data?._id) {
        this._editCompanyExpense(this.props.data?._id, payload);
      } else {
        this._createCompanyExpense(payload);
      }
    }
  };

  _editCompanyExpense = async (id, payload) => {
    try {
      await updateExpenseSigningCompany(id, payload);

      showToast("Expense Updated", "success");
      this.props.resetTable();
      this._closeModal();
    } catch (err) {
      this.setState({ loading: false });
      errorHandler(err);
    }
  };

  _createCompanyExpense = (payload) => {
    createCompanyExpense(payload)
      .then((res) => {
        showToast("Expense Added Successfully", "success");
        this.props.resetTable();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _updateFile = async (event) => {
    const { formFields } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      const objFile = event.target.files[0];
      const objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        formFields.receipt.file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };

        this.setState({ formFields });
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  render() {
    const { formFields, loading } = deepClone(this.state);
    const { expenseType, data } = deepClone(this.props);
    console.log("sss", this.state);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        centered
        scrollbar
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {data?._id ? "Edit" : "Add"} Expense
        </ModalHeader>
        <ModalBody>
          <FormGroup
            className={`floatingLabel ${
              formFields.date.value || this.state.isOpenReactDatetime
                ? "valueAdded"
                : ""
            }`}
          >
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: " ",
                value: formatDate(formFields.date.value),
                disabled: data?._id,
              }}
              onChange={(e) => this._onChangeFormField("date", e._d)}
              closeOnSelect={true}
              timeFormat={false}
              onOpen={() => this.setState({ isOpenReactDatetime: true })}
              onClose={() => this.setState({ isOpenReactDatetime: false })}
            />
            <Label>Date</Label>
            {formFields.date.error && (
              <div className="validation-error">{formFields.date.error}</div>
            )}
          </FormGroup>
          <FormGroup>
            <Label>Item</Label>
            <CreatableSelect
              value={{
                label: formFields.item.value,
                value: formFields.item.value,
              }}
              onChange={(event) => this._onChangeFormField("item", event.value)}
              options={expenseType.filter(
                (item) => item.value !== "Company Expense"
              )}
              className="customSelect"
              isDisabled={data?.recurring === true || data?.recurring === false}
            />
            {formFields.item.error && (
              <div className="validation-error">{formFields.item.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/dollar.svg").default}
                    alt="dollar"
                    height={14}
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder=" "
                value={formFields.amount.value}
                name="amount"
                onChange={(event) =>
                  this._onChangeFormField("amount", event.target.value)
                }
                disabled={data?.recurring === true || data?.recurring === false}
              />
              <Label>Amount</Label>
            </InputGroup>
            {formFields.amount.error && (
              <div className="validation-error">{formFields.amount.error}</div>
            )}
          </FormGroup>
          <Label className="uploadDocument" for="uploadFile_add_expense_modal">
            <Input
              type="file"
              id="uploadFile_add_expense_modal"
              style={{ display: "none" }}
              accept="image/x-png,image/gif,image/jpeg,.pdf"
              value=""
              disabled={loading}
              onChange={(event) => this._updateFile(event)}
            />
            <img
              src={require("../../../assets/img/uploadIcon.png")}
              alt="file upload"
            />
            <div className="uploadLabel">
              <h4>Upload Receipt</h4>
              <p>File size must be less than 5mb</p>
            </div>
          </Label>
          {formFields.receipt?.file?.previewBlob ? (
            <>
              <div className="uploadPreview">
                <img
                  className="uploadedImg"
                  src={
                    formFields.receipt.file.type === "image"
                      ? formFields.receipt.file.previewBlob
                      : require("../../../assets/img/file_icon.png")
                  }
                  alt="upload"
                  options={expenseType.filter(
                    (item) => item.value !== "Company Expense"
                  )}
                  maxMenuHeight={300}
                />
                <span>
                  {formFields.receipt.file.uploadData &&
                  formFields.receipt.file.uploadData.name
                    ? formFields.receipt.file.uploadData.name
                    : null}
                </span>

                <Button
                  color="danger"
                  onClick={this._deleteFile}
                  className="deletePreview"
                >
                  <img
                    src={require("../../../assets/img/close.png")}
                    alt="deletePreview"
                  />
                </Button>
              </div>
            </>
          ) : formFields?.receipt?.file?.uploadedUrl ? (
            <>
              <div className="uploadPreview">
                <img
                  className="uploadedImg"
                  src={
                    formFields?.receipt?.file?.docType === "image"
                      ? formFields?.receipt?.file?.uploadedUrl
                      : require("../../../assets/img/file_icon.png")
                  }
                  alt="upload"
                  options={expenseType.filter(
                    (item) => item.value !== "Company Expense"
                  )}
                  maxMenuHeight={300}
                />
                <span>
                  {formFields?.receipt?.file?.uploadedReceiptName
                    ? formFields?.receipt?.file?.uploadedReceiptName
                    : null}
                </span>

                <Button
                  color="danger"
                  onClick={this._deleteFile}
                  className="deletePreview"
                >
                  <img
                    src={require("../../../assets/img/close.png")}
                    alt="deletePreview"
                  />
                </Button>
              </div>
            </>
          ) : null}
          <CustomInput
            type="checkbox"
            className="mb-3 mt-3"
            id={`isShowRecurringFrequency__addExpenceCompany`}
            label="Recurring Expense"
            checked={formFields.isShowRecurringFrequency.value}
            onChange={(event) =>
              this._onChangeFormField(
                "isShowRecurringFrequency",
                event.target.checked
              )
            }
            disabled={data?._id || isPastDate(formFields?.date?.value)}
          />

          {formFields.isShowRecurringFrequency.value ? (
            <FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  placeholder=" "
                  value={formFields.recurringFrequency.value}
                  onChange={(event) =>
                    this._onChangeFormField(
                      "recurringFrequency",
                      event.target.value
                    )
                  }
                  disabled={data?._id}
                >
                  <option value="">Select</option>
                  {recurringFrequencyConfig.map((rf) => (
                    <option key={rf.value} value={rf.value}>
                      {rf.label}
                    </option>
                  ))}
                </Input>
                <Label>Recurring Frequency</Label>
              </div>
              {formFields.recurringFrequency.error && (
                <div className="validation-error">
                  {formFields.recurringFrequency.error}
                </div>
              )}
            </FormGroup>
          ) : null}
          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="3"
              placeholder=" "
              value={formFields.notes.value}
              name="notes"
              onChange={(event) =>
                this._onChangeFormField("notes", event.target.value)
              }
            />
            <Label>Notes</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => this._closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={() => this._addExpense()}
          >
            {data?._id ? "Update" : "Add"}
          </Button>
        </ModalFooter>
        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default AddExpenseAccountingCompany;
