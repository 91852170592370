import React, { lazy, Suspense, useRef, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";
import Select from "react-select";
import CreateBlogModal from "../Modals/Agents/CreateBlog";
import Swal from "sweetalert2";
import { BLOG_CATEGORIES } from "../../../../config/blog-config";
import CustomTable from "../CustomTable";
import { blogsCardHeaderKeys, blogsHeaderKeys } from "../../../../config/stateConfig";
import useScreenWidth from "../HelpModule/useScreenWidth";
import CustomCard from "../CustomCard";
import { capitalize } from "../../../../helper-methods";
import SvgIcons from "../SvgIcons";

const AgentWebsiteBlogsCardDataFormat = lazy(() =>
  import("../AgentWebsiteBlogsCardDataFormat")
);
const AgentWebsiteBlogsTableDataFormat = lazy(() =>
  import("../AgentWebsiteBlogsTableDataFormat")
);

const BlogsApp = ({
  handleViewBlogDetailsPage,
  blogs,
  blogsTotalCount,
  tags,
  loading,
  filters,
  setFilters,
  tableConfig,
  fetchBlogs,
  deleteBlog,
  deleteSelectedBlogs,
  fetchBlogFilterData,
  onPageChange,
  publishBlog,
  createBlog,
  updateBlog,
}) => {
  const searchRef = useRef("");
  const { isForMobile } = useScreenWidth();

  const [addEditBlogModal, setAddEditBlogModal] = useState({
    isOpen: false,
    data: null,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  console.log({ blogs });
  const _toggleBlogModal = (isOpen = false, data = null) => {
    setAddEditBlogModal({ isOpen, data });
  };

  // const _handleAddOrEditBlog = () => {
  //   fetchBlogs();
  //   fetchBlogFilterData();
  //   // resetPage();
  // };

  const _handleFilterChange = (key, value) => {
    // console.log({ key, value });
    const newFilters = { ...filters };

    newFilters[key] = value;
    setFilters((prev) => ({ ...prev, ...newFilters }));

    if (key === "search") {
      if (searchRef?.current) clearTimeout(searchRef?.current);

      searchRef.current = setTimeout(() => {
        fetchBlogs({ ...newFilters, page: 1 });
      }, 1000);
    } else {
      fetchBlogs({ ...newFilters, page: 1 });
    }
  };

  const _deleteBlogAlert = (ids, multiple = false) => {
    if (!ids?.length) return;

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete ${multiple ? "all selected" : "this"} ${multiple ? "blogs" : "blog"
        } .`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        if (multiple) {
          deleteSelectedBlogs(ids);
        } else {
          deleteBlog(ids?.[0]);
        }
      }
    });
  };

  const _publishBlogAlert = (id) => {
    if (!id) return;

    Swal.fire({
      title: "Are you sure?",
      text: `You want to publish this blog.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
    }).then((result) => {
      if (result.isConfirmed) {
        publishBlog(id);
      }
    });
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "title": {
        return row ? (
          <>
            <div className="tableUserInfo" >
              <div className="userContent">
                <span className="signerName" onClick={() => handleViewBlogDetailsPage(row?._id)}>
                  {row?.title ? capitalize(row?.title) : "N/A"}
                </span>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // For table view
  const _dataFormat = (cell, row, header) => {
    return (
      <Suspense fallback={<></>}>
        <AgentWebsiteBlogsTableDataFormat
          header={header}
          cell={cell}
          row={row}
          loading={loading}
          handleViewBlogDetailsPage={handleViewBlogDetailsPage}
          deleteBlogAlert={_deleteBlogAlert}
          toggleBlogModal={_toggleBlogModal}
          publishBlogAlert={_publishBlogAlert}
        />
      </Suspense>
    );
  };

  //For mobile view
  const _cardDataFormat = (row) => {
    return (
      <Suspense fallback={<></>}>
        <AgentWebsiteBlogsCardDataFormat
          row={row}
          loading={loading}
          handleViewBlogDetailsPage={handleViewBlogDetailsPage}
          deleteBlogAlert={_deleteBlogAlert}
          toggleBlogModal={_toggleBlogModal}
          publishBlogAlert={_publishBlogAlert}
        />
      </Suspense>
    );
  };

  const _resetFilters = async () => {
    // setFilters(initialFilters);
    fetchBlogs();
  }


  return (
    <>
      <div className="text-right">
        <Button color="primary" size="sm" onClick={_toggleBlogModal}>
          New Blog
        </Button>
      </div>

      <div
        className={`filterContainer  responsiveFilter 
          `}
      >
        <div className="filterIcon">
          <img
            src={require("../../../../assets/img/filter_icon.svg").default}
            alt="filter icon"
            loading="lazy"
          />
          Filter by{" "}
          {loading?.fetchBlogs ? (
            <i className="fa fa-spinner fa-spin ml-2" />
          ) : null}
        </div>
        <div className="mobileTitle">
          <h2>Filter by</h2>
          <Button
            color="link"
            className="closeButton"
          // onClick={this.filterShowMobile}
          >
            <img
              src={require("../../../../assets/img/close_grey.svg").default}
              alt="close"
              height={12}
              loading="lazy"
            />
          </Button>
        </div>

        <div className="filterWrapper">
          <FormGroup className="searchTable">
            <Label>Search</Label>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={
                      require("../../../../assets/img/searchIcon.svg").default
                    }
                    alt="searchIcon"
                    loading="lazy"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                key={filters}
                type="text"
                placeholder="Search Blogs"
                value={filters?.search}
                onChange={(e) => _handleFilterChange("search", e.target.value)}
              />
            </InputGroup>
          </FormGroup>
          <FormGroup>
            <Label>Status</Label>
            <div className="custom-select-wrapper">
              <Input
                key={filters}
                type="select"
                name="status"
                value={filters?.status}
                onChange={(e) => _handleFilterChange("status", e.target.value)}
              >
                <option value="">All</option>
                <option value="published">Published</option>
                <option value="draft">Draft</option>
              </Input>
            </div>
          </FormGroup>
          <FormGroup style={{ width: "180px" }}>
            <Label>Category</Label>
            <div className="custom-select-wrapper">
              <Select
                key={filters}
                isMulti // Enables multiple selection
                options={BLOG_CATEGORIES}
                placeholder="All"
                name="category"
                value={filters?.categories}
                onChange={(options) =>
                  _handleFilterChange("categories", options)
                }
              />
            </div>
          </FormGroup>
        </div>

        <Button
          color="link"
          onClick={() => _resetFilters()}
          id="agent-blogs"
          className="resetBtn"
        >
          <SvgIcons type={"refresh"} />
        </Button>
        {/* <div className="clearButton">
          <Button
            size="md"
            color="primary"
          // onClick={this.filterShowMobile}
          >
            Close
          </Button>
        </div> */}
      </div>

      {/* <BlogTable
        blogs={blogs}
        loading={loading}
        handleViewBlogDetailsPage={handleViewBlogDetailsPage}
        deleteBlogAlert={_deleteBlogAlert}
        toggleBlogModal={_toggleBlogModal}
      /> */}

      {selectedRows?.length ? (
        <Button
          color="link"
          className="remove mb-2"
          onClick={() => _deleteBlogAlert(selectedRows, true)}
        >
          Delete All Selected{" "}
          {loading?.bulkDeleteLoading ? (
            <i className="fa fa-spinner fa-spin ml-2" />
          ) : null}
        </Button>
      ) : null}

      {isForMobile ? (
        <div className="hideDesktop">
          <Suspense fallback={<></>}>
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig?.pageNumber}
              tableData={blogs}
              headerKeys={blogsCardHeaderKeys}
              // dataFormat={_dataFormat}
              totalCount={blogsTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                onPageChange(pageNumber, pageSize)
              }
              rowSelection={true}
              selectedRows={selectedRows}
              setSelectedRows={(selectedRows) => setSelectedRows(selectedRows)}
              showTableLoading={loading?.fetchBlogs}
              cardHeaderFormat={_cardHeaderFormat}
              cardDataFormat={_cardDataFormat}
              isHideForLessData={true}
            />
          </Suspense>
        </div>
      ) : (
        <div className="blogTable">
          <CustomTable
            rowSelection={true}
            striped
            isPageStartFromOne={true}
            pageNumber={tableConfig?.pageNumber}
            pageSize={tableConfig?.limit}
            tableData={blogs}
            headerKeys={blogsHeaderKeys}
            dataFormat={_dataFormat}
            totalCount={blogsTotalCount}
            selectedRows={selectedRows}
            setSelectedRows={(selectedRows) => setSelectedRows(selectedRows)}
            onPaginate={(pageNumber, pageSize) =>
              onPageChange(pageNumber, pageSize)
            }
            showTableLoading={loading?.fetchBlogs}
            isHideForLessData={true}
          />
        </div>
      )}

      {addEditBlogModal?.isOpen ? (
        <CreateBlogModal
          data={addEditBlogModal?.data}
          isOpen={addEditBlogModal?.isOpen}
          tags={tags}
          toggle={_toggleBlogModal}
          // onAddOrEditBlog={_handleAddOrEditBlog}
          onClose={() => setAddEditBlogModal({ isOpen: false, data: null })}
          onLoad={fetchBlogFilterData}
          createBlog={createBlog}
          updateBlog={updateBlog}
        />
      ) : null}
    </>
  );
};

export default BlogsApp;
