import React, { useState, useRef } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import StripeElement from "../../private/components/stripeElement";
import { Elements } from "react-stripe-elements";
import { errorHandler } from "../../../helper-methods";
import {
  addAgentCreditCard,
  addCompanyCreditCard,
} from "../../../http/http-calls";

const AddCreditCard = ({ isOpen, toggle, userData, getAllCreditCards }) => {
  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(null);
  const addCardHandlerRef = useRef(null); // Use useRef to persist across renders


  const toggleLoader = (state) => setLoading(state);

  // Handle Stripe errors (for example, if there's an issue with the card)
  const handleStripeError = (err) => {
    // setError(err.message);
    toggleLoader(false);
  };

  const handleCardAdded = async (payload) => {
    // console.log("Card token received:", payload.token);
    const userType = userData?.user?.type;

    if (payload?.token?.id) {
      try {
        if (userType === "Agent") {
          await addAgentCreditCard({ token: payload?.token?.id });
        } else {
          await addCompanyCreditCard({ token: payload?.token?.id });
        }
      } catch (error) {
        errorHandler(error);
      }
    } else {
      errorHandler("Something went wrong, Try again later.");
    }
    getAllCreditCards();
    toggleLoader(false);
    toggle();
  };

  const processPayment = () => {
    if (addCardHandlerRef.current) {
      toggleLoader(true);
      addCardHandlerRef.current(); // Trigger the Stripe card token creation
    }
  };

  // const _processPayment = async () => {
  //   const { preferredType, selectedCard } = this.state;
  //   if (preferredType === "new") {
  //     // Try to add card
  //     //   _addCard();
  //   } else {
  //     // preferredType === 'existing'
  //     //   if (selectedCard) {
  //     //     try {
  //     //       this.setState({ isLoaderActive: true });
  //     //       await this._makeCardAsDefault();
  //     //     } catch (error) {
  //     //       this.setState({ isLoaderActive: false });
  //     //     }
  //     //   }
  //     //   this.props.onPaymentConfirmation();
  //   }
  // };
  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Add Card</ModalHeader>
        <ModalBody>
          <Elements>
            <StripeElement
              showLoader={() => toggleLoader(true)}
              hideLoader={() => toggleLoader(false)}
              onCardAdded={handleCardAdded}
              onStripeError={handleStripeError}
              addCard={(addCardFunc) => {
                addCardHandlerRef.current = addCardFunc; // Store function in ref to persist page rerenders
              }}
            />
          </Elements>
          {/* {error && <p className="text-danger mt-2">{error}</p>} */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle} outline>
            Cancel
          </Button>
          <Button color="primary" disabled={loading} onClick={processPayment}>
            {loading ? "Processing..." : "Add"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddCreditCard;
