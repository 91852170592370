import React, { Component } from "react";
import { Card, CardBody, Col, Row, Button } from "reactstrap";
import {
  deepClone,
  openUrlOnNewTab,
  errorHandler,
} from "../../../helper-methods";
import { getAgentDetailForAboutAgentPage } from "../../../http/http-calls";
import { APP_URL, DEFAULT_PROFILE_PICTURE } from "../../../config";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import FeatureUnavailableModal from "../components/featureUnavailableModal";
import FeatureNotSupportedModal from "../components/featureNotSupportedModal";
import SvgIcons from "../components/SvgIcons";

class AgentAbout extends Component {
  state = {
    agentDetails: {},
    agentWebsite: {},
    availability: {},
    featureUnavailableModal: {
      isOpen: false,
      data: null,
    },
    featureNotSupportedModal: {
      isOpen: false,
      data: null,
    },
  };
  componentDidMount = async () => {
    console.log("href", window.location);
    const agentId = window.location.href.split("/")[4];
    this._getAgentDetailForAboutAgentPage(agentId);
  };

  _getAgentDetailForAboutAgentPage = async (agentId) => {
    try {
      let response = await getAgentDetailForAboutAgentPage(agentId);
      this.setState({
        agentDetails: response?.agent || {},
        agentWebsite: response.agentWebsite || {},
        availability: response?.agent?.availability || {},
      });
    } catch (error) {
      errorHandler(error);
    }
  };

  _isCredentialUploaded = (credentialName) => {
    const { agentDetails } = deepClone(this.state);
    if (
      credentialName === "notaryLicense" ||
      credentialName === "barLicense" ||
      credentialName === "ronCredentials"
    ) {
      return (
        agentDetails &&
        agentDetails[credentialName]?.length &&
        agentDetails[credentialName].map((item) => item.url)
      );
    } else {
      return (
        agentDetails &&
        agentDetails[credentialName] &&
        agentDetails[credentialName].url
      );
    }
  };

  _onToggleFeatureUnavailableModal = () => {
    let { featureUnavailableModal } = JSON.parse(JSON.stringify(this.state));
    featureUnavailableModal.isOpen = !featureUnavailableModal.isOpen;
    this.setState({
      featureUnavailableModal,
    });
  };
  _onToggleFeatureNotSupportedModal = () => {
    let { featureNotSupportedModal } = JSON.parse(JSON.stringify(this.state));
    featureNotSupportedModal.isOpen = !featureNotSupportedModal.isOpen;
    this.setState({
      featureNotSupportedModal,
    });
  };

  _handleSaveContact = () => {
    const vCardData = this.generateVCardData();
    const blob = new Blob([vCardData], { type: "text/vcard" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "contact.vcf";
    a.click();
    URL.revokeObjectURL(url);
  };

  generateVCardData = () => {
    const { agentDetails } = deepClone(this.state);
    const vCardData = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `FN:${agentDetails.name.full}`,
      `N:${agentDetails.name.full};;;`,
      `EMAIL:${agentDetails?.email}`,
      `TEL:${agentDetails?.phone}`,
      "END:VCARD",
    ].join("\n");

    return vCardData;
  };

  render() {
    const {
      agentDetails,
      agentWebsite,
      featureUnavailableModal,
      featureNotSupportedModal,
    } = deepClone(this.state);
    return (
      <>
        <div className="content">
          <div className="aboutWrapper">
            <Row>
              <Col xl={12}>
                <div className="agentCoverBg">
                  <img
                    src={
                      agentDetails?.coverPhoto ||
                      require("../../../assets/img/bg1.jpg").default
                    }
                    alt="cover"
                  />
                </div>
                <div className="agentInnerWrap">
                  <div className="agentAbout">
                    <div className="agentImage">
                      <img
                        src={
                          agentDetails.profilePicture || DEFAULT_PROFILE_PICTURE
                        }
                        alt="avatar"
                      />
                    </div>
                    <div className="infoAgent">
                      <div className="agentContent">
                        <h1>{agentDetails?.name?.full}</h1>
                        <h3>
                          {agentDetails?.businessName ? (
                            <span>{agentDetails.businessName}</span>
                          ) : (
                            ""
                          )}
                        </h3>
                        <p>
                          {agentDetails?.address?.city},{" "}
                          {agentDetails?.address?.state}
                        </p>
                        <p>{agentDetails?.bio}</p>
                      </div>
                      <div className="barContainer">
                        <div className="aboutBarCode">
                          <QRCode
                            id="qr-gen"
                            value={`${APP_URL}/agent-about/${agentDetails?.id}`}
                            size={180}
                            level={"H"}
                            includeMargin={true}
                          />
                          <p>Scan my code and save my information!</p>
                        </div>
                      </div>
                    </div>
                    <Button
                      color="primary"
                      size="md"
                      // onClick={() => this._onToggleFeatureNotSupportedModal()}
                      onClick={() => this._handleSaveContact()}
                    >
                      Save Contact
                    </Button>
                  </div>
                  <Card className="bookAppointment">
                    <CardBody>
                      <ul>
                        <li>
                          <Link
                            to="#"
                            // to={agentDetails?.websiteUrl}
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default behavior of the link
                              if (agentDetails?.subscriptionPlan === "Pro-Plus" && agentWebsite?.domainName?.trim()?.length > 0) {
                                // Opens the URL in a new tab
                                window.open(
                                  `https://${agentWebsite?.domainName}`,
                                  "_blank"
                                );
                              } else if (agentDetails?.subscriptionPlan === "Premium" && agentDetails?.freeBookingProfileUrl) {
                                // Opens the URL in a new tab
                                window.open(
                                  `${agentDetails?.freeBookingProfileUrl}`,
                                  "_blank"
                                );
                              } else {
                                // Handle the case where the feature is unavailable
                                this._onToggleFeatureUnavailableModal();
                              }
                            }}
                          >
                            <div className="agentIcon">
                              <SvgIcons type={"link"} />
                            </div>
                            <div>Book A Signing Appointment</div>
                          </Link>
                          {/* {!agentDetails?.websiteUrl && (
                            <Button
                              onClick={() =>
                                this._onToggleFeatureUnavailableModal()
                              }
                              className="fs-12"
                              color="link"
                            >
                              Book Now
                            </Button>
                          )} */}
                        </li>
                        <li>
                          <Link
                            to="#"
                            // to={agentDetails?.googleReviewLink}

                            // onClick={() =>
                            //   agentDetails?.googleReviewLink?.trim().length > 0
                            //     ? openUrlOnNewTab(
                            //         agentDetails?.googleReviewLink
                            //       )
                            //     : this._onToggleFeatureUnavailableModal()
                            // }
                            onClick={(e) => {
                              e.preventDefault(); // Prevents the default behavior of the link
                              if (agentDetails?.googleReviewLink) {
                                // Opens the URL in a new tab
                                window.open(
                                  agentDetails?.googleReviewLink,
                                  "_blank"
                                );
                              } else {
                                // Handle the case where the feature is unavailable
                                this._onToggleFeatureUnavailableModal();
                              }
                            }}
                          >
                            <div className="agentIcon">
                              <SvgIcons type={"link"} />
                            </div>
                            <div>Leave a review!</div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            onClick={(e) => {
                              window.location.href = `tel:${agentDetails?.phone}`;
                              e.preventDefault();
                            }}
                          >
                            <div className="agentIcon">
                              <SvgIcons type={"phone"} />
                            </div>
                            <div>
                              {agentDetails?.phone}
                              <span>Work</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="#"
                            onClick={(e) => {
                              window.location.href = `mailto:${agentDetails?.email}`;
                              e.preventDefault();
                            }}
                          >
                            <div className="agentIcon">
                              <SvgIcons type={"email_enevlope"} />
                            </div>
                            <div>
                              {agentDetails?.email}
                              <span>Work</span>
                            </div>
                          </Link>
                        </li>
                        <li>
                          <div>
                            <Link
                              to="#"
                              onClick={() =>
                                agentDetails?.websiteUrl?.trim().length > 0 &&
                                openUrlOnNewTab(agentDetails?.websiteUrl)
                              }
                            >
                              <div className="agentIcon">
                                <SvgIcons type={"globe"} />
                              </div>
                              Visit our website
                              {/* {agentDetails?.websiteUrl} */}
                            </Link>
                          </div>
                        </li>

                        {agentDetails?.linkedInUrl ? (
                          <li>
                            <Link
                              to="#"
                              onClick={() =>
                                agentDetails?.linkedInUrl?.trim().length > 0 &&
                                openUrlOnNewTab(agentDetails?.linkedInUrl)
                              }
                            >
                              <div className="agentIcon">
                                <SvgIcons type={"linkdin"} />
                              </div>
                              Connect With me on Linkdin
                              {/* {agentDetails?.linkedInUrl} */}
                            </Link>
                          </li>
                        ) : (
                          ""
                        )}

                        {agentDetails?.facebookReviewLink ? (
                          <li>
                            <a
                              href={
                                agentDetails?.facebookReviewLink.includes(
                                  "https://" || "http://"
                                )
                                  ? agentDetails?.facebookReviewLink
                                  : `https://${agentDetails?.facebookReviewLink}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="agentIcon">
                                <SvgIcons type={"facebook"} />
                              </div>
                              Friend me on Facebook
                            </a>
                          </li>
                        ) : null}
                        {agentDetails?.instagramReviewLink ? (
                          <li>
                            <a
                              href={
                                agentDetails?.instagramReviewLink.includes(
                                  "https://" || "http://"
                                )
                                  ? agentDetails?.instagramReviewLink
                                  : `https://${agentDetails?.instagramReviewLink}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <div className="agentIcon">
                                <SvgIcons type={"instagram"} />
                              </div>
                              Follow me on Instagram
                            </a>
                          </li>
                        ) : null}
                      </ul>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>

          {featureUnavailableModal.isOpen && (
            <FeatureUnavailableModal
              isOpen={featureUnavailableModal.isOpen}
              toggle={() => this._onToggleFeatureUnavailableModal()}
            />
          )}
          {featureNotSupportedModal.isOpen && (
            <FeatureNotSupportedModal
              isOpen={featureNotSupportedModal.isOpen}
              toggle={() => this._onToggleFeatureNotSupportedModal()}
            />
          )}
        </div>
      </>
    );
  }
}
export default AgentAbout;
