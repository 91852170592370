import React, { useState, useRef, forwardRef } from "react";
import {
  errorHandler,
  uploadFileOnServer,
} from "../../../../../../helper-methods";
import ImageCropModal from "../../../../components/Agents/ImageCropModal";

const ImageUploader = forwardRef(
  (
    {
      path,
      onImageUpload,
      placeholderText,
      placeholderImage,
      titleKey,
      title,
      type,
      index,
      arrayKey,
      loadingKey = undefined, // prop to show loading state based on a key, for only shoeing loader where it is needed
      noImage = false, // New prop to control image display
      manageLoading, // fn to manage loading state
      isDisabled = false, // diabled the image uploader while image is being uploaded anywhere from the app
      previewUrl = undefined, // previewUrl state coming from parent, to show the uploaded image / Blob
      handlePreviewUrl = () => {}, // fn to set the previewUrl
      previewUrlKey = "", // key to set the previewUrl
      allowImageCrop = false, // boolean to determine whether to allow image cropping or not
      className,
    },
    ref
  ) => {
    // console.log({
    //   titleKey,
    //   title,
    //   type,
    //   index,
    //   arrayKey,
    //   noImage,
    //   previewUrl,
    //   path,
    // });
    const [image, setImage] = useState(path || "");
    const [imageClass, setImageClass] = useState("rectangle");
    const imageRef = useRef(null);
    const fileInputRef = useRef(null);

    // image cropping state
    const [imageCropModal, setImageCropModal] = useState({
      isOpen: false,
      data: null,
      fieldName: "",
      fileType: "",
      fileName: "",
    });

    const _toggleImageCropModal = (
      isOpen = false,
      data = null,
      fieldName = "",
      fileType = "",
      fileName = ""
    ) => {
      console.log("open");
      setImageCropModal({ isOpen, data, fieldName, fileType, fileName });
    };

    const _setCroppedImg = async (objFile, fileType) => {
      try {
        manageLoading("imageUploadLoading", loadingKey || true);

        const imageUrl = URL.createObjectURL(objFile);

        handlePreviewUrl(previewUrlKey, imageUrl);
        setImage(imageUrl);

        const obj = {
          previewBlob: imageUrl,
          uploadData: objFile,
          type: fileType,
        };

        const uploadedFilesRes = await uploadFileOnServer([obj]);
        console.log("Uploaded to Server: ", uploadedFilesRes);

        await onImageUpload({
          title,
          data: uploadedFilesRes?.[0]?.url,
          type,
          key: titleKey,
          index,
          arrayKey,
        });
      } catch (err) {
        errorHandler(err);
      } finally {
        manageLoading("imageUploadLoading", null);
      }
    };

    // Forward the file input ref to the parent
    React.useImperativeHandle(
      ref,
      () => ({
        click: () => {
          if (fileInputRef.current) {
            // only trigger the click from the parent ref, if the image crop modal isn't open already
            if (!imageCropModal?.isOpen) {
              fileInputRef.current.click(); // Trigger the file input click
            }
          }
        },
      }),
      [imageCropModal]
    );

    const handleImageChange = async (e) => {
      try {
        manageLoading("imageUploadLoading", loadingKey || true);
        const file = e.target.files[0];
        let objFileType = file?.type?.split("/")?.[0];

        if (file) {
          if (objFileType === "image") {
            if (file?.size / 1024 > 5 * 1024) {
              errorHandler({
                reason: "Max allowed file size: 5MB",
              });
              return;
            }

            // const objFileType = file.type.split("/")[0];
            if (allowImageCrop) {
              _toggleImageCropModal(
                true,
                URL.createObjectURL(file),
                "logo",
                objFileType,
                file?.name
              );
            } else {
              const imageUrl = URL.createObjectURL(file);
              handlePreviewUrl(previewUrlKey, imageUrl);
              setImage(imageUrl);

              const obj = {
                previewBlob: imageUrl,
                uploadData: file,
                type: objFileType,
              };

              const uploadedFilesRes = await uploadFileOnServer([obj]);

              console.log("Uploaded to Server: ", uploadedFilesRes);

              await onImageUpload({
                title,
                data: uploadedFilesRes?.[0]?.url,
                type,
                key: titleKey,
                index,
                arrayKey,
              });
            }
          } else {
            errorHandler({ reason: "Only Image file is allowed" });
          }

          // Reset file input value to prevent retriggering
          e.target.value = "";
        }
      } catch (err) {
        errorHandler(err);
      } finally {
        manageLoading("imageUploadLoading", null);
      }
    };

    const handleImageLoad = () => {
      if (imageRef.current) {
        setImageClass(getImageDimensionClass(imageRef.current));
      }
    };

    return (
      <div
        className={`image-uploader ${imageClass} ${className}`}
        // here if we are already in the imageCropModal, we don't want to accidentally open the image picker popup again -> issue: in imagecrop modal sometimes on crop/aspect ration change, the image picker opens again due to ref calling the input file change event
        onClick={() =>
          imageCropModal?.isOpen ? {} : fileInputRef.current?.click()
        }
      >
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={handleImageChange}
          disabled={isDisabled}
        />

        {!noImage ? (
          <img
            ref={imageRef}
            src={previewUrl || image || placeholderImage} // Show blob or uploaded image or placeholder
            alt="Upload"
            onLoad={handleImageLoad}
            className="uploaded-image"
          />
        ) : placeholderText ? (
          placeholderText
        ) : null}

        {imageCropModal?.isOpen ? (
          <ImageCropModal
            isOpen={imageCropModal?.isOpen}
            imgData={imageCropModal?.data}
            fileType={imageCropModal?.fileType}
            fileName={imageCropModal?.fileName}
            toggle={_toggleImageCropModal}
            onSuccess={(objFile, fileType) => _setCroppedImg(objFile, fileType)}
          />
        ) : null}
      </div>
    );
  }
);

export const getImageDimensionClass = (imageElement) => {
  try {
    const ratio = imageElement.naturalWidth / imageElement.naturalHeight;
    return ratio <= 1.5 ? "square" : "rectangle";
  } catch (error) {
    return "rectangle";
  }
};

export default ImageUploader;
