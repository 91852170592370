import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import {
  getAgentDetailById,
  signingCompanyMarkFavouriteAgentById,
  updateAgentBlacklist,
} from "../../../http/http-calls";
import {
  deepClone,
  showToast,
  openUrlOnNewTab,
  formatTime,
  formatAddressInSingleText,
  formatDate,
  errorHandler,
  checkPermission,
  truncateFilename,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import moment from "moment";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { credentialsData, DEFAULT_PROFILE_PICTURE } from "../../../config";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";
import ScreenWidthHOC from "./ScreenWidthHOC";
import SvgIcons from "../components/SvgIcons";


class AgentDetails extends React.Component {
  state = {
    agent: {},
  };

  //Go to agent page function
  _redirectToAgents = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/agents`);
  };

  //Agent details data get from this function
  _getAgentDetails = (id) => {
    this.props.showLoader("Loading...");
    getAgentDetailById(id)
      .then((res) => {
        this.props.hideLoader();
        this.setState({
          agent: res?.agent || {},
        });
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
        this._redirectToAgents();
      });

    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
  };

  componentDidMount = () => {
    try {
      const agentId = this.props.match.params.id;
      this._getAgentDetails(agentId);
    } catch (error) {
      errorHandler(error);
      this._redirectToAgents();
    }
  };

  //check if credential exist then show data
  _isCredentialsExist = () => {
    const { agent } = deepClone(this.state);

    for (let i = 0; i < credentialsData.length; i++) {
      const obj = credentialsData[i];
      const value = agent && agent[obj.value];

      if (
        obj.value === "notaryLicense" ||
        obj.value === "barLicense" ||
        obj.value === "ronCredentials"
      ) {
        if (value && value.length > 0) {
          // const urls = value.map((item) => item.url);
          const urls = value.some((item) => item.url);
          if (urls) {
            return true;
          }
        }
      } else if (value && value.url) {
        return true;
      }
    }

    return false;
  };

  //This function is for block unblock agent
  _blockUnblockAgent = async () => {
    const { agent } = deepClone(this.state);
    try {
      const agentId = this.props.match.params.id;
      const payload = {
        type: agent?.isBlocked === true ? "unblock" : "block",
      };
      await updateAgentBlacklist(agentId, payload);
      this._getAgentDetails(agentId);
      if (agent?.isBlocked) {
        showToast("Agent has been unblocked", "success");
      } else {
        showToast("Agent has been blacklisted", "success");
      }
    } catch (err) {
      console.log(err);
      showToast(err);
    }
  };

  _markAgentAsFavorite = async (id) => {
    const { agent } = deepClone(this.state);
    let boolean = !agent?.isFavourite;
    this.setState({
      agent: { ...agent, isFavourite: boolean },
    });
    try {
      await signingCompanyMarkFavouriteAgentById(id);
    } catch (error) {
      let boolean = agent?.isFavourite;
      this.setState({
        agent: { ...agent, isFavourite: boolean },
      });
      errorHandler(error);
    }
  };

  render() {
    const { agent } = deepClone(this.state);
    const { isForMobile } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={this._redirectToAgents}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Agent Details</h2>
            </div>
          </div>
          <Row>
            <Col xl={3} lg={4}>
              {this?.props?.loadingData?.isVisible ? (
                <SkeletonLoading
                  type="card"
                  count={1}
                  height={395}
                  width={330}
                />
              ) : (
                <Card className="aboutCompany agentDetails" Agent Details>
                  <CardBody>
                    <div className="companyImage ">
                      <img
                        src={
                          agent && agent.profilePicture
                            ? agent.profilePicture
                            : DEFAULT_PROFILE_PICTURE
                        }
                        alt="Profile Img"
                        className=""
                      />
                    </div>
                    <h5>{agent.name?.full}</h5>
                    <h5>{agent?.vendorId || "N/A"}</h5>
                    <ul>
                      <li>
                        <img
                          src={require("../../../assets/img/phone.svg").default}
                          alt="phone"
                        />
                        <span>{agent.phone}</span>
                      </li>
                      <li>
                        <img
                          src={
                            require("../../../assets/img/enevlope.svg").default
                          }
                          alt="email"
                        />
                        <span>
                          <a href={`mailto:${agent?.email}`}>{agent?.email}</a>
                        </span>
                      </li>
                      <li>
                        {agent ? (
                          !agent.isFavourite ? (
                            <Button
                              color="link"
                              className="actionBtn"
                              disabled={!checkPermission("agents", "canUpdate")}
                              onClick={() =>
                                this._markAgentAsFavorite(agent.id)
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/iconFav.svg")
                                    .default
                                }
                                alt="fav icon"
                              />
                            </Button>
                          ) : (
                            <Button
                              color="link"
                              className="actionBtn"
                              onClick={() =>
                                this._markAgentAsFavorite(agent.id)
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/iconFavFill.svg")
                                    .default
                                }
                                alt="fav icon"
                              />
                            </Button>
                          )
                        ) : (
                          "N/A"
                        )}

                        {/* <img
                          src={
                            require("../../../assets/img/ratingBlack.svg")
                              .default
                          }
                          alt="rating"
                        /> */}
                        <span>
                          {agent.totalRating
                            ? agent?.totalRating + "/" + 5
                            : "N/A"}
                        </span>
                      </li>
                      <li>
                        <img
                          src={
                            require("../../../assets/img/location.svg").default
                          }
                          alt="location"
                        />
                        <span>{formatAddressInSingleText(agent?.address)}</span>
                      </li>
                    </ul>

                    {/* once you click button it will change to unblock  */}
                    <div className="text-center">
                      <Button
                        color="primary"
                        size="md"
                        onClick={() => this._blockUnblockAgent()}
                      >
                        {agent?.isBlocked === true ? "Unblock" : "Blacklist"}
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              )}
            </Col>
            <Col xl={9} lg={8}>
              <Card>
                <CardHeader>
                  <CardTitle>Stats</CardTitle>
                </CardHeader>
                <CardBody>
                  {this?.props?.loadingData?.isVisible ? (
                    <SkeletonLoading
                      type="card"
                      count={1}
                      height={370}
                      width={isForMobile ? 300 : 1100}
                    />
                  ) : (
                    <Row>
                      <Col xl={4} lg={6} xs={6} className="mb-4">
                        <div className="CompanyStats">
                          <div>
                            <h6>Jobs Completed</h6>
                            <p>{agent?.closingCompleted || "N/A"}</p>
                          </div>
                          <div className="icon">
                            <img
                              src={require("../../../assets/img/JobCompleted.png")}
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl={4} lg={6} xs={6} className="mb-4">
                        <div className="CompanyStats">
                          <div>
                            <h6>Jobs Scheduled</h6>
                            <p>{agent?.closingScheduled || "N/A"}</p>
                          </div>
                          <div className="icon">
                            <img
                              src={require("../../../assets/img/JobsScheduled.png")}
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl={4} lg={12} xs={12} className="mb-4">
                        <div className="CompanyStats">
                          <div>
                            <h6>Schedule</h6>
                            <ul className="weekName">
                              {agent?.availability &&
                                Object.entries(agent.availability).map(
                                  ([key, val]) => (
                                    <li className={val ? "selected" : ""}>
                                      {key[0].toUpperCase()}
                                    </li>
                                  )
                                )}
                            </ul>

                            <div className="scheduleTime">
                              (
                              {agent?.workTiming?.from
                                ? formatTime(
                                  moment(agent.workTiming.from, "HH:mm")
                                )
                                : "N/A"}
                              {" - "}
                              {agent?.workTiming?.to
                                ? formatTime(
                                  moment(agent.workTiming.to, "HH:mm")
                                )
                                : "N/A"}
                              )
                            </div>
                          </div>
                          <div className="icon">
                            <img
                              src={
                                require("../../../assets/img/TotalIncome.svg")
                                  .default
                              }
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xl={12} lg={12} xs={12}>
                        <h6>Standard Fee</h6>
                        <div
                          className="CompanyStats"
                          style={{ height: "auto" }}
                        >
                          <ul className="standardFee">
                            {agent?.standardFee
                              ? Object.entries(agent?.standardFee).map(
                                ([key, value], index) => (
                                  <>
                                    <li>
                                      {key} <span>${value || "N/A"}</span>
                                    </li>
                                  </>
                                )
                              )
                              : "N/A"}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Credentials</CardTitle>
                </CardHeader>
                {/* <CardBody>
                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>{" "}
                      Notary Licience
                    </div>

                    {"notaryLicense" in agent &&
                      agent?.notaryLicense.map((list) => (
                        <ul key={list._id}>
                          <li>
                            <label>License Number</label>
                            <span>{list.licenseNumber}</span>
                          </li>

                          <li>
                            <label>State</label>
                            <span>{list.state}</span>
                          </li>
                          <li>
                            <label>Expiration Date</label>
                            <span>{formatDate(list?.expiredOn)}</span>
                          </li>
                          <li>
                            <label>Attachment</label>
                            <Button
                              color="link"
                              onClick={() => openUrlOnNewTab(list?.url)}
                            >
                              {list.title}
                            </Button>
                          </li>
                        </ul>
                      ))}
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>{" "}
                      Bar Licience
                    </div>
                    {"barLicense" in agent &&
                      agent?.barLicense?.map((list) => (
                        <ul key={list._id}>
                          <li>
                            <label>License Number</label>
                            <span>{list?.licenseNumber}</span>
                          </li>

                          <li>
                            <label>State</label>
                            <span>{list?.state}</span>
                          </li>

                          <li>
                            <label>Attachment</label>
                            <Button
                              color="link"
                              onClick={() => openUrlOnNewTab(list?.url)}
                            >
                              {list?.title}
                            </Button>
                          </li>
                        </ul>
                      ))}
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>{" "}
                      ID verification
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.idVerification.url)
                          }
                        >
                          {agent?.idVerification?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>{" "}
                      Background check report
                    </div>
                    <ul>
                      <li>
                        <label>Company</label>
                        <span>
                          {agent?.backgroundCheckReport?.nameOfCompany}
                        </span>
                      </li>

                      <li>
                        <label>Issue date</label>
                        <span>
                          {formatDate(agent?.backgroundCheckReport?.expiredOn)}
                        </span>
                      </li>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.backgroundCheckReport?.url)
                          }
                        >
                          {" "}
                          {agent?.backgroundCheckReport?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>{" "}
                      E&O insurance
                    </div>

                    <ul>
                      <li>
                        <label>Company</label>
                        <span>{agent?.eoInsurance?.companyName}</span>
                      </li>
                      <li>
                        <label>Policy Number</label>
                        <span>{agent?.eoInsurance?.policyNumber}</span>
                      </li>
                      <li>
                        <label>Amount</label>
                        <span>{agent?.eoInsurance?.amount}</span>
                      </li>
                      <li>
                        <label>Expiration date</label>
                        <span>{formatDate(agent?.eoInsurance?.expiredOn)}</span>
                      </li>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.eoInsurance?.url)
                          }
                        >
                          {agent?.eoInsurance?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      W-9
                    </div>
                    <ul>
                      <li>
                        <label>Year</label>
                        <span>{agent?.w9Credentials?.certficationYear}</span>
                      </li>

                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.w9Credentials?.url)
                          }
                        >
                          {" "}
                          {agent?.w9Credentials?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      NNA certification
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.nnaCertification?.url)
                          }
                        >
                          {agent?.nnaCertification?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      LSS Certification
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.lssCertification?.url)
                          }
                        >
                          {" "}
                          {agent?.lssCertification?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      MD Title Producer License
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.mdTitleProducerLicense?.url)
                          }
                        >
                          {agent?.mdTitleProducerLicense?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      Title Producer License
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.titleProducerLicense?.url)
                          }
                        >
                          {agent?.titleProducerLicense?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      Add Ny Title Closer
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.nyTotalCloser?.url)
                          }
                        >
                          {agent?.nyTotalCloser?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      Notary Bond
                    </div>
                    <ul>
                      <li>
                        <label>Attachment</label>
                        <Button
                          color="link"
                          onClick={() =>
                            openUrlOnNewTab(agent?.notaryBond?.url)
                          }
                        >
                          {" "}
                          {agent?.notaryBond?.title}
                        </Button>
                      </li>
                    </ul>
                  </div>

                  <div className="credentialsItem">
                    <div className="credentialTitle">
                      <span className="icon">
                        <SvgIcons type={"check"} />
                      </span>
                      RON Credentials
                    </div>
                    {"ronCredentials" in agent &&
                      agent?.ronCredentials.map((list) => (
                        <ul key={list._id}>
                          <li>
                            <label>State</label>
                            <span>{list?.state}</span>
                          </li>
                          <li>
                            <label>Attachment</label>
                            <Button
                              color="link"
                              onClick={() => openUrlOnNewTab(list?.url)}
                            >
                              {list?.title}
                            </Button>
                          </li>
                        </ul>
                      ))}
                  </div>
                </CardBody> */}

                <CardBody>
                  {this?.props?.loadingData?.isVisible ? (
                    <SkeletonLoading
                      type="card"
                      count={1}
                      height={450}
                      width={isForMobile ? 300 : 1150}
                    />
                  ) : (
                    <>
                      {/* <ul className="agentCredentials"> */}
                      {credentialsData.map(
                        (obj, index) =>
                          (obj.value === "notaryLicense" ||
                            obj.value === "barLicense" ||
                            obj.value === "ronCredentials") &&
                          agent[obj.value] &&
                          agent[obj.value]
                            .filter((item) => item.isVerified === "Approved")
                            .map((each, idx) => {
                              return (
                                <div className={`credentialsItem`} key={idx}>
                                  <div className="credentialTitle">
                                    <span className="icon">
                                      <SvgIcons type={"check"} />
                                    </span>{" "}
                                    {obj.label}
                                    {agent[obj.value][idx]?.state ? (
                                      <>
                                        {" "}
                                        ({agent[obj.value][idx]?.state ||
                                          "NA"}){" "}
                                      </>
                                    ) : null}
                                  </div>

                                  {/* <li> */}
                                  <ul>
                                    {Object.keys(agent[obj.value][idx]).map(
                                      (key) => {
                                        if (
                                          [
                                            "state",
                                            "expiredOn",
                                            "licenseNumber",
                                          ].includes(key)
                                        ) {
                                          return (
                                            <li key={key}>
                                              <label>
                                                {key === "state"
                                                  ? "State"
                                                  : key === "expiredOn"
                                                    ? "Expiration Date"
                                                    : "License Number"}
                                              </label>
                                              <span>
                                                {key === "expiredOn"
                                                  ? agent[obj.value][idx][key]
                                                    ? formatDate(
                                                      agent[obj.value][idx][
                                                      key
                                                      ]
                                                    )
                                                    : "NA"
                                                  : agent[obj.value][idx][
                                                  key
                                                  ] || "NA"}
                                              </span>
                                            </li>
                                          );
                                        }
                                        return null;
                                      }
                                    )}

                                    <li>
                                      <label>Attachment</label>
                                      {agent.agentWorkedWithCompanys.some(
                                        (item) =>
                                          item === this.props.userData.user.id
                                      ) && (
                                          <Button
                                            color="link"
                                            onClick={() =>
                                              openUrlOnNewTab(
                                                agent[obj.value][idx]?.url
                                              )
                                            }
                                          >
                                            {agent[obj.value][idx]?.title
                                              ? truncateFilename(
                                                agent[obj.value][idx]?.title,
                                                10
                                              )
                                              : "View File"}
                                          </Button>
                                        )}
                                    </li>
                                  </ul>
                                  {/* </li> */}
                                </div>
                              );
                            })
                      )}

                      {/* Other credentials */}
                      {credentialsData.map((obj) =>
                        obj.value !== "notaryLicense" &&
                          obj.value !== "barLicense" &&
                          obj.value !== "ronCredentials" &&
                          agent[obj.value]?.isVerified === "Approved" &&
                          agent[obj.value]?.url ? (
                          <div className="credentialsItem" key={obj.value}>
                            <div className="credentialTitle">
                              <span className="icon">
                                <SvgIcons type={"check"} />
                              </span>{" "}
                              {obj.label}
                              {agent[obj.value]?.state ? (
                                <> ({agent[obj.value]?.state || "NA"}) </>
                              ) : null}
                            </div>

                            <ul>
                              {Object.keys(agent[obj.value]).map((key) => {
                                if (
                                  [
                                    "nameOfCompany",
                                    "certficationYear",
                                    "expiredOn",
                                    "companyName",
                                    "licenseNumber",
                                    "policyNumber",
                                    "amount",
                                  ].includes(key)
                                ) {
                                  return (
                                    <li key={key}>
                                      <label>
                                        {key === "nameOfCompany"
                                          ? "Company"
                                          : key === "certficationYear"
                                            ? "Year"
                                            : key === "expiredOn"
                                              ? obj.value ===
                                                "backgroundCheckReport"
                                                ? "Issued Date"
                                                : "Expiration Date"
                                              : key === "companyName"
                                                ? "Company"
                                                : key === "policyNumber"
                                                  ? "Policy Number"
                                                  : key === "amount"
                                                    ? "Amount"
                                                    : key}
                                      </label>
                                      <span>
                                        {key === "expiredOn"
                                          ? agent[obj.value][key]
                                            ? formatDate(agent[obj.value][key])
                                            : "NA"
                                          : agent[obj.value][key] || "NA"}
                                      </span>
                                    </li>
                                  );
                                }
                                return null;
                              })}

                              <li>
                                <label>Attachment</label>
                                {agent.agentWorkedWithCompanys.some(
                                  (item) => item === this.props.userData.user.id
                                ) && (
                                    <Button
                                      color="link"
                                      onClick={() =>
                                        openUrlOnNewTab(agent[obj.value]?.url)
                                      }
                                    >
                                      {agent[obj.value]?.title
                                        ? truncateFilename(
                                          agent[obj.value]?.title,
                                          10
                                        )
                                        : "View File"}
                                    </Button>
                                  )}
                              </li>
                            </ul>
                          </div>
                        ) : null
                      )}
                      {/* </ul> */}
                    </>
                  )}
                </CardBody>

                {this._isCredentialsExist() ? null : (
                  <div className="nodata">
                    <img
                      src={require("../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p> There is no credentials to display</p>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
    loadingData: state.loaderData ? state.loaderData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(AgentDetails));
