import { RegexConfig } from "./RegexConfig";

export const addBlogValidationConfig = {
  title: {
    required: true,
    minLength: 1,
    maxLength: 100,
    errorMessage: {
      required: "*Required",
      length: "*Must be between 1 to 100 characters",
    },
  },
  url: {
    required: true,
    pattern: RegexConfig.slug,
    errorMessage: {
      required: "*Required",
      pattern: "*Please enter a valid slug",
    },
  },
  category: {
    required: true,
    errorMessage: {
      required: "*Required",
    },
  },
  metaTitle: {
    required: true,
    minLength: 1,
    maxLength: 100,
    errorMessage: {
      required: "*Required",
      length: "*Must be between 1 to 100 characters",
    },
  },
  authorName: {
    required: true,
    minLength: 1,
    maxLength: 100,
    errorMessage: {
      required: "*Required",
      length: "*Must be between 1 to 100 characters",
    },
  },
  description: {
    required: true,
    errorMessage: {
      required: "*Required",
    },
  },
  tags: {
    required: true,
    errorMessage: {
      required: "*Required",
    },
  },
  content: {
    required: true,
    errorMessage: {
      required: "*Required",
    },
    validator: (value) => {
      // Create a temporary element to strip out HTML tags
      const tempDiv = document.createElement("div");
      tempDiv.innerHTML = value;

      // Get the text content (this will ignore any line breaks or empty tags)
      const textContent = tempDiv.textContent || tempDiv.innerText || "";

      // Check if the text content is empty or contains only whitespace
      if (textContent.trim() === "") return "*Required";

      return "";
    },
  },
  coverImage: {
    required: true,
    errorMessage: {
      required: "*Required",
    },
    validator: (value) => {
      if (!value?.file?.uploadData && !value?.file?.uploadedUrl)
        return "*Required";
      return "";
    },
  },
};
