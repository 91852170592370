import React, { useEffect } from "react";
import { Badge, Button } from "reactstrap";
import { formatDate } from "../../../../helper-methods";

const BlogDetail = ({
  handleViewBlogDetailsPage,
  blogId,
  loading,
  blog,
  fetchBlogById,
}) => {
  console.log({ blogId });

  useEffect(() => {
    fetchBlogById(blogId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogId]);

  console.log({ blog });

  // if (loading?.fetchBlogById) {
  //   return (
  //     <div className="text-center" style={{ marginTop: "100px" }}>
  //       <i className="fa fa-spinner fa-spin mr-1" />
  //     </div>
  //   );
  // }

  return (
    <>
      <div className="blogPageTitle">
        <Button
          color="link"
          className="backBtn"
          onClick={() => handleViewBlogDetailsPage(null)}
        >
          <img
            src={require("../../../../assets/img/arrowLeft.svg").default}
            alt="backbutton"
            height={14}
          />
        </Button>
        <h2>Blog Preview</h2>
      </div>
      {loading?.fetchBlogById ? (
        <div className="text-center" style={{ marginTop: "100px" }}>
          <i className="fa fa-spinner fa-spin mr-1" />
        </div>
      ) : (
        <div className="blogWrapper">
          <h1>{blog?.title || "-"}</h1>

          <div className="tags">{blog?.tags?.join(", ") || null}</div>

          {blog?.category?.length ? (
            <div className="blogInfo">
              <div className="blogCategory">
                {blog?.category?.map((category) => (
                  <Badge color="primary" key={category}>
                    {category}
                  </Badge>
                ))}
              </div>

              <div className="date">
                {blog?.status === "published"
                  ? formatDate(blog?.publishedDate)
                  : formatDate(blog?.createdAt)}
              </div>
            </div>
          ) : null}

          <div className="featuredImage">
            <img
              src={
                blog?.coverImage ||
                require("../../../../assets/img/banner1.png")
              }
              alt="public"
              width={1000}
              height={100}
            />
          </div>

          <div className="detailContent">
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: blog?.content }}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BlogDetail;
