import { useEffect, useState } from "react";
import { errorHandler, showToast } from "../../../helper-methods";
import {
  createBlog,
  deleteBlog,
  deleteMultipleBlogs,
  findAllBlogs,
  getBlog,
  getBlogFilterData,
  updateBlog,
} from "../../../http/http-calls";

const initialFilters = {
  search: "",
  status: "",
  categories: "",
};

const initialTableConfig = {
  skip: 0,
  limit: 10,
  pageNumber: 1,
};

const useBlogs = () => {
  const [blogs, setBlogs] = useState({
    data: [],
    totalCount: 0,
  });
  const [blog, setBlog] = useState(null); // to store blog details when fetching by id for a single blog
  const [tags, setTags] = useState([]);
  const [authors, setAuthors] = useState([]);
  // const [currentPage, setCurrentPage] = useState(0);

  const [tableConfig, setTableConfig] = useState(initialTableConfig);
  const [loading, setLoading] = useState({
    fetchBlogs: false,
    fetchBlogById: false,
    bulkDeleteLoading: false,
    deleteLoading: null,
    publishLoading: null,
    createBlogLoading: false,
    updateBlogLoading: false,
  });

  const _manageLoading = (key, value) => {
    setLoading((prev) => ({ ...prev, [key]: value }));
  };

  const [filters, setFilters] = useState(initialFilters);

  const _resetPage = () => setTableConfig({ ...tableConfig, skip: 0 });

  // fetch all blogs
  const _fetchBlogs = async (
    payload,
    tableConfig = initialTableConfig,
    isShowLoadingAndToast = true
  ) => {
    try {
      setFilters(payload);
      setTableConfig(tableConfig);

      if (isShowLoadingAndToast) {
        _manageLoading("fetchBlogs", true);
      }

      const newPayload = { ...payload, ...tableConfig };
      const filterPayload = _prepareFilterPayload(newPayload);

      const res = await findAllBlogs(filterPayload);
      if (!res?.error) {
        setBlogs({
          data: res?.blogs,
          totalCount: res?.totalCount,
        });
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      if (isShowLoadingAndToast) {
        _manageLoading("fetchBlogs", false);
      }
    }
  };

  // fetch a blog by id
  const _fetchBlogById = async (id) => {
    _manageLoading("fetchBlogById", true);
    try {
      const res = await getBlog(id);
      if (!res?.error) {
        setBlog(res?.blog);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      _manageLoading("fetchBlogById", false);
    }
  };

  const _prepareFilterPayload = (newFilters) => {
    const filterPayload = { ...newFilters };

    if (!filterPayload?.search) delete filterPayload?.search;
    if (!filterPayload?.status) delete filterPayload?.status;
    if (!filterPayload?.categories?.length) {
      delete filterPayload?.categories;
      delete filterPayload?.category;
    }

    if (filterPayload?.categories) {
      filterPayload["category"] = filterPayload?.categories?.map(
        (each) => each.value
      );
      delete filterPayload?.categories;
    }

    if (filterPayload?.search?.length) {
      filterPayload["title"] = filterPayload?.search || "";
      delete filterPayload?.search;
    }

    if (filterPayload?.pageNumber) {
      delete filterPayload?.pageNumber;
    }

    return filterPayload;
  };

  // fetch all blog filters
  const _fetchBlogFilterData = async () => {
    try {
      const res = await getBlogFilterData();
      if (!res?.error) {
        setTags(res?.tags);
        setAuthors(res?.admins);
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  // delete a blog
  const _deleteBlog = async (id) => {
    try {
      _manageLoading("deleteLoading", id);
      const res = await deleteBlog(id);
      if (!res?.error) {
        showToast("Blog deleted successfully", "success");
        _fetchBlogs(filters, tableConfig, false); // refetch data silently i.e without showing the loading and toaster

        // optimistic update
        const remvoeBlogIndex = blogs?.data?.findIndex(
          (each) => each?._id === id
        );
        if (remvoeBlogIndex > -1) {
          blogs?.data?.splice(remvoeBlogIndex, 1);
        }

        setBlogs({ ...blogs, data: blogs?.data });
      }
    } catch (error) {
      errorHandler(error);
      _fetchBlogs(filters, tableConfig); // only refetch if there is an error while deleting the blog otherwise optimistic update will be shown
    } finally {
      _manageLoading("deleteLoading", null);
    }
  };

  // delete multiple blogs at once
  const _deleteSelectedBlogs = async (blogs) => {
    try {
      _manageLoading("bulkDeleteLoading", true);
      const payload = {
        blogIds: blogs?.map((each) => each?.id || each?._id), // array of blog ids
      };

      const res = await deleteMultipleBlogs(payload);
      if (!res?.error) {
        showToast("Blog deleted successfully", "success");
        _fetchBlogs(filters, tableConfig);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      _manageLoading("bulkDeleteLoading", false);
    }
  };

  const _publishBlog = async (id) => {
    try {
      _manageLoading("publishLoading", id);

      // update the blog with the published status
      const payload = {
        isPublished: true,
        status: "published",
      };

      const res = await updateBlog({ payload, id });
      if (!res?.error) {
        showToast("Blog published successfully", "success");
        _fetchBlogs(filters, tableConfig, false); // refetch data silently i.e without showing the loading and toaster
      }

      // optimistic update
      const blogIndex = blogs?.data?.findIndex((each) => each?._id === id);
      if (blogIndex > -1) {
        const updatedBlog = blogs?.data[blogIndex];
        updatedBlog.status = payload?.status;
        updatedBlog.isPublished = payload?.isPublished;
        updatedBlog.publishedDate = new Date();
        blogs.data[blogIndex] = updatedBlog;
      }

      setBlogs({ ...blogs, data: blogs?.data });
    } catch (error) {
      errorHandler(error);
      _fetchBlogs(filters, tableConfig); // only refetch if there is an error while publishing the blog otherwise optimistic update will be shown
    } finally {
      _manageLoading("publishLoading", null);
    }
  };

  const _onPageChange = (pageNumber = 1) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig.skip = (pageNumber - 1) * newTableConfig?.limit;
    newTableConfig.pageNumber = pageNumber;

    setTableConfig(newTableConfig);

    _fetchBlogs(filters, newTableConfig);
  };

  const _createBlog = async ({ payload, previewUrl = undefined }) => {
    return new Promise(async (resolve, reject) => {
      try {
        _manageLoading("createBlogLoading", true);
        const res = await createBlog(payload);

        if (!res?.error) {
          showToast("Blog Created Successfully", "success");
          _fetchBlogs(filters, tableConfig); // refetch data silently i.e without showing the loading and toaster

          // // optimistic update
          // const newBlog = { ...payload, coverImage: previewUrl || "" };
          // blogs?.data?.unshift(newBlog); // add the new blog to the top of the list
          // setBlogs({ ...blogs, data: blogs?.data });

          resolve(res);
        }
      } catch (err) {
        _fetchBlogs(filters); // only refetch if there is an error while creating the blog otherwise optimistic update will be shown
        errorHandler(err);
        reject(err);
      } finally {
        _manageLoading("createBlogLoading", false);
      }
    });
  };

  const _updateBlog = async ({ payload, id, previewUrl = undefined }) => {
    return new Promise(async (resolve, reject) => {
      try {
        _manageLoading("updateBlogLoading", id);
        const res = await updateBlog({ payload, id });

        if (!res?.error) {
          showToast("Blog Updated Successfully", "success");
          _fetchBlogs(filters, tableConfig, false); // refetch data silently i.e without showing the loading and toaster

          // optimistic update
          const blogIndex = blogs?.data?.findIndex((each) => each?._id === id);
          if (blogIndex > -1) {
            const oldBlog = blogs?.data[blogIndex];

            blogs.data[blogIndex] = {
              ...oldBlog,
              ...payload,
              coverImage: previewUrl || oldBlog?.coverImage || "", // to be used for preview url for opening edit modal again without refreshing the page as we are optimistically updating the blog, so the image is not shown in the edit modal due to permission issue
            };
          }

          setBlogs({ ...blogs, data: blogs?.data });

          resolve(res);
        } else {
          reject(res);
        }
      } catch (err) {
        _fetchBlogs(filters, tableConfig); // only refetch if there is an error while updating the blog otherwise optimistic update will be shown
        errorHandler(err);
        reject(err);
      } finally {
        _manageLoading("updateBlogLoading", null);
      }
    });
  };

  useEffect(() => {
    _fetchBlogs({ ...filters });
    // _fetchBlogFilterData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authors,
    blogs: blogs?.data,
    blogsTotalCount: blogs?.totalCount,
    filters,
    setFilters,
    tableConfig,
    deleteBlog: _deleteBlog,
    deleteSelectedBlogs: _deleteSelectedBlogs,
    fetchBlogs: _fetchBlogs,
    fetchBlogFilterData: _fetchBlogFilterData,
    loading,
    onPageChange: _onPageChange,
    resetPage: _resetPage,
    fetchBlogById: _fetchBlogById,
    publishBlog: _publishBlog,
    createBlog: _createBlog,
    updateBlog: _updateBlog,
    blog,
    tags,
  };
};

export default useBlogs;
