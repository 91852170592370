import React, { useEffect, useRef, useState } from 'react'
import SvgIcons from '../../../../components/SvgIcons'
import { Button, Input, InputGroup, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import { errorHandler } from '../../../../../../helper-methods'
import { RegexConfig } from '../../../../../../config/RegexConfig'

const WebsiteFooterLinkPopOver = ({
  link,  // link object having predefined config
  tag: Tag = "li", // tag to render, defaults to li
  openPopoverId, // state to manage popover open state
  togglePopover, // function to toggle popover
  onEdit, // function to edit link using onInputHandler fn
  prefillValue = "" // prefill value for input field if availiable in websiteData object
}) => {
  const popoverRef = useRef(null);


  // console.log({ link, prefillValue });
  const [inputValue, setInputValue] = useState("");

  const _onClose = () => {
    setInputValue("");
    togglePopover(openPopoverId);
  }

  const _handleSubmit = async (e, data) => {
    try {
      if (e) e.stopPropagation();

      if (!inputValue) {
        _onClose();
        return; // empty input
      }

      if (!RegexConfig?.urlRegex?.test(inputValue)) {
        errorHandler({ reason: "Please enter a valid URL." });
        return;
      }

      if (data?.subKey) {
        onEdit({ title: data?.subKey, data: inputValue, type: data?.dataType, key: data?.key });
      } else {
        onEdit({ title: null, data: inputValue, type: data?.dataType, key: data?.key });
      }

      _onClose();
    } catch (err) {
      errorHandler(err);
    }
  }

  useEffect(() => {
    if (prefillValue && prefillValue?.length && openPopoverId === link?.id) {
      setInputValue(prefillValue);
    } else {
      setInputValue("");
    }
  }, [prefillValue, openPopoverId, link])

  // Close on outside click & scroll
  // useEffect(() => {
  //   const handleClose = (event) => {
  //     // Ignore if the input is focused
  //     if (popoverRef?.current?.contains(event.target) && document.activeElement?.tagName === "INPUT") return;

  //     if (
  //       popoverRef?.current &&
  //       !(popoverRef?.current)?.contains(event.target)
  //     ) {
  //       _onClose()
  //     }
  //   };

  //   if (openPopoverId === link?.id) {
  //     document.addEventListener("scroll", handleClose, true); // true captures scroll in nested elements
  //   } else {
  //     document.removeEventListener("scroll", handleClose, true);
  //   }

  //   return () => {
  //     document.removeEventListener("scroll", handleClose, true);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [openPopoverId, link?.id]);

  return (
    <Tag
      ref={popoverRef}
      id={link?.id}
      style={{ cursor: "pointer", marginRight: "10px" }}
      onClick={() => togglePopover(link?.id)}
    >
      {link?.isShowIcon && link?.imageType ? <SvgIcons type={link?.imageType} /> : null}
      {link?.isShowLabel && link?.label ? <span>{link?.label || ""}</span> : null}
      <Popover
        trigger="legacy"
        placement="top"
        isOpen={openPopoverId === link?.id}
        target={link?.id}
        toggle={() => togglePopover(link?.id)}
      >
        <PopoverHeader>
          {link?.label?.toUpperCase()}
        </PopoverHeader>
        <PopoverBody>
          <InputGroup>
            <Input
              onClick={(e) => e.stopPropagation()}
              imageType="text"
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={`Enter ${link?.label} link...`}
            />
            <Button
              color="primary"
              className="h-auto m-1 fs-14 px-4 py-2"
              onClick={(e) => _handleSubmit(e, link)}
              disabled={!inputValue}
            >
              OK
            </Button>
          </InputGroup>
        </PopoverBody>
      </Popover>
    </Tag>
  )
}

export default WebsiteFooterLinkPopOver