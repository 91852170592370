import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  errorHandler,
  getPhoneNumberFromBrackets,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  getUserProfileDetail,
  updateUserProfileDetail,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { updateAssistantObj } from "../../../redux/actions/user-data";

const initialBasicDetails = {
  name: {
    value: "",
    error: null,
    isDirty: false,
    isValidate: true,
  },
  email: {
    value: "",
    error: null,
    isDirty: false,
    isValidate: false,
  },
  phoneCountry: {
    value: "US",
    error: null,
    isDirty: false,
    isValidate: false,
  },
  phone: {
    value: "",
    error: null,
    isDirty: false,
    isValidate: true,
  },
  password: {
    value: "",
    error: null,
    isDirty: false,
    isValidate: true,
  },
  profilePicture: {
    file: {
      previewBlob: null,
      uploadData: null,
      type: null,
    },
    error: null,
    isDirty: false,
  },
};
const UserProfile = () => {
  const userData = useSelector((state) => state?.userData);
  // const percentage = useSelector((state) => state);
  const dispatch = useDispatch();

  const [basicDetails, setBasicDetails] = useState(initialBasicDetails);
  // const [showPassword, setShowPassword] = useState({
  //   password: true,
  // });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [changePassword, setChangePassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const _showPasswordToggle = () => {
    setIsShowPassword((prev) => !prev);
  };

  useEffect(() => {
    _getUserProfileDetail();

    // setTimeout(() => {
    //   setShowPassword({
    //     password: false,
    //   });
    // }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getUserProfileDetail = () => {
    return new Promise(async (resolve, reject) => {
      try {
        let id = userData?.user?._assistant?._id;
        let userType = userData?.user?.type?.toLowerCase();

        const res = await getUserProfileDetail({ userType, id });

        if (res.user) {
          dispatch(updateAssistantObj(res?.user));
        }

        setUserDetails(res?.user);
        _setFormData(res.user);
        dispatch(hideLoader());
        resolve(res);
      } catch (error) {
        dispatch(hideLoader());
        reject(error);
      }
    });
  };

  const _updateFileImageBasicDetail = (fieldName, event) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image") {
        const previewBlob = URL.createObjectURL(objFile);
        setBasicDetails((prevState) => ({
          ...prevState,
          [fieldName]: {
            file: {
              previewBlob,
              uploadData: objFile,
              type: objFileType === "application" ? "pdf" : objFileType,
            },
            error: null,
            isDirty: false,
          },
        }));
      } else {
        errorHandler({ reason: "Only Image file is allowed" });
      }
    }
  };

  const _setFormData = (data) => {
    const newBasicDetails = { ...basicDetails };

    newBasicDetails["name"].value = data.name.full;
    newBasicDetails["email"].value = data.email;

    newBasicDetails["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";

    newBasicDetails["profilePicture"].file = {
      previewBlob: data.profilePicture ? data.profilePicture : null,
      uploadData: null,
      type: null,
    };

    setBasicDetails(newBasicDetails);
  };

  const _onChangeBasicDetail = (fieldName, value) => {
    const newBasicDetails = { ...basicDetails };

    if (fieldName === "email") {
      return;
    }

    newBasicDetails[fieldName].value = value;

    newBasicDetails[fieldName].isDirty = true;

    setBasicDetails(newBasicDetails);

    _validateBasicDetailForm({ newBasicDetails });
  };

  const _validateBasicDetailForm = ({ newBasicDetails }) => {
    return new Promise((resolve, reject) => {
      let isFormValid = true;

      Object.keys(newBasicDetails).forEach((key) => {
        if (newBasicDetails[key].isDirty && newBasicDetails[key].isValidate) {
          switch (key) {
            case "name": {
              if (
                newBasicDetails[key].value &&
                newBasicDetails[key].value.trim().length
              ) {
                newBasicDetails[key].isDirty = false;
                newBasicDetails[key].error = null;
              } else {
                newBasicDetails[key].isDirty = true;
                newBasicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "email": {
              if (
                newBasicDetails[key].value &&
                newBasicDetails[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(newBasicDetails[key].value).toLowerCase()
                  )
                ) {
                  newBasicDetails[key].isDirty = false;
                  newBasicDetails[key].error = null;
                } else {
                  newBasicDetails[key].isDirty = true;
                  newBasicDetails[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                newBasicDetails[key].isDirty = true;
                newBasicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (
                newBasicDetails[key].value &&
                newBasicDetails[key].value.length
              ) {
                if (
                  RegexConfig.phone.test(
                    String(newBasicDetails[key].value).toLowerCase()
                  )
                ) {
                  newBasicDetails[key].isDirty = false;
                  newBasicDetails[key].error = null;
                } else {
                  isFormValid = false;
                  newBasicDetails[key].isDirty = true;
                  newBasicDetails[key].error = "*Invalid Phone Number";
                }
              } else {
                newBasicDetails[key].isDirty = false;
                newBasicDetails[key].error = null;
              }
              break;
            }
            case "password": {
              if (
                newBasicDetails[key].value &&
                newBasicDetails[key].value.trim().length
              ) {
                if (!newBasicDetails[key].value.trim().length) {
                  newBasicDetails[key].error = "Password is Required";
                } else if (
                  !RegexConfig?.newPassword?.test(
                    String(newBasicDetails?.[key]?.value)
                  )
                  // !new RegExp("^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$").test(
                  //   newBasicDetails[key].value
                  // )
                ) {
                  newBasicDetails[key].error =
                    "*Password must be at least 12 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.";
                } else {
                  newBasicDetails[key].error = null;
                  newBasicDetails[key].isDirty = false;
                }
              } else {
                newBasicDetails[key].error = null;
                newBasicDetails[key].isDirty = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      setBasicDetails(newBasicDetails);

      resolve(isFormValid);
    });
  };

  const _onSubmitBasicDetail = async () => {
    try {
      let isFormValid = Object.values(basicDetails).every((value) => {
        return value.error === null;
      });

      if (!isFormValid) {
        errorHandler({ reason: "Please Fill The Required Fields" });
        return;
      }

      if (isFormValid) {
        dispatch(showLoader("Update Company Profile..."));

        setLoading(true);

        let splitName = basicDetails?.name?.value?.trim()?.split(" ");

        if (splitName?.length) {
          splitName = {
            first:
              splitName.length > 1
                ? splitName.slice(0, -1).join(" ")
                : splitName[0],
            last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
          };
        }

        const payload = {
          id: userDetails?._id,
          name: {
            first: splitName?.first?.trim() ? splitName?.first?.trim() : "",
            last: splitName?.last?.trim() ? splitName?.last?.trim() : "",
          },
        };

        if (basicDetails?.phone.value) {
          payload["phone"] = `${basicDetails?.phone?.value}`;
        }

        if (basicDetails?.password?.value) {
          payload["password"] = `${basicDetails?.password?.value}`;
        }

        if (
          basicDetails?.profilePicture?.file &&
          basicDetails?.profilePicture?.file?.uploadData
        ) {
          const uploadedFilesRes = await uploadFileOnServer([
            basicDetails.profilePicture.file,
          ]);
          payload["profilePicture"] = uploadedFilesRes[0].url;
        } else {
          payload["profilePicture"] =
            basicDetails?.profilePicture?.file?.previewBlob;
        }
        _updateUserProfileDetail(payload, "Details have been updated");
      }
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _updateUserProfileDetail = (payload, toastSuccessMsg = null) => {
    return new Promise(async (resolve, reject) => {
      try {
        setLoading(true);

        const newBasicDetails = { ...basicDetails };

        let userType = userData?.user?.type?.toLowerCase();

        await updateUserProfileDetail({ userType, payload });

        await _getUserProfileDetail();

        newBasicDetails["password"].value = "";
        showToast(
          toastSuccessMsg ? toastSuccessMsg : "Details have been updated",
          "success"
        );
        dispatch(hideLoader());

        setBasicDetails(newBasicDetails);
        setLoading(false);
        // setShowPassword({
        //   password: false,
        // });
        // setChangePassword(false);
        resolve(true);
      } catch (error) {
        errorHandler(error);
        dispatch(hideLoader());
        setLoading(false);
        resolve(false);
      }
    });
  };

  return (
    <>
      <div className="responsiveTitle">
        <h2>My Profile </h2>
      </div>

      <Card className="">
        <CardBody>
          <Row>
            <Col sm="12">
              <Label className="uploadProfile">
                <Input
                  type="file"
                  style={{ display: "none" }}
                  disabled={loading}
                  accept="image/x-png,image/gif,image/jpeg"
                  value=""
                  onChange={(event) =>
                    _updateFileImageBasicDetail("profilePicture", event)
                  }
                />
                <img
                  src={
                    basicDetails.profilePicture.file &&
                    basicDetails.profilePicture.file.previewBlob
                      ? basicDetails.profilePicture.file.previewBlob
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Profile Img"
                />
                <i className="fa fa-pencil"></i>
              </Label>
            </Col>

            <Col xl={4} md={6}>
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  value={basicDetails?.name?.value}
                  name="name"
                  onChange={(event) =>
                    _onChangeBasicDetail("name", event.target.value)
                  }
                  placeholder=" "
                />
                <Label>Name</Label>
                {basicDetails?.name?.error && (
                  <div className="validation-error">
                    {basicDetails?.name?.error}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl={4} md={6}>
              <FormGroup className="floatingLabel">
                <Input
                  type="email"
                  value={basicDetails?.email?.value}
                  disabled={true}
                  name="email"
                  placeholder=" "
                  onChange={(event) =>
                    _onChangeBasicDetail("email", event.target.value)
                  }
                />
                <Label>Email</Label>
                {basicDetails?.email?.error && (
                  <div className="validation-error">
                    {basicDetails?.email?.error}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col xl={4} md={6}>
              <FormGroup className="floatingLabel withInputGroup">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>+1</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="phone"
                    value={basicDetails?.phone?.value}
                    name="phone"
                    onChange={(event) =>
                      _onChangeBasicDetail("phone", event.target.value)
                    }
                    placeholder=" "
                  />
                  <Label>Phone</Label>
                </InputGroup>
                {basicDetails?.phone?.error && (
                  <div className="validation-error">
                    {basicDetails?.phone?.error}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12">
              <CustomInput
                id="changePassword"
                type="checkbox"
                name="unassigned"
                checked={changePassword}
                onChange={() => setChangePassword((prev) => !prev)}
                label="Change Password"
              />

              {changePassword ? (
                <FormGroup
                  className="floatingLabel mt-2"
                  style={{ maxWidth: 350 }}
                >
                  <InputGroup>
                    <Input
                      type={isShowPassword ? "text" : "password"}
                      value={basicDetails?.password?.value}
                      name="password"
                      placeholder=" "
                      onChange={(event) =>
                        _onChangeBasicDetail("password", event.target.value)
                      }
                    />
                    <Label>Password</Label>
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <img
                          src={require(`../../../assets/img/${
                            isShowPassword ? "eyeIcon.svg" : "eyeIconSlash.svg"
                          }`)}
                          onClick={_showPasswordToggle}
                          alt="eye icon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  {basicDetails?.password?.error && (
                    <div className="validation-error">
                      {basicDetails?.password?.error}
                    </div>
                  )}
                </FormGroup>
              ) : null}
            </Col>
          </Row>
          <div className="text-center mt-3">
            <Button
              color="primary"
              disabled={loading}
              onClick={_onSubmitBasicDetail}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Save
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default UserProfile;
