import React, { useEffect, useState } from "react";
import PublicSideCarousel from "../components/PublicCarousel/PublicSideCarousel";
import {
  FormGroup,
  Input,
  Label,
  Form,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import { errorHandler, showToast } from "../../../helper-methods";
import { guestAgentResetPassword } from "../../../http/http-calls";
import { useHistory } from "react-router-dom";
import Gleap from "gleap";
import { RegexConfig } from "../../../config/RegexConfig";

const initialValues = {
  password: "",
  isTermsAndConditionsAccepted: "",
};

let initialIsDirty = {
  password: false,
  isTermsAndConditionsAccepted: false,
};
const NnaAgentSignup = () => {
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [formFields, setFormFields] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState({});

  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const _onChangeFormFields = (key, value) => {
    const updatedObj = { ...formFields };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;

    setFormFields(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);
  };
  const _validateForm = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          case "password": {
            if (
              newFormFields[fieldName] &&
              newFormFields[fieldName].trim().length > 0
            ) {
              //   if (!newFormFields[fieldName].trim().length>0) {
              //     error[fieldName] = "Password is Required";
              //     isFormValid = false;
              //   } else
              if (
                !RegexConfig?.newPassword?.test(
                  newFormFields[fieldName]
                )
              ) {
                error[fieldName] =
                  "Password must be at least 12 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character.";
                isFormValid = false;
              } else {
                error[fieldName] = null;
                isDirty[fieldName] = false;
              }
            } else {
              error[fieldName] = "Password is Required";
              isFormValid = false;
            }
            break;
          }

          case "isTermsAndConditionsAccepted": {
            if (newFormFields[fieldName] === true) {
              error[fieldName] = null;
              isDirty[fieldName] = false;
            } else {
              error[fieldName] = "*Required";
              isFormValid = false;
            }
            break;
          }

          default: {
          }
        }
      });

      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  const _guestAgentSetup = async (e) => {
    if (e) e.preventDefault();
    setLoading(true);

    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));

    const error = await _validateForm(formFields, newIsDirty);
    // console.log("error", error);

    let payload = {
      agentId: history.location.pathname.split("/").pop(),
      password: formFields.password,
      isTermsAndConditionsAccepted: formFields.isTermsAndConditionsAccepted,
    };
    if (error) {
      try {
        await guestAgentResetPassword(payload);
        showToast("Password set are updated", "success");
        history.push("/login");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    // Hide Gleap in this page
    Gleap.showFeedbackButton(false);

    if (history.location?.pathname?.includes("null")) {
      history.push("/login");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="loginWrapper">
        <div className="loginLeft">
          <PublicSideCarousel />
        </div>
        <div className="loginRight">
          <img
            src={require("../../../assets/img/logBigIcon.png")}
            alt="logo big"
            className="bigLogo"
          />
          <div className="FormWrap">
            <h1>Complete Signup</h1>
            <p className="mb-4">
              Create your password to activate the account and login
            </p>

            <Form
              spellCheck="false"
              onSubmit={(e) => _guestAgentSetup(e)}
              autoComplete="off"
            >
              {/* <FormGroup className="floatingLabel">
                                <Input
                                    type="email"
                                    // value={formFields.email.value}
                                    // onChange={(e) =>
                                    //     this._onChangeFormField("email", e.target.value)
                                    // }
                                    placeholder=" "
                                    autoComplete="username"
                                />
                                <Label>Email</Label>

                                {/* <div className="validation-error">
                                    Required
                                // </div> 

                            </FormGroup> */}
              <FormGroup className="floatingLabel">
                <InputGroup>
                  <Input
                    type={isShowPassword ? "text" : "password"}
                    value={formFields.password}
                    onChange={(e) =>
                      _onChangeFormFields("password", e.target.value)
                    }
                    placeholder=" "
                  />
                  <Label>New Password</Label>
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <img
                        src={
                          require(`../../../assets/img/${isShowPassword ? "eyeIcon.svg" : "eyeIconSlash.svg"
                            }`)
                        }
                        onClick={() => setIsShowPassword(!isShowPassword)}
                        alt="eye icon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>

                {errors?.password && (
                  <div className="validation-error">{errors?.password}</div>
                )}
              </FormGroup>
              {/* <FormGroup className="floatingLabel">
                                <InputGroup>
                                    <Input
                                        type={isShowPassword ? "text" : "password"}
                                        // value={formFields.password.value}
                                        // onChange={(e) =>
                                        //     this._onChangeFormField("password", e.target.value)
                                        // }
                                        // onBlur={() => this._markAsDirty("password")}
                                        placeholder=" "
                                    />
                                    <Label>Confirm New Password</Label>
                                    <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                            <img
                                                src={
                                                    require(`../../../assets/img/${isShowPassword
                                                        ? "eyeIcon.svg"
                                                        : "eyeIconSlash.svg"
                                                        }`).default
                                                }
                                                onClick={()=> setIsShowPassword(!isShowPassword)}
                                                alt="eye icon"
                                            />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                </InputGroup>

                                {/* {formFields.password.error && (
                                    <div className="validation-error">
                                        {formFields.password.error}
                                    </div>
                                // )} 
                            </FormGroup> */}
              <div className=" mt-4 mb-4">
                <FormGroup className="d-flex">
                  <CustomInput
                    type="checkbox"
                    id="termsAndCondition"
                    // label="terms and Condition"
                    checked={formFields?.isTermsAndConditionsAccepted?.value}
                    onChange={(e) =>
                      _onChangeFormFields(
                        "isTermsAndConditionsAccepted",
                        e.target.checked
                      )
                    }
                  />
                  <Label>
                    I have read and agree to the
                    <Button
                      color="link"
                      onClick={() =>
                        // this._termsAndConditionModalToggle(true)
                        window.open(
                          "https://www.closewise.com/terms-and-conditions/"
                        )
                      }
                    >
                      terms and Condition{" "}
                    </Button>
                    <Button
                      color="link"
                      onClick={() =>
                        window.open("https://www.closewise.com/privacy-policy/")
                      }
                    >
                      And Privacy policy{" "}
                    </Button>
                  </Label>
                </FormGroup>
              </div>
              <Button
                color="primary"
                className="w-100 d-block my-4"
                type="submit"
                disabled={!formFields.isTermsAndConditionsAccepted || loading}
              >
                Save {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default NnaAgentSignup;
