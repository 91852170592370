import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
} from "reactstrap";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  capitalize,
  checkPermission,
  debounce,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  formatDateHideYearIfSame,
  formatPhoneNumber,
  formatTime,
  isObjectPresent,
  openUrlOnNewTab,
  showToast,
} from "../../../helper-methods";
import {
  activityLogClosing,
  companyExpenseForClosing,
  deleteDocumentForClosing,
  editDraft,
  getAgentWithZipcode,
  getAllCompanySettings,
  getAllFiltersData,
  getClosingDetailsById,
  getLoanType,
  getRating,
  getTrackingInfoById,
  updateClosingDetailsById,
} from "../../../http/http-calls";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import StarRatings from "react-star-ratings";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ScreenWidthHOC from "./ScreenWidthHOC";
import InfiniteScrollDropdown from "../components/AgentDropdown";
import SignerAppointment from "../components/common/signerAppointment";
import Instructions from "../components/instructions";
import { LoanType } from "../../../config/loan-types";
import { addSettings } from "../../../redux/actions/settings";
import ClosingAssignment from "../components/closing-assignment-modal";
import SkeletonLoading from "../components/Skeletons/SkeletonLoading";
import { TeamMembers } from "../components/Modals/Company/TeamMembers";
import SvgIcons from "../components/SvgIcons";
import { openTrackingDetails } from "../../../config/trackingConfig";

const DocumentViewModal = lazy(() => import("../components/documentViewModal"));
const AddRatingModal = lazy(() => import("../components/add-rating-modal"));
const SignerAvailabilityModal = lazy(() =>
  import("../components/signer-availability-modal")
);
const SigningStatusSigningModal = lazy(() =>
  import("../components/signing-status-signing")
);
const EditOrderDetailsModal = lazy(() =>
  import("../components/Modals/EditOrderDetailsModal")
);
const ChatLogCardComponent = lazy(() =>
  import("../components/ChatLogCardComponent")
);
const CustomPagination = lazy(() => import("../components/CustomPagination"));
const SecureDocumentCardComponent = lazy(() =>
  import("../components/SecureDocumentCardComponent")
);

const DownloadInvoiceModal = lazy(() =>
  import("../components/Modals/DownloadInvoiceModal")
);
class ClosingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companySetting: {},
      closingDetails: null,
      previousRating: null,
      isEditAgentFee: false,
      addRatingModal: {
        isOpen: false,
        data: null,
        mode: null,
      },
      loading: {
        statusChangeLoading: false,
        fileUploadLoading: false,
        expensesLoading: false,
        deleteExpensesLoading: false,
        agentSearchLoading: false,
      },
      signingStatusModal: {
        isOpen: false,
        data: null,
        status: null,
      },
      signerAvailabilityModal: {
        isOpen: false,
        data: null,
      },
      documentViewModalData: {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      },
      editing: {
        borrower: false,
        orderDetail: false,
        appointment: false,
        activityLog: false,
        instructions: false,
        agentDetail: false,
      },
      formFields: {
        borrowerName: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        phoneHomeDialCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        phoneWorkDialCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        phoneMobileDialCode: {
          value: "+1",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        homePhone: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        workPhone: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        phone: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        line1: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        line2: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        city: {
          value: "",
          isValid: false,
          isValidate: true,
          isDirty: false,
        },
        county: {
          value: "",
          isValid: false,
          isValidate: true,
          isDirty: false,
        },
        state: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        zip: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        fileNumber: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        lender: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        personOfContactName: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        personOfContactEmail: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        signingCompanyFee: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        client: {
          value: "",
          isValid: false,
          isValidate: true,
          isDirty: false,
        },
        appointmentDate: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressLine1: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressLine2: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        closingAddressCity: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressCounty: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        closingAddressState: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        closingAddressZip: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        files: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        notes: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        companyNotes: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        signingStatusDetail: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        agent: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentDncFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        internalNotes: {
          value: "",
          isDirty: false,
          isValidate: false,
        },
        instructionId: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        instructions: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        paymentInstructions: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        closingSearch: {
          value: "",
          placeId: null,
          isDirty: false,
          isValidate: true,
        },
        borrowerSearch: {
          value: "",
          placeId: null,
          isDirty: false,
          isValidate: true,
        },
        isScanBackNeeded: {
          value: false,
          isDirty: false,
          isValidate: false,
        },
      },
      searchResult: [],
      clients: [],
      agents: [],
      expenses: [],
      activityLog: [],
      signingInstruction: [],
      loanType: [],
      dropdownAgentList: [],
      activityLogPage: {
        skip: 0,
        limit: 5,
        totalCount: null,
      },
      show: false,
      inputLoanType: false,
      appointmentMapType: "google",
      editOrderDetailsModal: {
        isOpen: false,
        data: null,
      },
      downloadInvoiceModal: {
        isOpen: false,
        data: null,
      },
      isOpenCollapse: true,
      agentsZipCodePage: {
        page: 1,
        limit: 30,
      },
      assignJobModal: {
        isOpen: false,
        data: null,
      },
      clientMemberModal: {
        isOpen: false,
        data: null,
        success: null,
      },
      agentSuggestedTotalCount: 0,
    };
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _updateClosingState = (closingDetails) => {
    this.setState({ closingDetails });
  };

  _getAllCompanySettings = () => {
    getAllCompanySettings()
      .then((res) => {
        this.setState(
          {
            signingInstruction: res?.companySetting?.signingInstruction?.length
              ? res?.companySetting?.signingInstruction
              : [],
            companySetting: res?.companySetting || {},
          },
          () => {
            this._findScanbackDocument();
            this.props.addSettings(res.companySetting);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _findScanbackDocument = () => {
    const { companySetting, formFields, closingDetails } = deepClone(
      this.state
    );
    console.log("companySetting", closingDetails.isScanBackNeeded);

    if (closingDetails?._client?._id && closingDetails?.loanType) {
      let companySettingScanBackDocument =
        companySetting &&
        Object.keys(companySetting).length &&
        companySetting?.scanBackDocument?.scanBackDocumentDetail?.some(
          (item) =>
            item?._client?._id === closingDetails._client._id &&
            item?.productType === closingDetails.loanType
        );

      formFields["isScanBackNeeded"].value = closingDetails.isScanBackNeeded
        ? closingDetails.isScanBackNeeded
        : companySetting?.scanBackDocument?.isAlwaysRequired
        ? companySetting?.scanBackDocument?.isAlwaysRequired
        : companySettingScanBackDocument;

      this.setState({ formFields });
    } else {
      formFields["isScanBackNeeded"].value = closingDetails.isScanBackNeeded;
      this.setState({ formFields });
    }
  };
  _getAllFiltersData = () => {
    let payload = null;
    if (this.state?.closingDetails?.signingType?.length) {
      payload = {
        signingType: this.state?.closingDetails?.signingType,
      };
    }
    getAllFiltersData(payload)
      .then((res) => {
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: item.name.full,
          })) || [];
        options?.splice(0, 0, { value: "", label: "Select" });
        this.setState({ clients: res?.clients, agents: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getTrackingInfoByTrackingNumber = async (payload) => {
    try {
      await getTrackingInfoById(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  _openTrackingDetails = () => {
    const { shippingCompany, trackingInfo } = this.state?.closingDetails;
    openTrackingDetails(shippingCompany, trackingInfo);
  };

  componentDidMount = async () => {
    let options = LoanType.map((item) => ({
      value: item,
      label: item.replace(/([A-Z])/g, " $1").trim(),
    }));
    options.splice(0, 0, { value: "", label: "Select" });
    this.setState({ loanType: options });
    try {
      const id = this.props.match.params.id;
      this.props.showLoader("Fetching Closing Details...");
      await this._getSigningClosingById(id);
      // console.log("op", isObjectPresent("settings"));
      if (isObjectPresent("settings") === false) {
        this._getAllCompanySettings();
      } else {
        this.setState(
          {
            signingInstruction: this.props.settings?.signingInstruction?.length
              ? this.props.settings?.signingInstruction
              : [],
            companySetting: this.props.settings?.companySetting || {},
          },
          () => {
            this._findScanbackDocument();
            this.props.addSettings(this.props.settings.companySetting);
          }
        );
      }
      this._getcompanyExpenseForClosing(id);
      this._getAllFiltersData();
      // this._getrating(id);
      // this._getAgentWithZipcode();
      // this._companyGetAllProductTypes();
      this.props.hideLoader();
    } catch (error) {
      console.log("err", error);
      errorHandler(error);
      this.props.hideLoader();
      this._redirectToDashboard();
    }
    HeaderEventEmitter.subscribe("track-order", this._openTrackingDetails);
    HeaderEventEmitter.subscribe("back-button", () => {
      this._closingDashboard();
    });
    HeaderEventEmitter.subscribe("download-invoice", () => {
      console.log("2222");
      this._onToggleDownloadInvoiceModal(true, this.state?.closingDetails);
    });
  };

  _getLoanType = () => {
    getLoanType()
      .then((res) => {
        // console.log(res);
        let options =
          res?.loanType?.map((item) => ({
            value: item,
            label: item.replace(/([A-Z])/g, " $1").trim(),
          })) || [];
        options?.splice(0, 0, { value: "", label: "Select" });
        options?.push({ value: "Others", label: "Others" });
        this.setState({ loanType: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  //fetch activity log
  _activityLogClosing = () => {
    const { activityLogPage } = deepClone(this.state);

    let payload = `closingId=${this.props.match.params.id}&skip=${activityLogPage.skip}&limit=${activityLogPage.limit}`;

    activityLogClosing(payload)
      .then((res) => {
        this.setState({
          activityLog: res?.logs,
          activityLogPage: {
            ...this.state.activityLogPage,
            totalCount: res?.totalCount,
          },
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getrating = (closingDetails) => {
    if (closingDetails?._agent) {
      getRating(closingDetails?.id)
        .then((res) => {
          this.setState({
            previousRating: res?.previousRating,
          });
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  _getcompanyExpenseForClosing = (id) => {
    companyExpenseForClosing(id)
      .then((res) => {
        this.setState({ expenses: res?.expenses });
        // console.log(res)
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAgentWithZipcode = (data) => {
    const { agentsZipCodePage, closingDetails } = deepClone(this.state);

    this._manageLoading("agentSearchLoading", true);
    let payload = {
      // zipCode: this.state.closingDetails?.closingAddress?.zip,
      zipCode:
        closingDetails?.closingAddress?.zip?.trim()?.length > 0
          ? closingDetails?.closingAddress?.zip
          : undefined,
      state:
        closingDetails?.signingType === "RON"
          ? closingDetails?.closingAddress?.state
          : undefined,
      signingType: closingDetails?.signingType,
      skip: 0,
      limit: agentsZipCodePage.page * agentsZipCodePage.limit,
      search: data?.search,
    };
    getAgentWithZipcode(payload)
      .then((res) => {
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: capitalize(item?.name?.first + " " + item?.name?.last),
          })) || [];
        this.setState({
          dropdownAgentList: options,
          agentSuggestedTotalCount: res?.totalCount,
        });
        this._manageLoading("agentSearchLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("agentSearchLoading", false);
        // this._manageLoading("showTableLoading", false);
      });
  };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.replace(`/${userData.type}/dashboard`);
  };

  _getSigningClosingById = (id = null) => {
    const { formFields } = deepClone(this.state);

    if (!id && this.state?.closingDetails && this?.state?.closingDetails?.id) {
      id = this.state.closingDetails.id;
    }
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this._handleActivityLogPagination();
          this.setState(
            {
              closingDetails: res?.closing || [],
            },
            () => {
              HeaderEventEmitter.dispatch("closing-details", {
                closingDetails: res?.closing,
              });
              document.querySelector("#scroll").scrollTo(0, 0);
            }
          );
          formFields["agentDncFee"].value = res?.closing?.agentDncFee
            ? res?.closing?.agentDncFee.toString()
            : "";

          formFields["instructionId"].value = res?.closing?.instructionId
            ? res?.closing?.instructionId
            : "";
          formFields["instructions"].value = res?.closing?.instructions
            ? res?.closing?.instructions
            : "";

          this.setState({ formFields });
          this._getrating(res.closing);

          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _onToggleAddRatingModal = () => {
    let { addRatingModal, previousRating } = JSON.parse(
      JSON.stringify(this.state)
    );
    addRatingModal.isOpen = !addRatingModal.isOpen;
    addRatingModal.data = {
      id: this.props.match.params.id,
      previousRating: previousRating,
    };
    addRatingModal.mode = previousRating ? "edit" : "add";
    this.setState({
      addRatingModal,
    });
  };

  // update CLosing Detail by Id
  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // update Draft Closing Detail by Id
  _updateSigningCompanyDraftClosing = (id, payload) => {
    return new Promise((resolve, reject) => {
      editDraft(id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // update closing status by id
  _updateClosingDetailsByIdStatus = async (data, status) => {
    if (status?.status === "DNC" || status?.status === "Closed") {
      if (!data?._agent) {
        errorHandler({ reason: "No Agent was assigned in this order" });
        return;
      }
    }

    if (
      status.status === "DNC" ||
      status.status === "Closed" ||
      status.status === "Cancelled"
    ) {
      this._onToggleSigningStatusModal(true, data, status.status);
    } else {
      try {
        this._manageLoading("statusChangeLoading", true);

        const updateAgentRes = await this._updateClosingDetailsById(
          data.id,
          status
        );
        !status.isCompanyViewDocument &&
          showToast("Status Updated Successfully", "success");
        const { closingDetails } = deepClone(this.state);
        closingDetails["status"] = updateAgentRes.closing.status;
        closingDetails["agentFee"] = updateAgentRes.closing.agentFee;
        closingDetails["companyAcceptDncFee"] =
          updateAgentRes.closing.companyAcceptDncFee;
        closingDetails["isDocumentCompleted"] =
          updateAgentRes.closing.isDocumentCompleted;
        closingDetails["isDocumentVerified"] =
          updateAgentRes.closing.isDocumentVerified;
        closingDetails["isCompanyViewDocument"] =
          updateAgentRes.closing.isCompanyViewDocument;

        closingDetails["isScanBackNeeded"] =
          updateAgentRes.closing.isScanBackNeeded;

        closingDetails["instructions"] = updateAgentRes.closing.instructions;
        this.setState({ closingDetails });
        if (this.state.isEditAgentFee) {
          this._toggleEditAgentFee();
        }
        // this._handleActivityLogPagination();
        //This api call opened bcz when we approved amount then click invoice button data not updated need to reload for see data
        this._getSigningClosingById();
        this._manageLoading("statusChangeLoading", false);
      } catch (error) {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      }
    }
  };

  _closingDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    let restLink = "";
    if (userData.type === "agent") {
      restLink = "dashboard";
    } else if (userData.type === "company") {
      restLink = "closing-dashboard";
    }
    this.props.history.replace(`/${userData.type}/${restLink}`);
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDownloadInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      downloadInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleEditAgentFee = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["agentFee"].value = closingDetails.agentFee
      ? closingDetails.agentFee.toString()
      : "";

    this.setState({ formFields, isEditAgentFee: !this.state.isEditAgentFee });
  };

  // document data format for documnet
  _documentDataFormat = (cell, row, header) => {
    switch (header) {
      case "title": {
        return row ? (
          <>
            {row.docType === "pdf" ? (
              <i className="fa fa-file-pdf-o mr-1" />
            ) : (
              <i className="fa fa-file-text-o mr-1" />
            )}
            {row.title}
          </>
        ) : (
          "N/A"
        );
      }
      case "createdAt": {
        return cell ? (
          <>
            {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._onToggleDocumentViewModal(row, true)}
            >
              <i className="fa fa-eye" />
            </Button>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._downloadDocument(row)}
            >
              <i className="fa fa-download" />
            </Button>
            {!row.isPrivate ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._deleteDocumentClosing(row)}
              >
                <i className="fa fa-trash-o text-danger" />
              </Button>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  // download document
  _downloadDocument = (row) => {
    let { closingDetails } = deepClone(this.state);

    if (!closingDetails?.isCompanyViewDocument) {
      this._updateClosingDetailsByIdStatus(closingDetails, {
        isCompanyViewDocument: true,
      });
    }
    openUrlOnNewTab(row.url);
  };

  _onToggleDocumentViewModal = (doc = null, isOpen = false) => {
    let { documentViewModalData, closingDetails } = deepClone(this.state);
    if (doc && isOpen) {
      documentViewModalData = {
        isOpen: true,
        externalUrl: doc.url,
        modalHeading: doc.title,
        fileType: doc.docType,
      };

      if (
        !closingDetails?.isCompanyViewDocument &&
        this?.props?.userData?.user?.isAssistant !== undefined &&
        !this?.props?.userData?.user?.isAssistant
      ) {
        this._updateClosingDetailsByIdStatus(closingDetails, {
          isCompanyViewDocument: true,
        });
      }
    } else {
      documentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }
    this.setState({ documentViewModalData });
  };

  _getTotalExpensesPrice = (expenses) => {
    let amount;
    if (expenses && expenses.length) {
      amount = expenses.reduce((totalAmount, exp) => {
        return totalAmount + exp.amount;
      }, 0);
    }
    return amount && amount > 0 ? formatCurrencyValue(amount) : "";
  };

  _expenseDataFormat = (cell, row, header) => {
    switch (header) {
      case "item": {
        return cell ? capitalize(cell) : "N/A";
      }
      case "amount": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "receipt": {
        return cell ? (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => openUrlOnNewTab(cell)}
          >
            <i className="fa fa-download" />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  editModeOn = (fieldName, value) => {
    let { editing, formFields } = deepClone(this.state);
    Object.keys(editing).forEach((key) => {
      editing[key] = false;
    });
    editing[fieldName] = value;
    this.setState({
      editing,
    });

    if (fieldName === "borrower") {
      this.borrowerDetail();
    }
    if (fieldName === "orderDetail") {
      this.orderDetail();
    }
    if (fieldName === "activityLog") {
      this.activityLog();
    }

    if (fieldName === "appointment") {
      this.appointmentDetail();
    }
    if (fieldName === "instructions") {
      this.instructionsDetail();
    }
    if (fieldName === "paymentInstructions") {
      this.paymentInstructionsDetail();
    }
    if (value === false) {
      Object.keys(formFields).forEach((key) => {
        delete formFields[key].error;
      });
      this.setState({ formFields });
    }
  };

  borrowerDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);

    formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    // closingDetails?.loanType !== "Other" ? closingDetails?.loanType : "Other";

    formFields["loanTypeOther"].value = closingDetails?.loanTypeOther || "";

    if (closingDetails._borrower[0]?.address) {
      formFields["borrowerSearch"].value = formatAddressInSingleText(
        closingDetails._borrower[0].address
      );
      formFields["borrowerSearch"].placeId = "initialValue";
    }
    this.setState({ formFields });
  };

  orderDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["fileNumber"].value = closingDetails.fileNumber
      ? closingDetails.fileNumber
      : "";
    formFields["lender"].value = closingDetails.lender
      ? closingDetails.lender
      : "";
    formFields["client"].value = closingDetails?._client?.id
      ? closingDetails?._client?.id
      : "";
    formFields["signingCompanyFee"].value = closingDetails.signingCompanyFee
      ? closingDetails.signingCompanyFee.toString()
      : "";

    formFields["trackingInfo"].value = closingDetails.trackingInfo
      ? closingDetails.trackingInfo
      : "";

    formFields["personOfContactName"].value = closingDetails.personOfContact
      .name
      ? closingDetails.personOfContact.name
      : "";
    formFields["personOfContactEmail"].value = closingDetails.personOfContact
      .email
      ? closingDetails.personOfContact.email.filter((x) => x)
      : [];

    formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanTypeOther"].value = closingDetails?.loanTypeOther || "";

    formFields["witnessCount"].value = Number(closingDetails.witnessCount);

    this.setState({ formFields });
  };

  appointmentDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["signingStatusDetail"].value = closingDetails.signingStatusDetail
      ? closingDetails.signingStatusDetail
      : "";
    if (closingDetails.closingAddress) {
      formFields["closingSearch"].value = formatAddressInSingleText(
        closingDetails.closingAddress
      );
      formFields["closingSearch"].placeId = "initialValue";
    }
    formFields["internalNotes"].value = closingDetails.internalNotes
      ? closingDetails.internalNotes
      : "";

    formFields["companyNotes"].value = closingDetails.companyNotes
      ? closingDetails.companyNotes
      : "";
    formFields["witnessCount"].value = closingDetails.witnessCount
      ? closingDetails.witnessCount
      : "";

    this.setState({ formFields });
  };

  instructionsDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);
    formFields["instructionId"].value = closingDetails?.instructionId
      ? closingDetails.instructionId
      : "";
    formFields["instructions"].value = closingDetails?.instructions
      ? closingDetails.instructions
      : "";
    this.setState({ formFields });
  };

  paymentInstructionsDetail = () => {
    const { closingDetails, formFields } = deepClone(this.state);

    formFields["paymentInstructions"].value =
      closingDetails?.paymentInstructions
        ? closingDetails.paymentInstructions
        : "";
    this.setState({ formFields });
  };

  agentDetail = () => {
    const { formFields } = deepClone(this.state);
    this.setState({ formFields });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction } = deepClone(this.state);

    if (fieldName === "signingCompanyFee" || fieldName === "agentFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "instructionId" && value) {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructions.value = findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        // formFields[e].isDirty = true;
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  updateClosing = async (type) => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();
      console.log(isFormValid);
      if (isFormValid) {
        this._manageLoading("cardUpdateLoading", true);

        const { formFields, closingDetails, editing } = deepClone(this.state);

        let data = {};

        if (type === "instructions") {
          if (
            // closingDetails.instructionId === formFields.instructionId.value ||
            closingDetails.instructions === formFields.instructions.value
          ) {
            // editing["instructions"] = false;
            // this.setState( editing );
            this.editModeOn("instructions", false);
            this._manageLoading("cardUpdateLoading", false);
            return;
          } else {
            data = {
              instructionId: formFields.instructionId.value || undefined,
              instructions: formFields.instructions.value || undefined,
            };
          }
        }

        if (type === "paymentInstructions") {
          data = {
            paymentInstructions:
              formFields.paymentInstructions.value || undefined,
          };
        }

        if (type === "agentDetail") {
          if (!closingDetails?.agentFee && closingDetails?.agentFee !== 0) {
            errorHandler({ reason: "Please provide Agent Fee" });
            this._manageLoading("cardUpdateLoading", false);
            return;
          }

          data = {
            agentId: formFields.agent?.value?.value || undefined,
          };
        }
        console.log("data", data);
        try {
          if (type === "agentDetail") {
            await this._updateClosingDetailsById(closingDetails.id, data);
          } else {
            const updateAgentRes = closingDetails.isSavedAsDraft
              ? await this._updateSigningCompanyDraftClosing(
                  closingDetails.id,
                  data
                )
              : await this._updateClosingDetailsById(closingDetails.id, data);
            console.log(updateAgentRes);
          }
          showToast("Updated Successfully", "success");
          Object.keys(editing).forEach((key) => {
            editing[key] = false;
          });
          this.setState({ editing });
          this._getSigningClosingById(closingDetails.id);
          this._manageLoading("cardUpdateLoading", false);
        } catch (error) {
          errorHandler(error);
          this._manageLoading("cardUpdateLoading", false);
        }
      }
    } catch (error) {
      console.log("error", error);
      errorHandler(error);
      this._manageLoading("cardUpdateLoading", false);
    }
  };
  _validateForm = () => {
    console.log("validating....");
    return new Promise((resolve, reject) => {
      const { formFields, editing } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "borrower": {
              if (editing.appointment) {
                formFields.borrower.forEach((each, index) => {
                  if (each.borrowerName?.value.trim().length) {
                    formFields[key][index]["borrowerName"].isDirty = false;
                    formFields[key][index]["borrowerName"].error = null;
                  } else {
                    formFields[key][index]["borrowerName"].isDirty = true;
                    formFields[key][index]["borrowerName"].error = "*Required";
                    isFormValid = false;
                  }

                  if (
                    formFields[key][index]["email"].value &&
                    formFields[key][index]["email"].value.trim().length
                  ) {
                    if (
                      RegexConfig.email.test(
                        String(
                          formFields[key][index]["email"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["email"].isDirty = false;
                      formFields[key][index]["email"].error = null;
                    } else {
                      formFields[key][index]["email"].isDirty = true;
                      formFields[key][index]["email"].error = "*Invalid Email";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  }

                  if (
                    formFields[key][index]["workPhone"].value &&
                    formFields[key][index]["workPhone"].value.length
                  ) {
                    if (
                      RegexConfig.phone.test(
                        String(
                          formFields[key][index]["workPhone"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["workPhone"].isDirty = false;
                      formFields[key][index]["workPhone"].error = null;
                    } else {
                      isFormValid = false;
                      formFields[key][index]["workPhone"].isDirty = true;
                      formFields[key][index]["workPhone"].error =
                        "*Invalid Phone Number";
                    }
                  } else {
                    if (
                      formFields["workPhone"].value &&
                      formFields["workPhone"].value.length
                    ) {
                      formFields[key][index]["workPhone"].isDirty = false;
                      formFields[key][index]["workPhone"].error = null;
                    } else {
                      isFormValid = false;
                      formFields[key][index]["workPhone"].isDirty = true;
                      formFields[key][index]["workPhone"].error = "*Required";
                    }
                  }
                });
              }
              break;
            }
            case "closingSearch": {
              if (editing.appointment) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                    isFormValid = true;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }

              break;
            }

            case "loanType": {
              if (editing.borrower) {
                if (formFields[key].value && formFields[key].value.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "loanTypeOther": {
              if (editing.borrower && formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  _deleteDocumentClosing = (row) => {
    const { closingDetails } = deepClone(this.state);
    console.log(row._id, closingDetails._id);
    let payload = { documentId: row._id };
    this.props.showLoader("Deleting Document...");

    deleteDocumentForClosing(closingDetails._id, payload)
      .then((res) => {
        this._getSigningClosingById();
        showToast("Document Deleted Successfully", "success");
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _handleActivityLogPagination = (pageNo = 1) => {
    const { activityLogPage } = deepClone(this.state);

    activityLogPage["skip"] = (pageNo - 1) * activityLogPage.limit;

    this.setState({ activityLogPage }, () => {
      this._activityLogClosing();
    });
  };

  _activityLog = () => {
    const { activityLog, closingDetails } = deepClone(this.state);
    //this is for when send incoive then how to show activityLog => item.activityType === "InvoiceCreated" check condition agent or client  thwn show name
    return (
      activityLog?.length > 0 &&
      activityLog.map((item, key) => {
        return (
          <li key={key}>
            {item.activityType !== "ClosingAssigned"
              ? item.isDocumentCompleted
                ? null
                : item.activityType === "InvoiceCreated"
                ? item?._agent?.name?.full
                  ? `${item._agent.name.full} sent invoice to ${item._user?.name?.full}`
                  : `${item._user?.name?.full} sent invoice to ${item._client?.companyName}`
                : item.activityType === "EmailParsed"
                ? null
                : item._user?.name?.full
              : "New Order"}{" "}
            {item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "Documents Upload by Signing Company"
                : item.isDocumentCompletedByAgent
                ? "Documents Upload by Agent"
                : item.isDocumentVerified
                ? "has Verified"
                : "Uploaded"
              : item.activityType === "AppointmentStatus" ||
                item.activityType === "AppointmentDate" ||
                item.activityType === "ClosingStatus" ||
                item.activityType === "LeftMessage"
              ? "Changed"
              : item.activityType === "ClosingCreated"
              ? "Created the Closing"
              : item.activityType === "ClosingAssigned"
              ? /* ? "Assigned Closing" */
                "Assigned"
              : item.activityType === "DraftCreated"
              ? "created Draft Closing"
              : item.activityType === "DraftToClosing"
              ? "converted Draft to Regular Closing"
              : item.activityType === "ClosingUnAssigned"
              ? "Unassign"
              : item.activityType === "DocumentsDeleted"
              ? "Deleted the Document"
              : item.activityType === "InvoiceCreated"
              ? null
              : item.activityType === "EmailParsed"
              ? null
              : "Updated"}{" "}
            {item?.activityType === "ClosingUnAssigned"
              ? `${item?._agent?.name?.full} from closing`
              : null}
            {item.activityType === "Notes"
              ? item.notesType.replace(/([A-Z])/g, " $1").trim()
              : item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "Complete"
                : item.isDocumentCompletedByAgent
                ? "as Scanback Upload Complete"
                : item.isDocumentVerified
                ? "Agent Document"
                : "a New File"
              : item.activityType === "ClosingCreated"
              ? ""
              : item.activityType === "DraftCreated"
              ? ""
              : item.activityType === "DraftToClosing"
              ? ""
              : item.activityType === "ClosingUnAssigned"
              ? ""
              : item.activityType === "LeftMessage"
              ? "Appointment Notes"
              : item.activityType === "ClosingAssigned"
              ? null
              : item.activityType === "DocumentsDeleted"
              ? item.documents[0].title
              : item.activityType === "InvoiceCreated"
              ? null
              : item.activityType.replace(/([A-Z])/g, " $1").trim()}{" "}
            {item.activityType === "Documents"
              ? item?.documents.map((each) => (
                  <Button
                    key={each.title}
                    color="link"
                    className="showMap"
                    onClick={() => openUrlOnNewTab(each?.url)}
                  >
                    {each.title}
                  </Button>
                ))
              : null}{" "}
            {/* {item?.activityType === "ClosingUnAssigned" ? } */}
            {item.to
              ? `To ${
                  item.activityType === "AppointmentDate"
                    ? formatDateAsPerTimeZOne(
                        item.to,
                        closingDetails.closingAddress.timeZone
                      )
                    : item.to
                }`
              : null}{" "}
            {item.activityType === "ClosingAssigned" ? (
              <>To {item._agent.name.full}</>
            ) : null}
            {item.activityType === "LeftMessage" ? <>To Left Message </> : null}
            {item.text ? (
              item.activityType === "Notes" ? (
                <>
                  {" "}
                  To{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      marginLeft: 3,
                      marginRight: 5,
                    }}
                  >
                    "{item.text}"{" "}
                  </span>{" "}
                </>
              ) : null
            ) : null}{" "}
            {item.activityType === "EmailParsed" ? "succesfully on " : null}|{" "}
            {formatDateHideYearIfSame(item.timeStamp)},{" "}
            {formatTime(item.timeStamp)}
          </li>
        );
      })
    );
  };

  _rowStyleFormat = (row, rowIdx) => {
    if (row?._signingCompany) {
      return { backgroundColor: "#eee" };
    } else if (row?._agent) {
      return { backgroundColor: "#cfe2ff" };
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _toggleEditOrderDetailsModal = (isOpen = false, data = null) => {
    this.setState({
      editOrderDetailsModal: {
        isOpen,
        data,
      },
    });
  };

  _checkAllFieldsAreAvailable = () => {
    const { closingDetails } = deepClone(this.state);
    return (
      closingDetails._client !== undefined &&
      closingDetails.signingCompanyFee !== undefined &&
      closingDetails?._borrower[0]?.name?.full.trim().length !== undefined &&
      closingDetails.fileNumber !== undefined
    );
  };

  searchOnChange = debounce((value) => {
    if (value.length > 0) {
      this._getAgentWithZipcode({ search: value });
    }
  }, 1000);

  _onToggleAssignJobModal = (data) => {
    let { assignJobModal } = JSON.parse(JSON.stringify(this.state));
    assignJobModal.isOpen = !assignJobModal.isOpen;
    assignJobModal.data = data;
    this.setState({
      assignJobModal,
    });
    if (!assignJobModal.isOpen) {
      this._getSigningClosingById();
    }
  };

  _onToggleClientMembers = (isOpen = false, data = null) => {
    this.setState({
      clientMemberModal: {
        isOpen,
        data,
      },
    });
  };

  render() {
    const {
      closingDetails,
      loading,
      documentViewModalData,
      addRatingModal,
      signerAvailabilityModal,
      isEditAgentFee,
      editing,
      formFields,
      signingStatusModal,
      dropdownAgentList,
      previousRating,
      activityLog,
      activityLogPage,
      signingInstruction,
      editOrderDetailsModal,
      agentsZipCodePage,
      agentSuggestedTotalCount,
      downloadInvoiceModal,
      clientMemberModal,
      assignJobModal,
      clients,
    } = this.state;

    return (
      <div id="scroll">
        <div className="content">
          {closingDetails && (
            <>
              <div className="responsiveTitle">
                <div className="d-flex align-items-center">
                  <Button
                    color="link"
                    className="backBtn"
                    onClick={this._closingDashboard}
                  >
                    <img
                      src={require("../../../assets/img/arrowLeft.svg").default}
                      alt="backbutton"
                      height={14}
                    />
                  </Button>
                  <h2>
                    Order Details
                    <span>Order #{closingDetails.closingNumber || "N/A"}</span>
                  </h2>
                </div>

                <div className="rightSide">
                  <Button
                    className="floatingButton"
                    color="primary"
                    onClick={() => {
                      this.props.history.push(
                        `/signingcompany/create-closing?${closingDetails.id}&isClone=true`
                      );
                    }}
                  >
                    Clone
                  </Button>
                  <Button
                    color="primary"
                    onClick={this._openTrackingDetails}
                    disabled={!closingDetails?.shippingCompany?.trim()}
                  >
                    Track Shipment
                  </Button>
                  <Button
                    color="primary"
                    onClick={() =>
                      HeaderEventEmitter.dispatch("download-invoice")
                    }
                    disabled={
                      // closingDetails?._agent === undefined
                      closingDetails?.status === "Cancelled" ||
                      closingDetails?.status === "CCA"
                      // ||
                      // closingDetails?.status === "Arrived"
                    }
                  >
                    Invoice
                  </Button>
                </div>
              </div>

              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Signer
                    <>
                      {closingDetails?.status !== "Closed" &&
                      closingDetails?.status !== "DNC" ? (
                        <Button
                          className="svgStroke"
                          color="link"
                          onClick={() =>
                            this._toggleEditOrderDetailsModal(
                              true,
                              closingDetails
                            )
                          }
                        >
                          {/* <img
                            src={
                              // require("../../../assets/img/pencil.svg").default
                            }
                            alt="edit"
                          /> */}
                          <SvgIcons type={"editIcon"} />
                        </Button>
                      ) : null}
                    </>
                  </h2>
                </div>
              </div>

              <SignerAppointment
                closingDetails={closingDetails}
                updateClosingDetailsByIdStatus={(closingDetails, status) =>
                  this._updateClosingDetailsByIdStatus(closingDetails, status)
                }
                userType="company"
                userData={this.props.userData}
                _onToggleClientMembers={(isopen, data) =>
                  this._onToggleClientMembers(isopen, data)
                }
              />

              <Row className="mt-4">
                <Col xl="6">
                  <div className="CardWrapper">
                    <Suspense fallback={<></>}>
                      <SecureDocumentCardComponent
                        closingDetails={closingDetails}
                        updateClosingState={(closingDetails) =>
                          this._updateClosingState(closingDetails)
                        }
                        loadingData={this.props.loadingData}
                      />
                    </Suspense>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="CardWrapper">
                    <Card>
                      <CardHeader>
                        <CardTitle>
                          {closingDetails._agent?.workType
                            ? closingDetails._agent.workType
                            : "Agent"}{" "}
                          Details
                          {!editing.agentDetail ? (
                            <Button
                              color="link"
                              className="svgStroke"
                              onClick={() =>
                                this._onToggleAssignJobModal({
                                  ...closingDetails,
                                  _id: closingDetails?._id,
                                })
                              }
                              disabled={
                                !checkPermission(
                                  "dashboard",
                                  "canEditOrderEntry"
                                )
                              }
                            >
                              {/* <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="edit"
                              /> */}
                              <SvgIcons type={"editIcon"} />
                            </Button>
                          ) : null}
                        </CardTitle>
                      </CardHeader>
                      {this?.props?.loadingData?.isVisible ? (
                        <SkeletonLoading
                          type="card"
                          count={1}
                          height={240}
                          width={470}
                        />
                      ) : (
                        <CardBody className="p-0">
                          {editing.agentDetail ? (
                            <div className="agentSelect">
                              {editing.agentDetail ? (
                                <FormGroup className="flex-1">
                                  <Label>Agent</Label>

                                  <div className="customSelect">
                                    <InfiniteScrollDropdown
                                      optionsConfig={dropdownAgentList}
                                      value={formFields.agent.value}
                                      onChangeFunc={(event) =>
                                        this._onChangeFormField("agent", event)
                                      }
                                      onInputChangeFunc={(value, action) => {
                                        // this.searchOnChange(value)
                                        if (action === "menu-close") {
                                          this._getAgentWithZipcode({ value });
                                        }

                                        if (action === "input-change") {
                                          this.searchOnChange(value);
                                        }
                                      }}
                                      shouldMenuShouldScrollIntoView={true}
                                      updateOptionsConfig={(payload) =>
                                        this._getAgentWithZipcode()
                                      }
                                      isLoading={loading?.agentSearchLoading}
                                      totalDataCount={agentSuggestedTotalCount}
                                      totalDataPages={agentsZipCodePage.page}
                                      dropdownPayload={agentsZipCodePage}
                                      setDropdownPayload={(
                                        newDropDownPayload
                                      ) =>
                                        this.setState(
                                          {
                                            agentsZipCodePage:
                                              newDropDownPayload,
                                          },
                                          () =>
                                            this._getAgentWithZipcode(
                                              newDropDownPayload
                                            )
                                        )
                                      }
                                      isMulti={false}
                                      isClearable={true}
                                      placeholder="Agent"
                                      isDisabled={
                                        closingDetails.status !== "Pending"
                                      }
                                    />
                                  </div>
                                </FormGroup>
                              ) : null}

                              {!editing.agentDetail ? null : (
                                <>
                                  <Button
                                    color="primary"
                                    disabled={
                                      loading.cardUpdateLoading ||
                                      closingDetails.status !== "Pending"
                                    }
                                    onClick={() =>
                                      this.updateClosing("agentDetail")
                                    }
                                  >
                                    {loading.cardUpdateLoading ? (
                                      <i className="fa fa-spinner fa-spin" />
                                    ) : null}{" "}
                                    Save
                                  </Button>
                                  <Button
                                    color="primary"
                                    outline
                                    onClick={() =>
                                      this.editModeOn("agentDetail", false)
                                    }
                                  >
                                    <i className="fa fa-times" />
                                  </Button>
                                </>
                              )}
                            </div>
                          ) : null}

                          <div className="profilePic">
                            <img
                              src={
                                closingDetails?._agent?.profilePicture
                                  ? closingDetails?._agent?.profilePicture
                                  : require("../../../assets/img/default_user_icon.svg")
                                      .default
                              }
                              alt="user"
                            />
                          </div>

                          <ul className="noataryAgentList">
                            <li>
                              Name
                              <div className="value">
                                {closingDetails._agent?.name?.full ? (
                                  <span
                                    className="text-primary cursorPointer"
                                    onClick={() =>
                                      this.props.history.push(
                                        `/signingcompany/agent-details/${closingDetails?._agent?.id}`
                                      )
                                    }
                                  >
                                    {capitalize(
                                      closingDetails?._agent?.name?.full
                                    )}
                                  </span>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </li>
                            <li>
                              Email
                              <div className="value">
                                {closingDetails?._agent?.email ? (
                                  <a
                                    href={`mailto:${closingDetails?._agent?.email}`}
                                  >
                                    {closingDetails?._agent.email}
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </li>
                            <li>
                              Phone
                              <div className="value">
                                {closingDetails._agent?.phone
                                  ? formatPhoneNumber(
                                      closingDetails._agent.phone
                                    )
                                  : "N/A"}
                              </div>
                            </li>
                            <li>
                              Location
                              <div className="value">
                                {closingDetails._agent?.address ? (
                                  <span>
                                    {closingDetails._agent.address.line1
                                      ? `${closingDetails._agent.address.line1}, `
                                      : ""}
                                    {closingDetails._agent.address.line2
                                      ? `${closingDetails._agent.address.line2}, `
                                      : ""}
                                    {closingDetails._agent.address.city
                                      ? `${closingDetails._agent.address.city}, `
                                      : ""}
                                    {closingDetails._agent.address.state
                                      ? `${closingDetails._agent.address.state}, `
                                      : ""}
                                    {closingDetails._agent.address.zip
                                      ? `${closingDetails._agent.address.zip}`
                                      : ""}
                                  </span>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </li>

                            <li>
                              Fee
                              {/* default view would be this. On clicking the below pencil icon, the below div should hide & the `edit` view should be shown(present below)  */}
                              <div className="value">
                                <span>
                                  <>
                                    {!isEditAgentFee ? (
                                      <>
                                        {closingDetails?.agentFee
                                          ? formatCurrencyValue(
                                              closingDetails?.agentFee
                                            )
                                          : closingDetails?.agentFee === 0
                                          ? "$0"
                                          : "N/A"}
                                        {closingDetails?._agent?._id && (
                                          <img
                                            className="cursorPointer ml-2"
                                            onClick={this._toggleEditAgentFee}
                                            src={
                                              require("../../../assets/img/pencil.svg")
                                                .default
                                            }
                                            alt="edit"
                                          />
                                        )}
                                      </>
                                    ) : (
                                      <>
                                        {/* Edit Mode (this should be shown when the above pencil icon is clicked) */}
                                        <div className="d-flex justify-content-end">
                                          <Input
                                            type="text"
                                            placeholder=""
                                            value={formFields.agentFee.value}
                                            style={{ height: 30, width: 100 }}
                                            onChange={(event) =>
                                              this._onChangeFormField(
                                                "agentFee",
                                                event.target.value
                                              )
                                            }
                                          />
                                          <Button
                                            color="link"
                                            className="pl-1"
                                            disabled={
                                              formFields.agentFee.value.trim()
                                                .length === 0
                                            }
                                            onClick={() =>
                                              this._updateClosingDetailsByIdStatus(
                                                closingDetails,
                                                {
                                                  agentFee:
                                                    formFields.agentFee.value,
                                                }
                                              )
                                            }
                                          >
                                            <img
                                              className="cursorPointer ml-2"
                                              src={
                                                require("../../../assets/img/checkBlueIcon.svg")
                                                  .default
                                              }
                                              alt="edit"
                                            />
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  </>
                                </span>
                              </div>
                            </li>
                            <li>
                              Dnc Fee
                              <div className="value">
                                {closingDetails.agentDncFee ? (
                                  closingDetails.companyAcceptDncFee ? (
                                    <span>${closingDetails.agentFee}</span>
                                  ) : (
                                    <>
                                      <InputGroup>
                                        <Input
                                          type="text"
                                          placeholder=""
                                          value={formFields.agentDncFee.value}
                                          onChange={(event) =>
                                            this._onChangeFormField(
                                              "agentDncFee",
                                              event.target.value
                                            )
                                          }
                                        />
                                        <InputGroupAddon addonType="append">
                                          <Button
                                            color="primary"
                                            className="fs-12"
                                            onClick={() =>
                                              formFields.agentDncFee.value <=
                                              closingDetails.agentDncFee
                                                ? this._updateClosingDetailsByIdStatus(
                                                    closingDetails,
                                                    {
                                                      companyAcceptDncFee: true,
                                                      agentFee:
                                                        formFields.agentDncFee
                                                          .value !==
                                                        closingDetails.agentDncFee.toString()
                                                          ? formFields
                                                              .agentDncFee.value
                                                          : undefined,
                                                    }
                                                  )
                                                : showToast(
                                                    "Agent Dnc Fee should not be Greater",
                                                    "error"
                                                  )
                                            }
                                          >
                                            Approve
                                          </Button>
                                        </InputGroupAddon>
                                      </InputGroup>
                                    </>
                                  )
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </li>
                            <li>
                              Rating
                              <div className="rating">
                                {previousRating !== null ? (
                                  <StarRatings
                                    rating={closingDetails?._agent?.totalRating}
                                    starRatedColor="#FED82F"
                                    changeRating={
                                      closingDetails?._agent?.totalRating
                                    }
                                    starHoverColor="#FED82F"
                                    numberOfStars={5}
                                    name="rating"
                                  />
                                ) : null}

                                {closingDetails._agent ? (
                                  <Button
                                    color="link"
                                    onClick={() =>
                                      this._onToggleAddRatingModal()
                                    }
                                  >
                                    {previousRating !== null ? (
                                      <img
                                        src={
                                          require("../../../assets/img/pencil.svg")
                                            .default
                                        }
                                        alt="edit"
                                      />
                                    ) : (
                                      "Add Rating"
                                    )}{" "}
                                  </Button>
                                ) : null}
                              </div>
                            </li>
                          </ul>
                        </CardBody>
                      )}
                    </Card>
                  </div>
                </Col>
              </Row>
              <Suspense fallback={<></>}>
                <ChatLogCardComponent closingDetails={closingDetails} />
              </Suspense>

              {/* Order Instructions */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Instructions
                    {!editing.instructions ? (
                      <Button
                        color="link"
                        onClick={() => this.editModeOn("instructions", true)}
                        disabled={
                          !checkPermission("dashboard", "canEditOrderEntry")
                        }
                      >
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="edit"
                        />
                      </Button>
                    ) : (
                      <>
                        <Button
                          color="link"
                          disabled={loading.cardUpdateLoading}
                          onClick={() => this.updateClosing("instructions")}
                        >
                          {loading.cardUpdateLoading ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : null}{" "}
                          <img
                            src={
                              require("../../../assets/img/checkBlueIcon.svg")
                                .default
                            }
                            alt="save"
                          />
                        </Button>
                        <Button
                          color="link"
                          className="px-2 px-sm-3"
                          onClick={() => this.editModeOn("instructions", false)}
                        >
                          <img
                            src={
                              require("../../../assets/img/close.svg").default
                            }
                            height={16}
                            alt="close"
                          />
                        </Button>
                      </>
                    )}
                  </h2>
                </div>
                <Suspense fallback={<></>}>
                  <Instructions
                    instructionData={formFields.instructions.value}
                    onChangeFormField={this._onChangeFormField}
                    signingInstruction={signingInstruction}
                    instructionIdData={formFields.instructionId.value}
                    closingDetails={closingDetails}
                    isScanBackNeededData={closingDetails.isScanBackNeeded}
                    hideInstructionId={!editing.instructions}
                    scanbackForDetailPage={true}
                    updateClosingDetailsById={async (id, payload) => {
                      await this._updateClosingDetailsByIdStatus(
                        { id },
                        payload
                      );
                    }}
                  />
                </Suspense>
              </div>

              {/* activity */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Activity Log</h2>
                </div>
                <Card>
                  <CardBody>
                    <ul className="activityLog">{this._activityLog()}</ul>
                    <Suspense fallback={<></>}>
                      <CustomPagination
                        data={activityLog}
                        dataCount={activityLogPage.totalCount}
                        pageNumber={
                          parseInt(
                            activityLogPage.skip / activityLogPage.limit
                          ) + 1
                        }
                        pageSize={activityLogPage.limit}
                        onPageChange={(pageNo) =>
                          this._handleActivityLogPagination(pageNo)
                        }
                        isPageStartFromOne
                        isHideForLessData
                      />
                    </Suspense>
                  </CardBody>
                </Card>
              </div>
            </>
          )}
          {/* modal to view document */}
          <Suspense fallback={<></>}>
            {documentViewModalData.isOpen && (
              <DocumentViewModal
                isOpen={documentViewModalData.isOpen}
                externalUrl={documentViewModalData.externalUrl}
                fileType={documentViewModalData.fileType}
                modalHeading={documentViewModalData.modalHeading}
                toggle={this._onToggleDocumentViewModal}
              />
            )}
          </Suspense>

          <Suspense fallback={<></>}>
            {addRatingModal.isOpen && (
              <AddRatingModal
                isOpen={addRatingModal.isOpen}
                data={addRatingModal.data}
                mode={addRatingModal.mode}
                toggle={() => this._onToggleAddRatingModal()}
                resetDetails={() => this._getSigningClosingById()}
                ratingDetail={() => this._getrating(closingDetails)}
              />
            )}
          </Suspense>

          {/* appointment availability modal  */}
          <Suspense fallback={<></>}>
            {signerAvailabilityModal.isOpen && (
              <SignerAvailabilityModal
                isOpen={signerAvailabilityModal.isOpen}
                data={signerAvailabilityModal.data}
                resetDetails={this._getSigningClosingById}
                toggle={() => this._onToggleSignerAvailabilityModal()}
              />
            )}
          </Suspense>

          {/* change signing status  */}
          <Suspense fallback={<></>}>
            {signingStatusModal.isOpen && (
              <SigningStatusSigningModal
                isOpen={signingStatusModal.isOpen}
                data={signingStatusModal.data}
                status={signingStatusModal.status}
                resetDetails={() => this._getSigningClosingById()}
                toggle={() => this._onToggleSigningStatusModal()}
                isDateFormat={false}
                initialViewMode={"time"}
              />
            )}
          </Suspense>

          {/* edit order modal */}
          <Suspense fallback={<></>}>
            <EditOrderDetailsModal
              isOpen={editOrderDetailsModal.isOpen}
              data={editOrderDetailsModal.data}
              toggle={() => this._toggleEditOrderDetailsModal()}
              updateClosingDetailsById={
                closingDetails?.isSavedAsDraft
                  ? this._updateSigningCompanyDraftClosing
                  : this._updateClosingDetailsById
              }
              resetDetails={this._getSigningClosingById}
              clients={clients}
            />
          </Suspense>

          {/* Download Invoice modal */}
          <Suspense fallback={<></>}>
            <DownloadInvoiceModal
              isOpen={downloadInvoiceModal?.isOpen}
              data={downloadInvoiceModal?.data}
              toggle={this._onToggleDownloadInvoiceModal}
              type="signingCompany"
              //This added here for after invoice send need to call activity api for update activity logs
              onSuccess={() => this._handleActivityLogPagination()}
            />
          </Suspense>
          {/* closing Assignment modal or Bidding Pop-up */}
          <Suspense fallback={<></>}>
            <ClosingAssignment
              {...this.props}
              isOpen={assignJobModal.isOpen}
              data={assignJobModal.data}
              resetDetails={() => this._getSigningClosingById()}
              toggle={() => this._onToggleAssignJobModal()}
            />
          </Suspense>

          <Suspense fallback={<></>}>
            <TeamMembers
              clientTeamMembers={
                closingDetails?._clientAssistantTeam?.teamMembers
              }
              isOpen={clientMemberModal?.isOpen}
              data={clientMemberModal?.data}
              toggle={() => this._onToggleClientMembers()}
            />
          </Suspense>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
    settings: state.settings,
    loadingData: state.loaderData ? state.loaderData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    addSettings: (settingsData) => dispatch(addSettings(settingsData)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenWidthHOC(ClosingDetails));
