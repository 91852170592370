import React from "react";

const CustomSpinner = ({ size = "sm", color = "#000", className = "" }) => {
  const spinnerStyle = {
    fontSize: size === "sm" ? "16px" : size === "lg" ? "32px" : "24px",
    color,
  };

  return (
    <i
      className={`fa fa-spinner fa-spin mr-1 ${className ? className : ""}`}
      style={spinnerStyle}
    />
  );
};

export default CustomSpinner;
